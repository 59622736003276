<template>
    <SectionContainer title="Household Information" @nextStep="() => {
        event('household_information_complete')
        nextStep()
    }" @prevStep="prevStep" :button-disabled="!store.steps[stepIndex].complete()" helperText="The carrier will use this information to determine your suitability for an annuity. This
                information will never be shared outside of direct communication with the carrier. No credit checks are
                run in the annuity application process.">
        <div class="space-y-8">
            <div class="grid sm:grid-cols-2 gap-4">
                <MoneyField required label="Monthly Household Income" name="householdIncome"
                    :value="store.householdIncome"
                    tooltip="This is your total monthly household income before taxes. Include salary, pension, social security, investment income, and disability."
                    @input="e => store.setField('householdIncome', e)" />
                <MoneyField required label="Monthly Household Expenses"
                    tooltip="This may include cost of every day living,  
                    rent or mortgage payment, auto expenses, insurance, and loan payments (credit card, personal loans, etc.)." name="householdExpenses"
                    :value="store.householdExpenses" @input="e => store.setField('householdExpenses', e)" />
            </div>
            <MultipleChoiceField required cols="2" label="Where are your current investments?"
                name="householdInvestments" :options="investmentOptions"
                @input="e => store.setField('householdInvestments', e)" :value="store.householdInvestments" />
            <div v-for="option in investmentOptions" class="space-y-4">
                <MoneyField required :label="`${option.label} Value`" name="investmentValue"
                    :value="store.householdInvestmentDetails[option.value].value"
                    @input="e => store.setHouseholdInvestmentDetails(option.value, 'value', e)"
                    v-if="store.householdInvestments.includes(option?.value)" />
                <BooleanField required label="Are you partially or wholly using this account to fund your annuity?"
                    name="qualifiedAccount"
                    @input="e => store.setHouseholdInvestmentDetails(option.value, 'liquidating', e)"
                    :value="store.householdInvestmentDetails[option.value].liquidating"
                    v-if="store.householdInvestments.includes(option?.value)" />
            </div>
            <div class="grid sm:grid-cols-2 gap-4">
                <MoneyField required label="Household Liabilities/Debt"
                    tooltip="Examples include student loans, credit cards, or personal loans. Do not include mortgage payments."
                    name="householdLiabilities" :value="store.householdLiabilities"
                    @input="e => store.setField('householdLiabilities', e)" />
            </div>
            <div class="grid gap-4">
                <BooleanField required label="Do you have any dependents in your household?" name="dependents"
                    @input="e => store.setField('dependents', e)" :value="store.dependents" />
                <div class="grid grid-cols-3 gap-8" v-if="store.dependents">
                    <TextField label="Dependent #1 Age" name="dependent1Age" :value="store.dependent1Age"
                        @input="e => store.setField('dependent1Age', e)" />
                    <TextField label="Dependent #2 Age" name="dependent2Age" :value="store.dependent2Age"
                        @input="e => store.setField('dependent2Age', e)" />
                    <TextField label="Dependent #3 Age" name="dependent3Age" :value="store.dependent3Age"
                        @input="e => store.setField('dependent3Age', e)" />
                </div>
            </div>

            <RadioField required :inline="true" label="Tax Filing Status" name="taxFilingStatus"
                :options="taxFilingOptions" @input="e => store.setField('taxFilingStatus', e)"
                :value="store.taxFilingStatus" />
        </div>
    </SectionContainer>
</template>

<script setup lang="ts">
import SectionContainer from '@/components/SectionContainer.vue';
import { MoneyField, MultipleChoiceField, RadioField, BooleanField, TextField } from '@reviseannuity/randolph-ui';
import { useApplicationStore } from '@/stores/application';
import { useStep } from '@/composables/useStep';
import { event } from 'vue-gtag';

const store = useApplicationStore();
const { stepIndex, nextStep, prevStep } = useStep(store);

const investmentOptions = [
    { label: 'Stocks/bonds', value: 'stocks-bonds' },
    { label: 'Annuities', value: 'annuities' },
    { label: 'Mutual Funds', value: 'mutual-funds' },
    { label: 'CDs', value: 'cds' },
    { label: 'Money Market', value: 'money-market' },
    { label: 'Checking/Savings Account', value: 'checking-savings' },
    { label: 'Pension/401k', value: 'pension-401k' },
    { label: 'Net cash surrender of life insurance', value: 'life-insurance' },
    { label: 'Real Estate (Not including primary residence)', value: 'real-estate' },
    { label: 'Reverse Mortgage', value: 'reverse-mortgage' },
    { label: 'Limited Partnership', value: 'limited-partnership' },
]

const taxFilingOptions = [
    { label: 'Single', value: 'single' },
    { label: 'Married Filing Jointly', value: 'married-joint' },
    { label: 'Married Filing Separately', value: 'married-separate' },
    { label: 'Head of Household', value: 'head-household' },
    { label: 'Qualifying Widow(er)', value: 'widow' },
]

</script>