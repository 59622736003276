export const getStateFromZipCode = async (zipCode: string): Promise<string> => {
    const res = await fetch(`https://api.zipcodestack.com/v1/search?codes=${zipCode}&country=us&apikey=${import.meta.env.VITE_ZIPCODESTACK_API_KEY}`)

    if (!res.ok) throw new Error(`Failed to fetch state from zip code. ${res.statusText}`)

    const json = await res.json()

    if (json?.results) {
        if (!json.results[zipCode]) throw new Error('Invalid zip code')
        if (!json.results[zipCode].length) throw new Error('Invalid zip code')
        return json.results[zipCode][0].state
    }
}