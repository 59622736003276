<template>
  <RouterView v-if="store.user" />
  <div v-else class="bg-gray-100 text-gray-400 w-screen h-screen flex justify-center items-center">
    <div class="flex flex-col items-center gap-2">
      <SpinningLoader color="#1454f2" class="w-12" />
      <span class="text-xs uppercase font-semibold">Loading...</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getUserByAuth0Id } from './clients/user';
import { useApplicationStore } from './stores/application';
import { watch } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { SpinningLoader } from '@reviseannuity/randolph-ui';

const store = useApplicationStore()
const { isLoading, user } = useAuth0()

watch(isLoading, async (isLoading) => {
  if (!isLoading) {
    try {
      if (user?.value?.sub) {
        const userData = await getUserByAuth0Id(user?.value?.sub);
        store.setField('user', { ...userData, auth0_id: user?.value?.sub, phone: user?.value?.name });
      } else {
        store.setField('user', { phone: user?.value?.name });
      }
    } catch (error) {
      store.setField('user', { auth0_id: user?.value?.sub, phone: user?.value?.name });
    }
  }
})
</script>