<template>
    <div class="relative w-full bg-white shadow-lg rounded-lg p-6 sm:grid-cols-2 gap-x-8 gap-y-2" :class="{
        'border-2 border-primary-600': bestFit,
        'flex flex-col border border-gray-100 h-full': small,
        'grid': !small
    }">
        <div class="absolute top-0 right-0 flex">
            <div class="text-[0.5em] font-semibold text-green-600 flex items-center"
                :class="{ 'px-2.5 py-1.5': bestFit, 'px-3 py-3': !bestFit }" v-if="rolloverApproved">
                <CheckBadgeIcon class="mr-1 w-3 inline-block" />
                401(k) approved
            </div>
            <BestFitBadge v-if="bestFit" />
        </div>
        <div class="col-span-full">
            <h3 class="text-2xl sm:text-3xl font-serif font-bold col-span-full text-left mb-2">{{ strategy.name }}</h3>
            <div :class="{ 'mb-4': strategy.reviews, 'mb-2': !strategy.reviews }">
                <div class="flex items-center gap-2">
                    <StrategyTypeTag :type="strategy.type" />
                    <div class="flex gap-1 text-xs items-center text-gray-600" v-if="strategy.reviews">
                        <StarRating :rating="compositeReviewRating" small /> ({{ strategy.reviews?.length || 0 }}
                        strategy reviews)
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col justify-between text-left" :class="{ 'mb-8': small }">
            <p class="text-sm text-gray-600 mb-6 sm:mb-8">{{ strategy.shortDescription }}</p>
            <div class="text-center mb-6 sm:mb-0" :class="{ 'sm:text-left': !small }" v-if="strategy.indices?.length">
                <p class="font-semibold text-xs">Crediting options</p>
                <p class="text-xs text-gray-600" v-for="index in strategy.indices" :key="index.id">{{ index.name }}</p>
            </div>
        </div>
        <div class="mt-auto">
            <div class="grid grid-cols-2 text-left gap-2 mt-auto place-items-baseline"
                :class="{ 'sm:gap-6': small, 'sm:gap-3': !small }">
                <template v-for="block in dataBlocks">
                    <strong class="block text-[0.65em] uppercase text-gray-500">{{ block.title }}</strong>
                    <span class="text-base leading-tight font-serif font-bold" v-html="block.value" />
                </template>
            </div>
            <div class="mt-10 mx-auto mb-6" v-if="['growth', 'myga'].includes(strategy.type)">
                <VolatilityChart :value="strategy.volatility" :min="store.minVolatility" :max="store.maxVolatility" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { CheckBadgeIcon } from '@heroicons/vue/20/solid';
import { StarRating } from '@reviseannuity/randolph-ui'
import VolatilityChart from './VolatilityChart.vue';
import { renderYears, formatMoney, renderPercentageRange, renderPercentage } from '@/utils'
import StrategyTypeTag from './StrategyTypeTag.vue';
import { _boundSegment } from 'chart.js/helpers';
import BestFitBadge from './BestFitBadge.vue'
import { Strategy } from '@/types'
import { useQuestionnaireStore } from '@/stores/questionnaire'

const store = useQuestionnaireStore()

const {
    strategy,
    bestFit = false,
    small = false,
    rolloverApproved = false
} = defineProps<{
    strategy: Strategy,
    bestFit?: boolean,
    small?: boolean,
    rolloverApproved?: boolean,
}>()

const compositeReviewRating = strategy.reviews?.length ? strategy.reviews.reduce((acc, review) => acc + review.rating, 0) / strategy.reviews.length : null

let dataBlocks

if (strategy.type === 'growth') {
    dataBlocks = [
        { title: "Hist. Rate", value: renderPercentageRange(strategy.historicalRate) },
        { title: "Free Withdrawal", value: renderPercentage(strategy.underlyingProduct.liquidityProvision) },
        { title: "Surrender Period", value: renderYears(strategy.surrenderPeriod) },
        { title: "Strategy Fee", value: renderPercentage(strategy.strategyFee) },
    ]
}

if (strategy.type === 'hybrid') {
    dataBlocks = [
        { title: "Your Est. Income", value: strategy.estimatedIncome ? `${formatMoney(strategy.estimatedIncome)}` : '-' },
        { title: "Income Growth", value: renderPercentageRange(strategy.incomeGrowthRate) },
        { title: "Surrender Period", value: renderYears(strategy.surrenderPeriod) },
        { title: "Product Fees", value: renderPercentage(strategy.productFee) }
    ]
}

if (strategy.type === 'income') {
    dataBlocks = [
        { title: "Your Est. Income", value: strategy.estimatedIncome ? `${formatMoney(strategy.estimatedIncome)}` : '-' },
        { title: "Income Growth", value: renderPercentageRange(strategy.assetGrowthRate) },
        { title: "Surrender Period", value: renderYears(strategy.surrenderPeriod) },
        { title: "Product Fees", value: renderPercentage(strategy.productFee) }
    ]
}

if (strategy.type === 'myga') {
    dataBlocks = [
        { title: "Guaranteed Rate", value: renderPercentageRange(strategy.historicalRate) },
        { title: "Free Withdrawal", value: renderPercentage(strategy.underlyingProduct.liquidityProvision) },
        { title: "Guarantee Period", value: renderYears(strategy.surrenderPeriod) },
        { title: "Strategy Fee", value: renderPercentage(strategy.strategyFee) },
    ]
}

if (strategy.type === 'bonus') {
    dataBlocks = [
        { title: "Historical rate", value: renderPercentageRange(strategy.historicalRate) },
        { title: "Bonus", value: renderPercentageRange(0.15, 0.2) },
        { title: "Surrender Period", value: renderYears(strategy.surrenderPeriod) },
        { title: "Product Fee", value: renderPercentage(strategy.productFee) }
    ]
}
</script>