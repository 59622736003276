import { FocusOption } from "../questionnaire/types"
import type { WebAuth } from 'auth0-js'
import { Strategy } from "../../types"

export enum RegistrationStep {
    LIQUID_FUNDS = 'registration_liquid_funds',
    QUALIFIED_INVESTMENT_AMOUNT = 'registration_qualified_investment_amount',
    ROLLOVER_BOOKING = 'registration_rollover_booking',
    FUNDING = 'registration_funding',
    INVESTMENT_AMOUNT = 'registration_investment_amount',
    INITIAL_PREMIUM_AMOUNT = 'registration_initial_premium_amount'
}

export interface RegistrationStore {
    steps: RegistrationStep[]
    firstName: string
    lastName: string
    email: string
    phone: string
    consent: boolean
    auth0Id: string
    isRegistrationFormLoading: boolean
    registrationFormErrorMessages: string
    isVerificationCodeSent: boolean
    verificationCode: string
    isVerifyLoading: boolean
    verificationFormErrorMessages: string
    isVerificationCodeResending: boolean
    isVerificationCodeResent: boolean
    strategyName: string
    investmentAmount: number
    state: string
    birthdate: Date
    age: number
    zip: string
    optIn: boolean
    submitting: boolean
    liquidFunds: number
    activeStep: RegistrationStep
    fundingSource: string
    focus: FocusOption
    webAuth: WebAuth
    strategy: Strategy
}