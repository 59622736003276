<template>
    <QuestionnaireSection class="max-w-lg" title="How are you funding your annuity?" :loading="store.submitting"
        helperText="Choose one of the below options." @click="handleContinue" :disableNext="disabled"
        :enableEnterKey="false">
        <div class="grid gap-4 mb-8">
            <button @click="store.setField('fundingSource', fundingSource.value)"
                class="text-left rounded-lg border-2 p-6"
                :class="store.fundingSource == fundingSource.value ? 'border-primary-600 bg-primary-50 focus:outline-none' : 'border-gray-300 bg-white'"
                v-for="fundingSource in fundingSources">
                <div class="text-primary-600 text-xs uppercase font-semibold mb-1">{{ fundingSource.name }}</div>
                <p class="text-xl sm:text-2xl font-bold font-serif tracking-tight"
                    :class="store.fundingSource == fundingSource.value ? 'text-primary-600' : 'text-gray-800'">{{
                        fundingSource.title }}
                </p>
            </button>
        </div>
    </QuestionnaireSection>
    <Modal :open="inServiceWarningOpen" title="You must be separated from service to roll your plan."
        body="You must be separated from service (no longer employed) by the company who sponsored this retirement plan. Check directly with your plan administrator if you can roll in service or reach out when you’ve separated from service at your company."
        button1="Go back" @click:button1="inServiceWarningOpen = false" button2="Confirm" @click:button2="nextStep"
        :button2Loading="store.submitting" />
</template>

<script setup lang="ts">
import QuestionnaireSection from '@/components/questionnaireSections/QuestionnaireSection.vue';
import { onMounted, ref } from 'vue';
import { event } from 'vue-gtag'
import Modal from '@/components/Modal.vue';
import { useRegistrationStore } from '@/stores/registration';
import { RegistrationStep } from '@/stores/registration/types';
import { useRouter } from 'vue-router';
import { updateUser } from '@/clients/user';
import { useToast } from 'vue-toast-notification';

const router = useRouter()
const store = useRegistrationStore()
const toast = useToast()

const inServiceWarningOpen = ref(false);

const fundingSources = [
    {
        value: 'after-tax',
        name: 'After-tax Funds',
        title: 'ACH from a checking, savings, or investment account.'
    },
    {
        value: 'employer',
        name: "Rollover Employer Plan",
        title: "Rollover from an employer plan like a 401(k), 403(b), or TSP."
    },
    {
        value: 'ira',
        name: 'Rollover IRA',
        title: 'Rollover a retirement account like an IRA or Roth IRA.'
    }
]

onMounted(() => {
    event('page_view', {
        page_title: 'Master App Registration - Funding Selection',
        page_location: window.location.href,
        page_path: window.location.pathname
    })
})

const handleContinue = () => {
    if (store.fundingSource == 'employer') {
        inServiceWarningOpen.value = true
        return
    }

    nextStep()
}

const nextStep = async () => {
    store.setField('submitting', true);

    try {
        await updateUser(store.auth0Id, {
            funding_type: store.fundingSource
        })
    } catch (error) {
        toast.error(`An error occurred while updating your funding type. ${error || 'Please try again.'}`);
        return
    } finally {
        store.setField('submitting', false);
    }

    event('funding_source_selected', {
        funding_source: store.fundingSource
    })

    if (store.fundingSource == 'after-tax') {
        store.setActiveStep(RegistrationStep.INITIAL_PREMIUM_AMOUNT)
        return
    } else {
        store.setActiveStep(RegistrationStep.QUALIFIED_INVESTMENT_AMOUNT)
        return
    }
}

onMounted(() => {
    event('page_view', {
        page_title: 'Master App Questionnaire - Funding Source Selection',
        page_location: window.location.href,
        page_path: window.location.pathname
    })
})
</script>