import { ReturnsOption } from "@/stores/questionnaire/types"

export enum StrategyType {
    GROWTH = 'growth',
    INCOME = 'income',
    BONUS = 'bonus',
    HYBRID = 'hybrid',
}

export enum ProductType {
    SPIA = 'spia',
    DIA = 'dia',
    MYGA = 'myga',
    FIA = 'fia'
}

export interface Strategy {
    id: string
    name: string
    volatility: number
    reviews: any[]
    type: StrategyType
    rolloverApproved: boolean
    tags: Array<{
        name: string
        label: string
        color: string
    }>
    returnType: ReturnsOption[]
    matchScore: number
    historicalRate: number
    underlyingProduct: {
        insurer: {
            rating: number
            name: string
            logo: Array<{
                url: string
            }>
            description: string
        }
        liquidityProvision: number
        name: string
        description: string
        brochure: Array<{
            url: string
        }>
        premiumFrequency: string
    }
    index1Percentage: number
    par1High: number
    par1Low: number
    index2Percentage: number
    par2High: number
    par2Low: number
    indices: Array<{
        name: string
        id: string
        strategies: Strategy[]
    }>
    strategyOverview: string
    ourTeamSays: string
    historicalRates: string
    tagline: string
    minimumInvestment: number
    maximumInvestment: number
    strategyFee: number
    shortDescription: string
    surrenderPeriod: number
    productFee: number
    estimatedIncome: number
    break: number
    crediting: string
    cap: number
    floor: number
    incomeGrowthRate: number
    bonus: number
    active: boolean
    category: string
    minimumAge: number
    maximumAge: number
    recommendedMaximumAge: number
    assetGrowthRate: number
    allocationChart: Array<{ url: string }>
    historicalStrategyPerformanceChart: Array<{ url: string }>
    returns: string
    states: string[]
    compare1Insurer: Array<{ url: string }>
    compare1Rate: number
    compare1Indices: string
    compare2Insurer: Array<{ url: string }>
    compare2Rate: number
    compare2Indices: string
}