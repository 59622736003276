<template>
    <div class="h-screen w-screen bg-gray-50 text-gray-500 flex justify-center items-center">Logging you in...</div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useApplicationStore } from '@/stores/application';
import { onMounted } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';

const router = useRouter();
const store = useApplicationStore()
const { isAuthenticated } = useAuth0()

onMounted(async () => {
    if (isAuthenticated && store.user && store.user.status) {
        if (store.user.status === 'application_submitted') {
            router.push({ name: 'dashboard' })
            return
        } else if (store.user.status === 'application_started') {
            router.push({ name: 'apply' });
            return
        } else {
            router.push({ name: 'register' });
            return
        }
    } else {
        router.push({ name: 'questionnaire' });
    }
})
</script>