<template>
    <div class="w-screen h-screen flex justify-center items-center text-gray-400">
        <SpinningLoader class="w-8" color="#1454f2" />
    </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { SpinningLoader } from '@reviseannuity/randolph-ui';
import { useToast } from 'vue-toast-notification';

const toast = useToast()

onMounted(async () => {
    try {
        parent.postMessage({ type: 'handleCallback', hash: window.location.hash }, '*');
    } catch (error) {
        toast.error(`An error occurred while processing the request. ${error.message}`);
    }
})
</script>