<template>
    <Modal :open="questionnaireStore.showPaywall" title="Let's get you signed up." blurBg centerOnMobile
        v-if="!auth0.isAuthenticated.value">
        <template #body>
            <div class="-mb-6">
                <iframe src="/auth/register" class="w-full" :style="{ height: `${iframeHeight}px` }" frameborder="0"
                    scrolling="no" />
            </div>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { onMounted, ref } from 'vue';
import { useQuestionnaireStore } from '@/stores/questionnaire';
import { useRegistrationStore } from '@/stores/registration';

const questionnaireStore = useQuestionnaireStore();
const registrationStore = useRegistrationStore()
const auth0 = useAuth0()
const iframeHeight = ref<number>(0);

onMounted(async () => {
    window.addEventListener('message', async (event) => {
        if (event.data) {
            if (event.data.type === 'setField') {
                return registrationStore.setField(event.data.field, event.data.value);
            }

            if (event.data.type === 'handleCallback') {
                const { authResult, user } = await registrationStore.handleCallback(questionnaireStore, event.data.hash);
                auth0.isAuthenticated.value = true;
                auth0.user.value = user;
                auth0.isLoading.value = false;
                auth0.idTokenClaims.value = authResult.idTokenPayload
                questionnaireStore.setField('showPaywall', false);
                return
            }

            if (event.data.type === 'setHeight') {
                return iframeHeight.value = event.data.height;
            }
        }
    });

    try {
        await auth0.getAccessTokenSilently()
    } catch (error) {
        console.info("No access token found. New user.")
    }
})
</script>