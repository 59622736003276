<template>
    <div class="border rounded">
        <div class="border-b border-gray-200 bg-white px-4 py-3 sm:px-6 flex justify-between items-center">
            <h3 class="text-sm font-semibold leading-6 text-gray-900">
                Beneficiary <span v-if="number">#{{ number }}</span>
            </h3>
            <button @click="$emit('remove')">
                <XMarkIcon class="h-5 w-5 text-gray-400" />
            </button>
        </div>
        <div class="px-4 py-3 space-y-4">
            <div class="grid grid-cols-2 gap-4">
                <RadioField required :inline="true"
                    tooltip="Primary beneficiaries receive the benefits first. Contingent beneficiaries will receive the benefits if the primary beneficiary is unable to do so (e.g., predeceases the account holder). This ensures that the intended distribution plan is followed even if circumstances change."
                    label="Status" :name="`${id}_beneficiaryStatus`" :options="statuses" :value="value.status"
                    @input="e => $emit('update', { key: 'status', value: BeneficiaryStatus[e] })" />
                <RadioField required :inline="true" tooltip="Per stirpes is a way of distributing an annuity
                    death benefit where if a beneficiary should pass before the account holder, their share is divided
                    equally among their descendants. This method ensures that a beneficiary's lineage receives their
                    intended inheritance. Per capita s a method of allocating assets where each living beneficiary receives an equal share of the inheritance. Unlike 
                    Per Stirpes, this method does not distribute a deceased beneficiary's share to their descendants
                    but rather re-distributes it among the surviving beneficiaries." label="Contingency"
                    :name="`${id}_beneficiaryContingency`" :options="contingencies" :value="value.contingency"
                    @input="e => $emit('update', { key: 'contingency', value: BeneficiaryContingency[e] })"
                    v-if="value.type == BeneficaryType.INDIVIDUAL" />
            </div>
            <PersonInput :name="`${id}_personInput`" label="Beneficiary Info"
                v-if="value.type == BeneficaryType.INDIVIDUAL" :value="value"
                @update="({ key, value }) => $emit('update', { key, value })" />
            <CorporationInput label="Beneficiary Info"
                v-if="[BeneficaryType.CORPORATION, BeneficaryType.TRUST].includes(value.type)" :value="value" />
            <div class="grid grid-cols-2 gap-4">
                <SelectField label="Relationship to Owner" :options="relationshipOptions" :value="value.relationship"
                    @input="e => $emit('update', { key: 'relationship', value: e })" required />
                <TextField required label="Percentage" tooltip="How much of the benefits will go to this beneficiary."
                    :name="`${id}_percentage`" type="number" :value="value.percentage"
                    @input="e => $emit('update', { key: 'percentage', value: e })" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    RadioField,
    TextField,
    SelectField,
    PersonInput
} from '@reviseannuity/randolph-ui'
import CorporationInput from './CorporationInput.vue';
import { XMarkIcon } from '@heroicons/vue/20/solid';
import { Beneficiary, BeneficiaryContingency, BeneficiaryRelationship, BeneficiaryStatus, BeneficaryType } from '@/stores/application/types';

const {
    value,
    id,
    number
} = defineProps<{
    value: Beneficiary
    id: string
    number: number
}>();
defineEmits(['remove', 'update']);

const types: Array<{
    label: string
    value: BeneficaryType
}> = [
        { label: 'Individual', value: BeneficaryType.INDIVIDUAL },
        { label: 'Trust', value: BeneficaryType.TRUST },
        { label: 'Corporation', value: BeneficaryType.CORPORATION }
    ];
const statuses: Array<{
    label: string
    value: BeneficiaryStatus
}> = [
        { label: 'Primary', value: BeneficiaryStatus.PRIMARY },
        { label: 'Contingent', value: BeneficiaryStatus.CONTINGENT }
    ];
const contingencies: Array<{
    label: string
    value: BeneficiaryContingency
}> = [
        { label: 'Per Capita', value: BeneficiaryContingency.PER_CAPITA },
        { label: 'Per Stirpes', value: BeneficiaryContingency.PER_STIRPES },
    ];
const relationshipOptions: Array<{
    label: string
    value: BeneficiaryRelationship
}> = [
        { label: "Aunt", value: BeneficiaryRelationship.AUNT },
        { label: "Brother-in-law", value: BeneficiaryRelationship.BROTHER_IN_LAW },
        { label: "Child", value: BeneficiaryRelationship.CHILD },
        { label: "Cousin", value: BeneficiaryRelationship.COUSIN },
        { label: "Daughter-in-law", value: BeneficiaryRelationship.DAUGHTER_IN_LAW },
        { label: "Domestic Partner", value: BeneficiaryRelationship.DOMESTIC_PARTNER },
        { label: "Ex-Spouse", value: BeneficiaryRelationship.EX_SPOUSE },
        { label: "Father-in-law", value: BeneficiaryRelationship.FATHER_IN_LAW },
        { label: "Fiance", value: BeneficiaryRelationship.FIANCE },
        { label: "Friend", value: BeneficiaryRelationship.FRIEND },
        { label: "Godchild", value: BeneficiaryRelationship.GODCHILD },
        { label: "Grandchild", value: BeneficiaryRelationship.GRANDCHILD },
        { label: "Grandparent", value: BeneficiaryRelationship.GRANDPARENT },
        { label: "Mother-in-law", value: BeneficiaryRelationship.MOTHER_IN_LAW },
        { label: "Nephew", value: BeneficiaryRelationship.NEPHEW },
        { label: "Niece", value: BeneficiaryRelationship.NIECE },
        { label: "Parent", value: BeneficiaryRelationship.PARENT },
        { label: "Sibling", value: BeneficiaryRelationship.SIBLING },
        { label: "Sister-in-law", value: BeneficiaryRelationship.SISTER_IN_LAW },
        { label: "Son-in-law", value: BeneficiaryRelationship.SON_IN_LAW },
        { label: "Spouse", value: BeneficiaryRelationship.SPOUSE },
        { label: "Step parent", value: BeneficiaryRelationship.STEP_PARENT },
        { label: "Step sibling", value: BeneficiaryRelationship.STEP_SIBLING },
        { label: "Step child", value: BeneficiaryRelationship.STEP_CHILD },
        { label: "Surviving owner", value: BeneficiaryRelationship.SURVIVING_OWNER },
        { label: "Surviving spouse", value: BeneficiaryRelationship.SURVIVING_SPOUSE },
        { label: "Uncle", value: BeneficiaryRelationship.UNCLE },
    ];
</script>