<template>
    <SectionContainer title="Funding Sources" @nextStep="() => {
        event('funding_sources_complete')
        nextStep()
    }" @prevStep="prevStep" :button-disabled="!store.steps[stepIndex].complete()">
        <template v-slot:helperText>
            <HelperText>
                Your selected
                insurance carrier will initiate a funds transfer directly via ACH upon approval. Revise does not act
                as a financial intermediary.
            </HelperText>
            <div v-if="true" class="mt-2 flex">
                <div class="bg-green-600 flex text-white items-center px-1.5 py-1 rounded text-xs font-semibold gap-1">
                    <ShieldCheckIcon class="w-3.5" /> Secure & 256-bit Encypted
                </div>
            </div>
        </template>
        <div class="space-y-4">
            <div class="flex flex-col sm:flex-row justify-end items-start gap-4">
                <SecondaryButton @click="store.addFundingSource">
                    <div class="flex items-center">
                        <PlusIcon class="h-4 w-4 inline-block mr-1" />
                        Add Funding Source
                    </div>
                </SecondaryButton>
            </div>
            <FundingSource v-for="(source, i) in store.fundingSources" :id="source.id" :key="source.id"
                :sourceType="store.fundingSourceType" />
        </div>
        <ErrorMessage v-if="errorMessage">
            {{ errorMessage }}
        </ErrorMessage>
    </SectionContainer>

</template>

<script setup lang="ts">
import FundingSource from '@/components/FundingSource.vue';
import { ref, watch } from 'vue';
import SectionContainer from '@/components/SectionContainer.vue';
import { SecondaryButton, ErrorMessage, HelperText } from '@reviseannuity/randolph-ui';
import { useApplicationStore } from '@/stores/application';
import { PlusIcon } from '@heroicons/vue/24/outline';
import { useStep } from '@/composables/useStep';
import { formatMoney } from '@/utils';
import { event } from 'vue-gtag';
import { ShieldCheckIcon } from '@heroicons/vue/16/solid';

const store = useApplicationStore();
const { nextStep, prevStep, stepIndex } = useStep(store)

const errorMessage = ref(null)

watch(store.fundingSources, () => {
    if (store.fundingSources?.length > 0) {
        if (store.totalFunded !== store.investmentAmount) {
            errorMessage.value = `Total funding source amounts must equal ${formatMoney(store.investmentAmount)}`
        } else {
            errorMessage.value = null
        }
    }
})
</script>