<template>
    <div class="grid grid-cols-2 gap-4">
        <p class="text-sm text-gray-500 col-span-full">Sign up to view results immediately.</p>
        <TextField label="First Name" class="" required :value="store.firstName"
            @input="v => setField('firstName', v)" />
        <TextField label="Last Name" class="" required :value="store.lastName" @input="v => setField('lastName', v)" />
        <TextField label="Email Address" class="col-span-full" required :value="store.email"
            @input="v => setField('email', v)" :error="store.emailValidationError" />
        <PhoneField label="Phone" class="col-span-full" required :value="store.phone" @input="v => setField('phone', v)"
            :error="store.phoneValidationError" />
        <HelperText class="text-xs col-span-full -mt-3">We'll send you a code to verify your phone number. Standard
            message and data rates may apply.</HelperText>
        <div class="col-span-">
        </div>
        <CheckboxField :value="store.consent" @input="v => setField('consent', v)"
            label="<p class='text-xs leading-0 mt-0.5'>I consent to receiving communications from Revise Annuity in accordance with our <a href='https://getrevise.com/privacy' class='underline' target='_blank'>privacy policy</a>.</p>"
            class="text-sm text-gray-600 col-span-full" />
        <ErrorMessage v-if="store.registrationFormErrorMessages" class="col-span-full">{{
            store.registrationFormErrorMessages }}
        </ErrorMessage>
        <div class="col-span-full flex justify-center">
            <PrimaryButton @click="store.register" :loading="store.isRegistrationFormLoading"
                :disabled="!store.isRegistrationFormValid">Get verification code
            </PrimaryButton>
        </div>
    </div>
</template>

<script setup lang="ts">
import { CheckboxField, ErrorMessage, HelperText, PhoneField, PrimaryButton, TextField } from '@reviseannuity/randolph-ui';
import { useRegistrationStore } from '@/stores/registration';

const store = useRegistrationStore()

const setField = (field, value) => {
    store.setField(field, value)
    parent.postMessage({ type: 'setField', field, value }, '*');
}
</script>