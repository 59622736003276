import { createUser } from '../../clients/user';
import { signUpEvents } from '../../eventTagging';
import { useToast } from 'vue-toast-notification';
import { RegistrationStore, RegistrationStep } from './types';
import { QuestionnaireStore } from '../questionnaire/types';

const toast = useToast()

export default {
    setField(field: keyof RegistrationStore, value: any): void {
        this[field] = value;
    },
    setActiveStep(step: RegistrationStep): void {
        this.activeStep = step;
        this.router.push({ name: step });
    },
    async register(): Promise<void> {
        const state = this as RegistrationStore

        try {
            state.isRegistrationFormLoading = true
            state.registrationFormErrorMessages = ''

            await new Promise((res, rej) => {
                state.webAuth.passwordlessStart({
                    connection: 'sms',
                    send: 'code',
                    phoneNumber: `+1${state.phone}`
                }, function (err, response) {
                    if (err) {
                        rej(new Error(err.message))
                    }

                    res(response)
                });
            })

            state.isRegistrationFormLoading = false
            state.isVerificationCodeSent = true
        } catch (error) {
            state.registrationFormErrorMessages = error
        }
    },
    verifyCode(): void {
        const state = this as RegistrationStore

        state.isVerifyLoading = true
        state.verificationFormErrorMessages = ''

        if (!/\d{6}/g.test(state.verificationCode.trim())) {
            state.verificationFormErrorMessages = 'Verification code must be 6 numbers'
            state.isVerifyLoading = false
            return
        }

        state.webAuth.passwordlessLogin({
            connection: 'sms',
            phoneNumber: `+1${state.phone}`,
            verificationCode: state.verificationCode
        }, function (error, res) {
            state.isVerifyLoading = false

            if (error) {
                state.verificationFormErrorMessages = error.error_description
                return
            }
        });
    },
    async resendCode(): Promise<void> {
        const state = this as RegistrationStore

        try {
            state.isVerificationCodeResent = false
            state.isVerificationCodeResending = true
            await this.register()
            state.isVerificationCodeResending = false
            state.isVerificationCodeResent = true
            setTimeout(() => {
                state.isVerificationCodeResent = false
            }, 3000)
        } catch (error) {
            toast.error(`${error.message}`);
        }
    },
    async handleCallback(questionnaireStore: QuestionnaireStore, hash: string): Promise<{
        authResult: { accessToken: string, idTokenPayload: any },
        user: { sub: string }
    }> {
        const state = this as RegistrationStore

        try {
            const authResult = await new Promise<{ accessToken: string, idTokenPayload: string }>((resolve, reject) => {
                state.webAuth.parseHash({ hash }, function (err, authResult) {
                    if (err) {
                        reject(err);
                    }

                    resolve(authResult);
                })
            })

            const user = await new Promise<{ sub: string }>((resolve, reject) => {
                state.webAuth.client.userInfo(authResult.accessToken, async (err, user) => {
                    if (err) {
                        reject(err);
                    }

                    resolve(user);
                })
            })

            state.auth0Id = user.sub;
            const auth0Id = state.auth0Id;
            const firstName = state.firstName;
            const lastName = state.lastName;
            const email = state.email;
            const phone = `+1${state.phone}`;
            const strategyName = questionnaireStore?.strategy?.name;
            const investmentAmount = questionnaireStore.investmentAmount;
            const userState = questionnaireStore.state;
            const birthdate = questionnaireStore.birthdate.toLocaleDateString();
            const age = questionnaireStore.age;
            const zip = questionnaireStore.zipCode;

            await createUser({
                auth0_id: auth0Id,
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phone,
                strategy_name: strategyName,
                funding_amount: investmentAmount,
                state: userState,
                birthdate: birthdate,
                age: age,
                zip: zip
            });

            signUpEvents(
                email,
                firstName,
                lastName,
                phone,
                strategyName,
                investmentAmount,
                birthdate,
                age
            )

            return { authResult, user };
        } catch (error) {
            toast.error(`${error.message}`);
        }
    }
}