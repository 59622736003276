<template>
    <div class="bg-[#f1f5f9] fixed top-0 left-0 w-full z-50">
        <div class="max-w-sm mx-auto px-4">
            <div class="pt-4 mb-4 sm:mb-6 flex flex-col items-center gap-4">
                <div class="flex justify-between w-full items-center">
                    <button @click="store.setActiveStep(store.previousStep)" class=" w-8 h-8 -m-2 rounded-full hover:bg-primary-300 flex items-center
                        justify-center" v-if="store.previousStep">
                        <ArrowLeftIcon class="w-4 h-4 text-gray-500 text-primary-600" />
                    </button>
                    <img :src="logo" alt="Revise Annuity Group logo" class="mx-auto h-5" />
                </div>
                <ProgressBar :progress="store.percentage" class="w-full" />
            </div>
        </div>
    </div>
    <div class="pt-20 sm:pt-24 px-4 pb-8">
        <RouterView />
    </div>

</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useRegistrationStore } from '@/stores/registration';
import { useApplicationStore } from '@/stores/application';
import { ArrowLeftIcon } from '@heroicons/vue/20/solid';
import { ProgressBar } from '@reviseannuity/randolph-ui'
import { RouterView } from 'vue-router';
import { setBgColor } from '@/utils';
import { RegistrationStep } from '@/stores/registration/types';
import logo from '@/assets/logo.svg'

const router = useRouter();

const {
    user: {
        auth0_id,
        funding_type,
        funding_amount,
        rollover_call
    }
} = useApplicationStore()

const store = useRegistrationStore();

onMounted(() => {
    if (!store.auth0Id) {
        store.setField('auth0Id', auth0_id)
    }

    setBgColor('#f1f5f9')

    const registrationComplete = localStorage.getItem('registrationComplete');
    if (registrationComplete) {
        router.push({ name: 'apply' });
    }

    if (!funding_type) {
        return store.setActiveStep(RegistrationStep.FUNDING)
    }

    if (!funding_amount) {
        if (funding_type === 'after-tax') {
            return store.setActiveStep(RegistrationStep.INITIAL_PREMIUM_AMOUNT)
        } else {
            return store.setActiveStep(RegistrationStep.QUALIFIED_INVESTMENT_AMOUNT)
        }
    }

    if (funding_type !== 'after-tax' && rollover_call) {
        return store.setActiveStep(RegistrationStep.ROLLOVER_BOOKING)
    }

    router.push({ name: 'apply' })
})
</script>