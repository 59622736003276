<template>
    <SDPLoader v-if="!strategy" />
    <div v-else class="bg-white rounded-lg shadow-lg max-w-2xl mx-auto mb-12">
        <Header :strategy="strategy" :totalReviews="reviews?.length" :rating="rating" />
        <Stats :strategy="strategy" />
        <Tags :strategy="strategy" />
        <CreditWorthiness />
        <Details :strategy="strategy" />
        <StrategyComparison :strategy="strategy" :comparisons="comparisons" v-if="comparisons" />
        <HybridCalculator v-if="strategy?.type === 'hybrid'" />
        <IncomeCalculator v-if="strategy?.type === 'income'" />
        <GrowthCalculator :strategy="strategy" v-if="strategy?.type === 'growth'" />
        <VideoSalesLetter />
        <WhyRevise />
        <MoneybackGuarantee />
        <Reviews :reviews="reviews" :rating="rating" v-if="reviews?.length" />
        <Recommendations :recommendedStrategies="recommendedStrategies" v-if="recommendedStrategies.length"
            @selectStrategy="strategyId => handleRecommendationClick(strategyId)" />
        <Disclaimers />
    </div>
</template>

<script setup lang="ts">
import { useQuestionnaireStore } from '@/stores/questionnaire';
import { useRoute, useRouter } from 'vue-router';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { event } from 'vue-gtag';
import {
    MoneybackGuarantee,
    CreditWorthiness,
    SDPLoader,
    Header,
    Stats,
    Tags,
    Details,
    GrowthCalculator,
    Reviews,
    Recommendations,
    Disclaimers,
    HybridCalculator,
    IncomeCalculator,
    StrategyComparison,
    WhyRevise,
} from '@/components/strategyDetailsSections';
import { getStrategy } from '@/clients/strategies';
import { QuestionnaireStep } from '@/stores/questionnaire/types';
import VideoSalesLetter from '../strategyDetailsSections/VideoSalesLetter.vue';

const store = useQuestionnaireStore()
const route = useRoute()
const router = useRouter()
const strategy = ref(null)
const recommendedStrategies = ref(null)
const reviews = ref(null)
const rating = ref(0)
const comparisons = ref(null)

let exitPopupTimeout

onMounted(async () => {
    store.setStep(QuestionnaireStep.STRATEGY_DETAIL)
    let strategyId = route.params.strategyId
    strategyId = Array.isArray(strategyId) ? strategyId[0] : strategyId
    await loadStrategy(strategyId)
})

watch(() => store.enableExitPopup, (enabled) => {
    if (enabled) {
        exitPopupTimeout = setTimeout(() => {
            if (!store.dontShowPopupAgain) {
                store.setField('showExitPopup', true)
            }
        }, 20000)
    }
})

watch(() => route.params.strategyId, async (strategyId) => {
    strategy.value = null
    strategyId = Array.isArray(strategyId) ? strategyId[0] : strategyId
    await loadStrategy(strategyId)
})

onUnmounted(() => {
    clearTimeout(exitPopupTimeout)
})

const handleRecommendationClick = (strategyId: string) => {
    router.push({ name: 'strategyDetails', params: { strategyId }, query: { ref: 'sdp' } })
}

const loadStrategy = async (strategyId: string): Promise<void> => {
    strategy.value = await getStrategy(strategyId)
    recommendedStrategies.value = store.strategies.filter(s => s.id != strategy.value.id).slice(0, 3)
    if (strategy.value?.reviews?.length) {
        reviews.value = strategy.value.reviews
        rating.value = reviews.value.reduce((acc, review) => acc + review.rating, 0) / reviews.value.length
    } else {
        reviews.value = null
        rating.value = 0
    }

    if (strategy.value.compare1Insurer && strategy.value.compare2Insurer) {
        comparisons.value = [
            {
                insurer: strategy.value.compare1Insurer,
                rate: strategy.value.compare1Rate,
                indices: strategy.value.compare1Indices?.split(',')
            },
            {
                insurer: strategy.value.compare2Insurer,
                rate: strategy.value.compare2Rate,
                indices: strategy.value.compare2Indices?.split(',')
            }
        ]
    }

    event('page_view', {
        page_title: 'Master App Questionnaire - Marketplace - Strategy Details',
        page_location: window.location.href,
        page_path: window.location.pathname
    })
    event('sdp_loaded', {
        is_best_fit: store.strategies.length && strategy.value.id == store.strategies[0].id,
        referrer: route?.query?.ref
    })
}
</script>
