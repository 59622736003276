<template>
    <Line :data="data" :options="options" />
</template>

<script setup lang="ts">
import { defineProps, ref, watch } from 'vue'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartData
} from 'chart.js'
import { Line } from 'vue-chartjs'
import { formatMoney } from '@/utils';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const {
    productGrowth,
    comparisonGrowth,
    name
} = defineProps<{
    productGrowth: number[]
    comparisonGrowth: number[]
    name: string
}>()

const data = ref<ChartData<'line'>>({
    labels: ["Year 1", "Year 2", "Year 3", "Year 4", "Year 5", "Year 6", "Year 7", "Year 8", "Year 9", "Year 10"],
    datasets: [
        {
            label: name,
            backgroundColor: '#1454f2',
            borderColor: '#1454f2',
            tension: 0.3,
            borderWidth: 2,
            data: productGrowth
        },
        {
            label: 'CDs/Money Market',
            backgroundColor: '#152256',
            borderColor: '#152256',
            tension: 0.3,
            borderWidth: 2,
            data: comparisonGrowth
        }
    ]
})

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        tooltip: {
            callbacks: {
                label: function (context) {
                    return formatMoney(context.parsed.y)
                }
            }
        },
    },
    scales: {
        x: {
            border: {
                display: false
            }
        },
        y: {
            ticks: {
                callback: (value) => {
                    return formatMoney(value);
                }
            }
        }
    }
}

watch(() => [productGrowth, comparisonGrowth], () => {
    data.value = {
        ...data.value,
        datasets: [
            {
                ...data.value.datasets[0],
                data: productGrowth
            },
            {
                ...data.value.datasets[1],
                data: comparisonGrowth
            }
        ]
    }
})

</script>