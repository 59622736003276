<template>
    <Modal :open="open" @close="close" button1="Cancel" @click:button1="close">
        <GHLCalendar :calendarId="calendarId" @bookingComplete="e => $emit('bookingComplete', e)" />
    </Modal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import GHLCalendar from './GHLCalendar.vue';
import Modal from './Modal.vue';
import { GHLCalendarData } from '@/stores/questionnaire/types';

defineProps({
    open: Boolean,
    calendarId: String
})
const emit = defineEmits<{
    (e: 'close'): void,
    (e: 'bookingComplete', data: GHLCalendarData): void
}>()
const showCal = ref(false)

const close = () => {
    emit('close')
    showCal.value = false
}
</script>