import { productTypeOptions, returnsOptions } from "../../data/strategyList";
import { QuestionnaireStep, QuestionnaireStore, SortOption } from "./types";

const state = (): QuestionnaireStore => ({
    steps: {
        [QuestionnaireStep.ZIP]: {
            validate: (state) => state.zipCode !== null && state.zipCode.length === 5,
            next: () => QuestionnaireStep.BIRTHDATE
        },
        [QuestionnaireStep.BIRTHDATE]: {
            validate: (state) => state.age !== null || (state.birthdate !== null && state.birthdate instanceof Date),
            next: (state) => state.age > 40 ? QuestionnaireStep.FOCUS : QuestionnaireStep.RISK_TOLERANCE
        },
        [QuestionnaireStep.RISK_TOLERANCE]: {
            validate: (state) => state.riskTolerance !== null,
            next: () => QuestionnaireStep.INVESTMENT_AMOUNT
        },
        [QuestionnaireStep.INVESTMENT_AMOUNT]: {
            validate: (state) => state.investmentAmount > 0,
            next: () => QuestionnaireStep.STRATEGY_LIST
        },
        [QuestionnaireStep.FOCUS]: {
            validate: (state) => state.focus !== null,
            next: (state) => {
                if (state.focus === 'growth') return QuestionnaireStep.RISK_TOLERANCE
                return QuestionnaireStep.TIMELINE
            }
        },
        [QuestionnaireStep.TIMELINE]: {
            validate: (state) => state.timeline !== null,
            next: (state) => {
                if (state.timeline === 'immediately') return QuestionnaireStep.IMMEDIATE_INCOME
                return QuestionnaireStep.RETIREMENT_AGE
            }
        },
        [QuestionnaireStep.IMMEDIATE_INCOME]: {
            validate: () => true,
            next: () => null
        },
        [QuestionnaireStep.STRATEGY_LIST]: {
            validate: () => true,
            next: () => QuestionnaireStep.STRATEGY_DETAIL
        },
        [QuestionnaireStep.STRATEGY_DETAIL]: {
            validate: () => true,
            next: () => null
        },
        [QuestionnaireStep.RETIREMENT_AGE]: {
            validate: (state) => state.retirementAge > state.age,
            next: () => QuestionnaireStep.INVESTMENT_AMOUNT
        }
    },
    activeStep: QuestionnaireStep.ZIP,
    previousStep: null,
    state: null,
    zipCode: '',
    birthdate: null,
    investmentAmount: 0,
    retirementAge: null,
    riskTolerance: null,
    fundingSource: null,
    strategies: [],
    loadingStrategies: true,
    minVolatility: 0,
    maxVolatility: 0,
    loading: true,
    strategyLoadingError: null,
    age: null,
    focus: null,
    sortedResults: [],
    sortBy: SortOption.BEST_MATCH,
    minimumRating: 0,
    productType: productTypeOptions.map(option => option.value),
    useCase: null,
    returns: returnsOptions.map(option => option.value),
    showBirthdateChecks: false,
    timeline: null,
    enableExitPopup: false,
    dontShowPopupAgain: false,
    showExitPopup: false,
    showPaywall: false
})

export default state