<template>
    <SDPWrapper thin>
        <SDPTitle>Lifetime income calculator</SDPTitle>
        <SDPDescription>Use this helpful calculator to help determine how much premium you'll need to purchase in order
            to reach your monthly income goals.
        </SDPDescription>
        <div class="grid lg:grid-cols-2 gap-x-12 gap-y-8">
            <div class="space-y-8">
                <RadioField label="I want to see my..." @input="v => solveFor = v" :value="solveFor"
                    :options="solveForOptions" :inline="true" />
                <RadioField label="I want coverage for..." @input="v => coveredLives = v" :value="coveredLives"
                    :options="coveredLivesOptions" :inline="true" />
            </div>
            <div class="space-y-2">
                <div v-if="solveFor === SolveFor.INCOME">
                    <MoneyField label="Your investment amount" :value="store.investmentAmount"
                        @input="v => store.setField('investmentAmount', Number(v))" />
                    <ErrorMessage v-if="store.investmentAmount < 20000 || store.investmentAmount > 20000000">
                        Please enter a value between $20,000 and $20,000,000.
                        <div class="text-gray-900">Required premium amount to
                            purchase the Income Planning Annuity fixed index annuity must be at least $20,000.
                            Initial and subsequent premium amounts over $1,000,000 require Home Office
                            approval.</div>
                    </ErrorMessage>
                </div>
                <div v-if="solveFor === SolveFor.PREMIUM">
                    <MoneyField :value="income" @input="v => income = v" label="Desired monthly income" />
                    <ErrorMessage v-if="store.investmentAmount < 20000">
                        Please
                        enter a higher amount
                        for Desired Income. The resulting premium is less than $20,000.
                    </ErrorMessage>
                    <ErrorMessage v-if="store.investmentAmount > 20000000">
                        Please
                        enter a lower amount
                        for Desired Income. The resulting premium is more than $20,000,000. </ErrorMessage>
                </div>

                <div>
                    <FieldLabel for="currentAge">Current Age <span v-if="coveredLives == CoveredLives.JOINT"
                            class="text-xs text-gray-600">(Enter
                            the
                            younger person's age)</span>
                    </FieldLabel>
                    <TextField :value="currentAge" name="currentAge" min="50" max="79" type="number"
                        @input="v => currentAge = v" />
                    <ErrorMessage v-if="currentAge && currentAge < 50 && currentAge > 79">
                        Current
                        age
                        must be between 50 and
                        79.
                    </ErrorMessage>
                </div>
                <div>
                    <TextField label="Age I'd like to start receiving
                            income" :value="attainedAge" name="attainedAge" :min="currentAge" max="79" type="number"
                        @input="v => attainedAge = v" />
                    <ErrorMessage v-if="attainedAge && attainedAge < currentAge">Please
                        enter
                        a number equal to or
                        greater than your current age.</ErrorMessage>
                    <ErrorMessage v-if="attainedAge < 50 || attainedAge > 115">Age to
                        start
                        receiving income must
                        be between 50 and 115.</ErrorMessage>
                </div>


            </div>
            <div class="col-span-full">
                <div v-if="solveFor === SolveFor.INCOME" class="space-y-6">
                    <StatCard :title="`Estimated Monthly Income *`" :stat="income" :formatter="formatMoney" />
                </div>
                <div v-if="solveFor === SolveFor.PREMIUM" class="space-y-6">
                    <StatCard title="Required investment amount *" :stat="store.investmentAmount"
                        :formatter="formatMoney" tooltip="tooltip" />
                    <ErrorMessage v-if="store.investmentAmount < 20000">
                        Required premium amount
                        to
                        purchase the Income Planning Annuity fixed index annuity must be at least $20,000.
                        Initial and subsequent premium amounts over $1,000,000 require Home Office approval.
                    </ErrorMessage>
                </div>
            </div>
        </div>
        <div class="disclaimer text-center lg:mt-4">
            <p>* The displayed periodic income is calculated as an estimate for illustrative purposes only, not as a
                guarantee or prediction of future rates. Actual income may vary depending on product and insurance
                carrier.</p>
        </div>
    </SDPWrapper>
</template>

<script setup lang="ts">
import { onMounted, onBeforeMount, ref, watch } from 'vue';
import {
    FieldLabel,
    RadioField,
    TextField,
    MoneyField,
    ErrorMessage
} from '@reviseannuity/randolph-ui'
import StatCard from '@/components/StatCard.vue';
import { formatMoney } from '@/utils';
import SDPWrapper from './SDPWrapper.vue';
import SDPTitle from './SDPTitle.vue';
import SDPDescription from './SDPDescription.vue';
import { useQuestionnaireStore } from '@/stores/questionnaire';

enum SolveFor {
    PREMIUM = 'premium',
    INCOME = 'income'
}

const solveForOptions = [
    { value: SolveFor.INCOME, label: 'Monthly income' },
    { value: SolveFor.PREMIUM, label: 'Required investment amount' },
]

enum CoveredLives {
    SINGLE = 'single',
    JOINT = 'joint'
}

const coveredLivesOptions = [
    { value: CoveredLives.SINGLE, label: 'Myself' },
    { value: CoveredLives.JOINT, label: 'My spouse and I' }
]

const store = useQuestionnaireStore()
const solveFor = ref(SolveFor.INCOME)
const income = ref(20000)
const coveredLives = ref(CoveredLives.SINGLE)
const currentAge = ref(store.age)
const attainedAge = ref(store.age + 10)

function calculateRate(age, yearsUntilPayout, coveredLives = CoveredLives.SINGLE) {
    const rates = {
        [CoveredLives.SINGLE]: {
            50: [0.057879900000, 0.063667890000, 0.070034679000, 0.077038146900, 0.084741961590, 0.093216157749, 0.102537773524, 0.112791550876, 0.124070705964, 0.136477776560, 0.150125554216, 0.153128065301, 0.156190626607, 0.159314439139, 0.162500727922, 0.165750742480, 0.169065757330, 0.172447072476, 0.175896013926, 0.179413934204, 0.183002212888, 0.186662257146, 0.190395502289, 0.194203412335, 0.198087480582, 0.202049230193],
            51: [0.057879900000, 0.063667890000, 0.070034679000, 0.077038146900, 0.084741961590, 0.093216157749, 0.102537773524, 0.112791550876, 0.124070705964, 0.136477776560, 0.150125554216, 0.153128065301, 0.156190626607, 0.159314439139, 0.162500727922, 0.165750742480, 0.169065757330, 0.172447072476, 0.175896013926, 0.179413934204, 0.183002212888, 0.186662257146, 0.190395502289, 0.194203412335, 0.198087480582, 0.202049230193],
            52: [0.057879900000, 0.063667890000, 0.070034679000, 0.077038146900, 0.084741961590, 0.093216157749, 0.102537773524, 0.112791550876, 0.124070705964, 0.136477776560, 0.150125554216, 0.153128065301, 0.156190626607, 0.159314439139, 0.162500727922, 0.165750742480, 0.169065757330, 0.172447072476, 0.175896013926, 0.179413934204, 0.183002212888, 0.186662257146, 0.190395502289, 0.194203412335, 0.198087480582, 0.202049230193],
            53: [0.057879900000, 0.063667890000, 0.070034679000, 0.077038146900, 0.084741961590, 0.093216157749, 0.102537773524, 0.112791550876, 0.124070705964, 0.136477776560, 0.150125554216, 0.153128065301, 0.156190626607, 0.159314439139, 0.162500727922, 0.165750742480, 0.169065757330, 0.172447072476, 0.175896013926, 0.179413934204, 0.183002212888, 0.186662257146, 0.190395502289, 0.194203412335, 0.198087480582, 0.202049230193],
            54: [0.057879900000, 0.063667890000, 0.070034679000, 0.077038146900, 0.084741961590, 0.093216157749, 0.102537773524, 0.112791550876, 0.124070705964, 0.136477776560, 0.150125554216, 0.153128065301, 0.156190626607, 0.159314439139, 0.162500727922, 0.165750742480, 0.169065757330, 0.172447072476, 0.175896013926, 0.179413934204, 0.183002212888, 0.186662257146, 0.190395502289, 0.194203412335, 0.198087480582, 0.202049230193],
            55: [0.058447350000, 0.064292085000, 0.070721293500, 0.077793422850, 0.085572765135, 0.094130041649, 0.103543045813, 0.113897350395, 0.125287085434, 0.137815793978, 0.151597373375, 0.154629320843, 0.157721907260, 0.160876345405, 0.164093872313, 0.167375749759, 0.170723264754, 0.174137730050, 0.177620484651, 0.181172894344, 0.184796352230, 0.188492279275, 0.192262124860, 0.196107367358, 0.200029514705, 0.204030104999],
            56: [0.059446450000, 0.065391095000, 0.071930204500, 0.079123224950, 0.087035547445, 0.095739102190, 0.105313012408, 0.115844313649, 0.127428745014, 0.140171619516, 0.154188781467, 0.157272557097, 0.160418008238, 0.163626368403, 0.166898895771, 0.170236873687, 0.173641611160, 0.177114443384, 0.180656732251, 0.184269866896, 0.187955264234, 0.191714369519, 0.195548656909, 0.199459630048, 0.203448822649, 0.203448822649],
            57: [0.060545460000, 0.066600006000, 0.073260006600, 0.080586007260, 0.088644607986, 0.097509068785, 0.107259975663, 0.117985973229, 0.129784570552, 0.142763027608, 0.157039330368, 0.160180116976, 0.163383719315, 0.166651393701, 0.169984421575, 0.173384110007, 0.176851792207, 0.180388828051, 0.183996604612, 0.187676536705, 0.191430067439, 0.195258668787, 0.199163842163, 0.203147119006, 0.203147119006, 0.203147119006],
            58: [0.061544560000, 0.067699016000, 0.074468917600, 0.081915809360, 0.090107390296, 0.099118129326, 0.109029942258, 0.119932936484, 0.131926230132, 0.145118853146, 0.159630738460, 0.162823353229, 0.166079820294, 0.169401416700, 0.172789445034, 0.176245233935, 0.179770138613, 0.183365541385, 0.187032852213, 0.190773509257, 0.194588979443, 0.198480759031, 0.202450374212, 0.202450374212, 0.202450374212, 0.202450374212],
            59: [0.062543660000, 0.068798026000, 0.075677828600, 0.083245611460, 0.091570172606, 0.100727189867, 0.110799908853, 0.121879899739, 0.134067889712, 0.147474678684, 0.162222146552, 0.165466589483, 0.168775921273, 0.172151439698, 0.175594468492, 0.179106357862, 0.182688485019, 0.186342254720, 0.190069099814, 0.193870481810, 0.197747891447, 0.201702849275, 0.201702849275, 0.201702849275, 0.201702849275, 0.201702849275],
            60: [0.063642670000, 0.070006937000, 0.077007630700, 0.084708393770, 0.093179233147, 0.102497156462, 0.112746872108, 0.124021559319, 0.136423715251, 0.150066086776, 0.165072695453, 0.168374149362, 0.171741632349, 0.175176464996, 0.178679994296, 0.182253594182, 0.185898666066, 0.189616639387, 0.193408972175, 0.197277151618, 0.201222694651, 0.201222694651, 0.201222694651, 0.201222694651, 0.201222694651, 0.201222694651],
            61: [0.064641770000, 0.071105947000, 0.078216541700, 0.086038195870, 0.094642015457, 0.104106217003, 0.114516838703, 0.125968522573, 0.138565374831, 0.152421912314, 0.167664103545, 0.171017385616, 0.174437733328, 0.177926487995, 0.181485017755, 0.185114718110, 0.188817012472, 0.192593352721, 0.196445219776, 0.200374124171, 0.200374124171, 0.200374124171, 0.200374124171, 0.200374124171, 0.200374124171, 0.200374124171],
            62: [0.065740780000, 0.072314858000, 0.079546343800, 0.087500978180, 0.096251075998, 0.105876183598, 0.116463801958, 0.128110182153, 0.140921200369, 0.155013320406, 0.170514652446, 0.173924945495, 0.177403444405, 0.180951513293, 0.184570543559, 0.188261954430, 0.192027193519, 0.195867737389, 0.199785092137, 0.199785092137, 0.199785092137, 0.199785092137, 0.199785092137, 0.199785092137, 0.199785092137, 0.199785092137],
            63: [0.066739880000, 0.073413868000, 0.080755254800, 0.088830780280, 0.097713858308, 0.107485244139, 0.118233768553, 0.130057145408, 0.143062859949, 0.157369145944, 0.173106060538, 0.176568181749, 0.180099545384, 0.183701536291, 0.187375567017, 0.191123078358, 0.194945539925, 0.198844450723, 0.198844450723, 0.198844450723, 0.198844450723, 0.198844450723, 0.198844450723, 0.198844450723, 0.198844450723, 0.198844450723],
            64: [0.067838890000, 0.074622779000, 0.082085056900, 0.090293562590, 0.099322918849, 0.109255210734, 0.120180731807, 0.132198804988, 0.145418685487, 0.159960554036, 0.175956609439, 0.179475741628, 0.183065256460, 0.186726561590, 0.190461092821, 0.194270314678, 0.198155720971, 0.198155720971, 0.198155720971, 0.198155720971, 0.198155720971, 0.198155720971, 0.198155720971, 0.198155720971, 0.198155720971, 0.198155720971],
            65: [0.068837990000, 0.075721789000, 0.083293967900, 0.091623364690, 0.100785701159, 0.110864271275, 0.121950698402, 0.134145768243, 0.147560345067, 0.162316379574, 0.178548017531, 0.182118977882, 0.185761357439, 0.189476584588, 0.193266116280, 0.197131438605, 0.197131438605, 0.197131438605, 0.197131438605, 0.197131438605, 0.197131438605, 0.197131438605, 0.197131438605, 0.197131438605, 0.197131438605, 0.197131438605],
            66: [0.069937000000, 0.076930700000, 0.084623770000, 0.093086147000, 0.102394761700, 0.112634237870, 0.123897661657, 0.136287427823, 0.149916170605, 0.164907787665, 0.181398566432, 0.185026537761, 0.188727068516, 0.192501609886, 0.196351642084, 0.196351642084, 0.196351642084, 0.196351642084, 0.196351642084, 0.196351642084, 0.196351642084, 0.196351642084, 0.196351642084, 0.196351642084, 0.196351642084, 0.196351642084],
            67: [0.070936100000, 0.078029710000, 0.085832681000, 0.094415949100, 0.103857544010, 0.114243298411, 0.125667628252, 0.138234391077, 0.152057830185, 0.167263613204, 0.183989974524, 0.187669774014, 0.191423169495, 0.195251632885, 0.195251632885, 0.195251632885, 0.195251632885, 0.195251632885, 0.195251632885, 0.195251632885, 0.195251632885, 0.195251632885, 0.195251632885, 0.195251632885, 0.195251632885, 0.195251632885],
            68: [0.071935200000, 0.079128720000, 0.087041592000, 0.095745751200, 0.105320326320, 0.115852358952, 0.127437594847, 0.140181354332, 0.154199489765, 0.169619438742, 0.186581382616, 0.190313010268, 0.194119270473, 0.194119270473, 0.194119270473, 0.194119270473, 0.194119270473, 0.194119270473, 0.194119270473, 0.194119270473, 0.194119270473, 0.194119270473, 0.194119270473, 0.194119270473, 0.194119270473, 0.194119270473],
            69: [0.073034210000, 0.080337631000, 0.088371394100, 0.097208533510, 0.106929386861, 0.117622325547, 0.129384558102, 0.142323013912, 0.156555315303, 0.172210846834, 0.189431931517, 0.193220570147, 0.193220570147, 0.193220570147, 0.193220570147, 0.193220570147, 0.193220570147, 0.193220570147, 0.193220570147, 0.193220570147, 0.193220570147, 0.193220570147, 0.193220570147, 0.193220570147, 0.193220570147, 0.193220570147],
            70: [0.074033310000, 0.081436641000, 0.089580305100, 0.098538335610, 0.108392169171, 0.119231386088, 0.131154524697, 0.144269977167, 0.158696974883, 0.174566672372, 0.192023339609, 0.192023339609, 0.192023339609, 0.192023339609, 0.192023339609, 0.192023339609, 0.192023339609, 0.192023339609, 0.192023339609, 0.192023339609, 0.192023339609, 0.192023339609, 0.192023339609, 0.192023339609, 0.192023339609, 0.192023339609],
            71: [0.075132320000, 0.082645552000, 0.090910107200, 0.100001117920, 0.110001229712, 0.121001352683, 0.133101487952, 0.146411636747, 0.161052800421, 0.177158080463, 0.194873888510, 0.194873888510, 0.194873888510, 0.194873888510, 0.194873888510, 0.194873888510, 0.194873888510, 0.194873888510, 0.194873888510, 0.194873888510, 0.194873888510, 0.194873888510, 0.194873888510, 0.194873888510, 0.194873888510, 0.194873888510],
            72: [0.076131420000, 0.083744562000, 0.092119018200, 0.101330920020, 0.111464012022, 0.122610413224, 0.134871454547, 0.148358600001, 0.163194460001, 0.179513906002, 0.197465296602, 0.197465296602, 0.197465296602, 0.197465296602, 0.197465296602, 0.197465296602, 0.197465296602, 0.197465296602, 0.197465296602, 0.197465296602, 0.197465296602, 0.197465296602, 0.197465296602, 0.197465296602, 0.197465296602, 0.197465296602],
            73: [0.077230430000, 0.084953473000, 0.093448820300, 0.102793702330, 0.113073072563, 0.124380379819, 0.136818417801, 0.150500259581, 0.165550285539, 0.182105314093, 0.200315845503, 0.200315845503, 0.200315845503, 0.200315845503, 0.200315845503, 0.200315845503, 0.200315845503, 0.200315845503, 0.200315845503, 0.200315845503, 0.200315845503, 0.200315845503, 0.200315845503, 0.200315845503, 0.200315845503, 0.200315845503],
            74: [0.078229530000, 0.086052483000, 0.094657731300, 0.104123504430, 0.114535854873, 0.125989440360, 0.138588384396, 0.152447222836, 0.167691945120, 0.184461139632, 0.202907253595, 0.202907253595, 0.202907253595, 0.202907253595, 0.202907253595, 0.202907253595, 0.202907253595, 0.202907253595, 0.202907253595, 0.202907253595, 0.202907253595, 0.202907253595, 0.202907253595, 0.202907253595, 0.202907253595, 0.202907253595],
            75: [0.079328540000, 0.087261394000, 0.095987533400, 0.105586286740, 0.116144915414, 0.127759406955, 0.140535347651, 0.154588882416, 0.170047770658, 0.187052547723, 0.205757802496, 0.205757802496, 0.205757802496, 0.205757802496, 0.205757802496, 0.205757802496, 0.205757802496, 0.205757802496, 0.205757802496, 0.205757802496, 0.205757802496, 0.205757802496, 0.205757802496, 0.205757802496, 0.205757802496, 0.205757802496],
            76: [0.080327640000, 0.088360404000, 0.097196444400, 0.106916088840, 0.117607697724, 0.129368467496, 0.142305314246, 0.156535845671, 0.172189430238, 0.189408373261, 0.208349210588, 0.208349210588, 0.208349210588, 0.208349210588, 0.208349210588, 0.208349210588, 0.208349210588, 0.208349210588, 0.208349210588, 0.208349210588, 0.208349210588, 0.208349210588, 0.208349210588, 0.208349210588, 0.208349210588, 0.208349210588],
            77: [0.081326740000, 0.089459414000, 0.098405355400, 0.108245890940, 0.119070480034, 0.130977528037, 0.144075280841, 0.158482808925, 0.174331089818, 0.191764198800, 0.210940618680, 0.210940618680, 0.210940618680, 0.210940618680, 0.210940618680, 0.210940618680, 0.210940618680, 0.210940618680, 0.210940618680, 0.210940618680, 0.210940618680, 0.210940618680, 0.210940618680, 0.210940618680, 0.210940618680, 0.210940618680],
            78: [0.082425750000, 0.090668325000, 0.099735157500, 0.109708673250, 0.120679540575, 0.132747494633, 0.146022244096, 0.160624468505, 0.176686915356, 0.194355606891, 0.213791167581, 0.213791167581, 0.213791167581, 0.213791167581, 0.213791167581, 0.213791167581, 0.213791167581, 0.213791167581, 0.213791167581, 0.213791167581, 0.213791167581, 0.213791167581, 0.213791167581, 0.213791167581, 0.213791167581, 0.213791167581],
            79: [0.083424850000, 0.091767335000, 0.100944068500, 0.111038475350, 0.122142322885, 0.134356555174, 0.147792210691, 0.162571431760, 0.178828574936, 0.196711432430, 0.216382575672, 0.216382575672, 0.216382575672, 0.216382575672, 0.216382575672, 0.216382575672, 0.216382575672, 0.216382575672, 0.216382575672, 0.216382575672, 0.216382575672, 0.216382575672, 0.216382575672, 0.216382575672, 0.216382575672, 0.216382575672],
        },
        [CoveredLives.JOINT]: {
            50: [0.052381309500, 0.057619440450, 0.063381384495, 0.069719522945, 0.076691475239, 0.084360622763, 0.092796685039, 0.102076353543, 0.112283988897, 0.123512387787, 0.135863626566, 0.138580899097, 0.141352517079, 0.144179567421, 0.147063158769, 0.150004421944, 0.153004510383, 0.156064600591, 0.159185892603, 0.162369610455, 0.165617002664, 0.168929342717, 0.172307929572, 0.175754088163, 0.179269169926, 0.182854553325],
            51: [0.052381309500, 0.057619440450, 0.063381384495, 0.069719522945, 0.076691475239, 0.084360622763, 0.092796685039, 0.102076353543, 0.112283988897, 0.123512387787, 0.135863626566, 0.138580899097, 0.141352517079, 0.144179567421, 0.147063158769, 0.150004421944, 0.153004510383, 0.156064600591, 0.159185892603, 0.162369610455, 0.165617002664, 0.168929342717, 0.172307929572, 0.175754088163, 0.179269169926, 0.182854553325],
            52: [0.052381309500, 0.057619440450, 0.063381384495, 0.069719522945, 0.076691475239, 0.084360622763, 0.092796685039, 0.102076353543, 0.112283988897, 0.123512387787, 0.135863626566, 0.138580899097, 0.141352517079, 0.144179567421, 0.147063158769, 0.150004421944, 0.153004510383, 0.156064600591, 0.159185892603, 0.162369610455, 0.165617002664, 0.168929342717, 0.172307929572, 0.175754088163, 0.179269169926, 0.182854553325],
            53: [0.052381309500, 0.057619440450, 0.063381384495, 0.069719522945, 0.076691475239, 0.084360622763, 0.092796685039, 0.102076353543, 0.112283988897, 0.123512387787, 0.135863626566, 0.138580899097, 0.141352517079, 0.144179567421, 0.147063158769, 0.150004421944, 0.153004510383, 0.156064600591, 0.159185892603, 0.162369610455, 0.165617002664, 0.168929342717, 0.172307929572, 0.175754088163, 0.179269169926, 0.182854553325],
            54: [0.052381309500, 0.057619440450, 0.063381384495, 0.069719522945, 0.076691475239, 0.084360622763, 0.092796685039, 0.102076353543, 0.112283988897, 0.123512387787, 0.135863626566, 0.138580899097, 0.141352517079, 0.144179567421, 0.147063158769, 0.150004421944, 0.153004510383, 0.156064600591, 0.159185892603, 0.162369610455, 0.165617002664, 0.168929342717, 0.172307929572, 0.175754088163, 0.179269169926, 0.182854553325],
            55: [0.052894851750, 0.058184336925, 0.064002770618, 0.070403047679, 0.077443352447, 0.085187687692, 0.093706456461, 0.103077102107, 0.113384812318, 0.124723293550, 0.137195622905, 0.139939535363, 0.142738326070, 0.145593092591, 0.148504954443, 0.151475053532, 0.154504554603, 0.157594645695, 0.160746538609, 0.163961469381, 0.167240698768, 0.170585512744, 0.173997222999, 0.177477167459, 0.181026710808, 0.184647245024],
            56: [0.053799037250, 0.059178940975, 0.065096835073, 0.071606518580, 0.078767170438, 0.086643887481, 0.095308276230, 0.104839103853, 0.115323014238, 0.126855315662, 0.139540847228, 0.142331664172, 0.145178297456, 0.148081863405, 0.151043500673, 0.154064370687, 0.157145658100, 0.160288571262, 0.163494342687, 0.166764229541, 0.170099514132, 0.173501504415, 0.176971534503, 0.180510965193, 0.184121184497, 0.184121184497],
            57: [0.054793641300, 0.060273005430, 0.066300305973, 0.072930336570, 0.080223370227, 0.088245707250, 0.097070277975, 0.106777305773, 0.117455036350, 0.129200539985, 0.142120593983, 0.144963005863, 0.147862265980, 0.150819511300, 0.153835901526, 0.156912619556, 0.160050871947, 0.163251889386, 0.166516927174, 0.169847265718, 0.173244211032, 0.176709095253, 0.180243277158, 0.183848142701, 0.183848142701, 0.183848142701],
            58: [0.055697826800, 0.061267609480, 0.067394370428, 0.074133807471, 0.081547188218, 0.089701907040, 0.098672097744, 0.108539307518, 0.119393238270, 0.131332562097, 0.144465818306, 0.147355134673, 0.150302237366, 0.153308282113, 0.156374447756, 0.159501936711, 0.162691975445, 0.165945814954, 0.169264731253, 0.172650025878, 0.176103026396, 0.179625086923, 0.183217588662, 0.183217588662, 0.183217588662, 0.183217588662],
            59: [0.056602012300, 0.062262213530, 0.068488434883, 0.075337278371, 0.082871006208, 0.091158106829, 0.100273917512, 0.110301309263, 0.121331440190, 0.133464584209, 0.146811042630, 0.149747263482, 0.152742208752, 0.155797052927, 0.158912993985, 0.162091253865, 0.165333078942, 0.168639740521, 0.172012535332, 0.175452786038, 0.178961841759, 0.182541078594, 0.182541078594, 0.182541078594, 0.182541078594, 0.182541078594],
            60: [0.057596616350, 0.063356277985, 0.069691905784, 0.076661096362, 0.084327205998, 0.092759926598, 0.102035919258, 0.112239511183, 0.123463462302, 0.135809808532, 0.149390789385, 0.152378605173, 0.155426177276, 0.158534700822, 0.161705394838, 0.164939502735, 0.168238292790, 0.171603058645, 0.175035119818, 0.178535822215, 0.182106538659, 0.182106538659, 0.182106538659, 0.182106538659, 0.182106538659, 0.182106538659],
            61: [0.058500801850, 0.064350882035, 0.070785970239, 0.077864567262, 0.085651023989, 0.094216126387, 0.103637739026, 0.114001512929, 0.125401664222, 0.137941830644, 0.151736013708, 0.154770733982, 0.157866148662, 0.161023471635, 0.164243941068, 0.167528819889, 0.170879396287, 0.174296984213, 0.177782923897, 0.181338582375, 0.181338582375, 0.181338582375, 0.181338582375, 0.181338582375, 0.181338582375, 0.181338582375],
            62: [0.059495405900, 0.065444946490, 0.071989441139, 0.079188385253, 0.087107223778, 0.095817946156, 0.105399740772, 0.115939714849, 0.127533686334, 0.140287054967, 0.154315760464, 0.157402075673, 0.160550117186, 0.163761119530, 0.167036341921, 0.170377068759, 0.173784610134, 0.177260302337, 0.180805508384, 0.180805508384, 0.180805508384, 0.180805508384, 0.180805508384, 0.180805508384, 0.180805508384, 0.180805508384],
            63: [0.060399591400, 0.066439550540, 0.073083505594, 0.080391856153, 0.088431041769, 0.097274145946, 0.107001560540, 0.117701716594, 0.129471888254, 0.142419077079, 0.156660984787, 0.159794204483, 0.162990088572, 0.166249890344, 0.169574888151, 0.172966385914, 0.176425713632, 0.179954227905, 0.179954227905, 0.179954227905, 0.179954227905, 0.179954227905, 0.179954227905, 0.179954227905, 0.179954227905, 0.179954227905],
            64: [0.061394195450, 0.067533614995, 0.074286976495, 0.081715674144, 0.089887241558, 0.098875965714, 0.108763562286, 0.119639918514, 0.131603910366, 0.144764301402, 0.159240731542, 0.162425546173, 0.165674057097, 0.168987538239, 0.172367289003, 0.175814634783, 0.179330927479, 0.179330927479, 0.179330927479, 0.179330927479, 0.179330927479, 0.179330927479, 0.179330927479, 0.179330927479, 0.179330927479, 0.179330927479],
            65: [0.062298380950, 0.068528219045, 0.075381040950, 0.082919145044, 0.091211059549, 0.100332165504, 0.110365382054, 0.121401920260, 0.133542112286, 0.146896323514, 0.161585955866, 0.164817674983, 0.168114028482, 0.171476309052, 0.174905835233, 0.178403951938, 0.178403951938, 0.178403951938, 0.178403951938, 0.178403951938, 0.178403951938, 0.178403951938, 0.178403951938, 0.178403951938, 0.178403951938, 0.178403951938],
            66: [0.063292985000, 0.069622283500, 0.076584511850, 0.084242963035, 0.092667259339, 0.101933985272, 0.112127383800, 0.123340122180, 0.135674134397, 0.149241547837, 0.164165702621, 0.167449016673, 0.170797997007, 0.174213956947, 0.177698236086, 0.177698236086, 0.177698236086, 0.177698236086, 0.177698236086, 0.177698236086, 0.177698236086, 0.177698236086, 0.177698236086, 0.177698236086, 0.177698236086, 0.177698236086],
            67: [0.064197170500, 0.070616887550, 0.077678576305, 0.085446433936, 0.093991077329, 0.103390185062, 0.113729203568, 0.125102123925, 0.137612336317, 0.151373569949, 0.166510926944, 0.169841145483, 0.173237968393, 0.176702727761, 0.176702727761, 0.176702727761, 0.176702727761, 0.176702727761, 0.176702727761, 0.176702727761, 0.176702727761, 0.176702727761, 0.176702727761, 0.176702727761, 0.176702727761, 0.176702727761],
            68: [0.065101356000, 0.071611491600, 0.078772640760, 0.086649904836, 0.095314895320, 0.104846384852, 0.115331023337, 0.126864125670, 0.139550538237, 0.153505592061, 0.168856151267, 0.172233274293, 0.175677939778, 0.175677939778, 0.175677939778, 0.175677939778, 0.175677939778, 0.175677939778, 0.175677939778, 0.175677939778, 0.175677939778, 0.175677939778, 0.175677939778, 0.175677939778, 0.175677939778, 0.175677939778],
            69: [0.066095960050, 0.072705556055, 0.079976111661, 0.087973722827, 0.096771095109, 0.106448204620, 0.117093025082, 0.128802327590, 0.141682560349, 0.155850816384, 0.171435898023, 0.174864615983, 0.174864615983, 0.174864615983, 0.174864615983, 0.174864615983, 0.174864615983, 0.174864615983, 0.174864615983, 0.174864615983, 0.174864615983, 0.174864615983, 0.174864615983, 0.174864615983, 0.174864615983, 0.174864615983],
            70: [0.067000145550, 0.073700160105, 0.081070176116, 0.089177193727, 0.098094913100, 0.107904404410, 0.118694844851, 0.130564329336, 0.143620762269, 0.157982838496, 0.173781122346, 0.173781122346, 0.173781122346, 0.173781122346, 0.173781122346, 0.173781122346, 0.173781122346, 0.173781122346, 0.173781122346, 0.173781122346, 0.173781122346, 0.173781122346, 0.173781122346, 0.173781122346, 0.173781122346, 0.173781122346],
            71: [0.067994749600, 0.074794224560, 0.082273647016, 0.090501011718, 0.099551112889, 0.109506224178, 0.120456846596, 0.132502531256, 0.145752784381, 0.160328062819, 0.176360869101, 0.176360869101, 0.176360869101, 0.176360869101, 0.176360869101, 0.176360869101, 0.176360869101, 0.176360869101, 0.176360869101, 0.176360869101, 0.176360869101, 0.176360869101, 0.176360869101, 0.176360869101, 0.176360869101, 0.176360869101],
            72: [0.068898935100, 0.075788828610, 0.083367711471, 0.091704482618, 0.100874930880, 0.110962423968, 0.122058666365, 0.134264533001, 0.147690986301, 0.162460084931, 0.178706093425, 0.178706093425, 0.178706093425, 0.178706093425, 0.178706093425, 0.178706093425, 0.178706093425, 0.178706093425, 0.178706093425, 0.178706093425, 0.178706093425, 0.178706093425, 0.178706093425, 0.178706093425, 0.178706093425, 0.178706093425],
            73: [0.069893539150, 0.076882893065, 0.084571182372, 0.093028300609, 0.102331130670, 0.112564243736, 0.123820668110, 0.136202734921, 0.149823008413, 0.164805309255, 0.181285840180, 0.181285840180, 0.181285840180, 0.181285840180, 0.181285840180, 0.181285840180, 0.181285840180, 0.181285840180, 0.181285840180, 0.181285840180, 0.181285840180, 0.181285840180, 0.181285840180, 0.181285840180, 0.181285840180, 0.181285840180],
            74: [0.070797724650, 0.077877497115, 0.085665246827, 0.094231771509, 0.103654948660, 0.114020443526, 0.125422487879, 0.137964736667, 0.151761210333, 0.166937331367, 0.183631064503, 0.183631064503, 0.183631064503, 0.183631064503, 0.183631064503, 0.183631064503, 0.183631064503, 0.183631064503, 0.183631064503, 0.183631064503, 0.183631064503, 0.183631064503, 0.183631064503, 0.183631064503, 0.183631064503, 0.183631064503],
            75: [0.071792328700, 0.078971561570, 0.086868717727, 0.095555589500, 0.105111148450, 0.115622263295, 0.127184489624, 0.139902938587, 0.153893232445, 0.169282555690, 0.186210811259, 0.186210811259, 0.186210811259, 0.186210811259, 0.186210811259, 0.186210811259, 0.186210811259, 0.186210811259, 0.186210811259, 0.186210811259, 0.186210811259, 0.186210811259, 0.186210811259, 0.186210811259, 0.186210811259, 0.186210811259],
            76: [0.072696514200, 0.079966165620, 0.087962782182, 0.096759060400, 0.106434966440, 0.117078463084, 0.128786309393, 0.141664940332, 0.155831434365, 0.171414577802, 0.188556035582, 0.188556035582, 0.188556035582, 0.188556035582, 0.188556035582, 0.188556035582, 0.188556035582, 0.188556035582, 0.188556035582, 0.188556035582, 0.188556035582, 0.188556035582, 0.188556035582, 0.188556035582, 0.188556035582, 0.188556035582],
            77: [0.073600699700, 0.080960769670, 0.089056846637, 0.097962531301, 0.107758784431, 0.118534662874, 0.130388129161, 0.143426942077, 0.157769636285, 0.173546599914, 0.190901259905, 0.190901259905, 0.190901259905, 0.190901259905, 0.190901259905, 0.190901259905, 0.190901259905, 0.190901259905, 0.190901259905, 0.190901259905, 0.190901259905, 0.190901259905, 0.190901259905, 0.190901259905, 0.190901259905, 0.190901259905],
            78: [0.074595303750, 0.082054834125, 0.090260317538, 0.099286349291, 0.109214984220, 0.120136482642, 0.132150130907, 0.145365143997, 0.159901658397, 0.175891824237, 0.193481006660, 0.193481006660, 0.193481006660, 0.193481006660, 0.193481006660, 0.193481006660, 0.193481006660, 0.193481006660, 0.193481006660, 0.193481006660, 0.193481006660, 0.193481006660, 0.193481006660, 0.193481006660, 0.193481006660, 0.193481006660],
            79: [0.075499489250, 0.083049438175, 0.091354381993, 0.100489820192, 0.110538802211, 0.121592682432, 0.133751950675, 0.147127145743, 0.161839860317, 0.178023846349, 0.195826230984, 0.195826230984, 0.195826230984, 0.195826230984, 0.195826230984, 0.195826230984, 0.195826230984, 0.195826230984, 0.195826230984, 0.195826230984, 0.195826230984, 0.195826230984, 0.195826230984, 0.195826230984, 0.195826230984, 0.195826230984]
        }
    }

    return rates[coveredLives][age][yearsUntilPayout];
}

onBeforeMount(() => { if (!store.investmentAmount) store.setField('investmentAmount', 100000) })

onMounted(() => {
    calcTotal();
})


const calcTotal = () => {
    //CALCULATE YEARS UNTIL PAYOUT
    let yearsUntilPayout = attainedAge.value - currentAge.value;
    if (yearsUntilPayout > 25) yearsUntilPayout = 25;

    const activeRate = calculateRate(currentAge.value, yearsUntilPayout, coveredLives.value);

    //CALCULATE INCOME
    if (solveFor.value == SolveFor.INCOME) {
        income.value = (store.investmentAmount * activeRate) / 12;
        return
    }
    //CALCULATE PREMIUM
    if (solveFor.value == SolveFor.PREMIUM) {
        store.investmentAmount = ((income.value * 12) / activeRate);
        return
    }
}


watch(() => [currentAge.value, attainedAge.value, coveredLives.value, solveFor.value, store.investmentAmount, income.value], () => {
    calcTotal();
})

</script>