<template>
    <QuestionnaireSection title="What are you looking for?" class="max-w-lg" @click="handleContinue"
        :disableNext="!store.validateStep(QuestionnaireStep.FOCUS)">
        <div class="grid gap-4 mb-6">
            <button v-for="option of options" @click="selectOption(option.value)"
                class="text-left rounded-lg border-2 p-6" :class="[store.focus == option.value ? 'border-primary-600 bg-primary-50 focus:outline-none' :
                    'border-gray-300 bg-white']">
                <div class="-mt-2 mb-1">
                    <StrategyTypeTag :type="option.value" :color="store.focus == option.value" />
                </div>
                <h3 class="text-xl sm:text-2xl font-bold font-serif tracking-tight mb-2"
                    :class="store.focus == option.value ? 'text-primary-600' : 'text-gray-800'">{{ option.heading }}
                </h3>
                <p class="text-normal leading-tight"
                    :class="store.focus == option.value ? 'text-primary-900' : 'text-gray-800'">{{
                        option.body
                    }}</p>
            </button>
        </div>
    </QuestionnaireSection>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import QuestionnaireSection from './QuestionnaireSection.vue';
import { useQuestionnaireStore } from '@/stores/questionnaire';
import { useRouter } from 'vue-router';
import StrategyTypeTag from '@/components/StrategyTypeTag.vue';
import { FocusOption, QuestionnaireStep } from '@/stores/questionnaire/types';

const router = useRouter()
const store = useQuestionnaireStore();

const options = ref([
    {
        value: FocusOption.GROWTH,
        heading: "Protected growth",
        body: "Generate competitive, tax-deferred returns with the option to contribute more money over time."
    },
    {
        value: FocusOption.INCOME,
        heading: "An income stream I can't outlive",
        body: "Draw a guaranteed lifetime income at a later date from a single, lump-sum investment."
    },
])

onMounted(() => {
    store.setStep(QuestionnaireStep.FOCUS)
})

const selectOption = (value: FocusOption): void => {
    store.setField('focus', value)
}

const handleContinue = (): void => {
    router.push(store.nextStep())
}
</script>