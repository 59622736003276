<template>
    <div class="sm:flex justify-center sm:gap-6 items-start mx-auto w-auto">
        <QuestionnaireSection class="max-w-xl !mx-0 sm:mb-0 mb-4" noButton>
            <div class="flex items-center gap-2 mb-4">
                <BestFitBadge pill />
                <StrategyTypeTag :type="ProductType.SPIA" color />
            </div>
            <h2 class="font-serif font-bold text-2xl sm:text-3xl mb-2">
                A SPIA may be the right option for you.
            </h2>
            <p class="mb-4">SPIAs are quoted to our clients during a live meeting with a licensed agent as rates and
                terms can fluctuate daily based on various financial factors, such as interest rates, economic
                conditions, and insurer strategies.</p>
            <p class="mb-8">Our agents have access to a wide variety of options and will provide the most accurate and
                up-to-date information tailored to your specific financial situation and objectives.
            </p>
            <div class="md:w-60 mx-auto">
                <div class="mb-2 flex justify-center">
                    <PrimaryButton block @click="showCalEmbed = true">
                        <div class="flex items-center justify-center gap-1">
                            <CalendarIcon class="-mt-0.5 w-4 h-4" />
                            Book a SPIA meeting now
                        </div>
                    </PrimaryButton>
                </div>
            </div>
        </QuestionnaireSection>
        <CalendarBookingModal :open="showCalEmbed" @close="showCalEmbed = false" :calendarId="CalendarId.Intro"
            @bookingComplete="store.handleBookingComplete" />
    </div>
</template>

<script setup lang="ts">
import { CalendarIcon } from '@heroicons/vue/16/solid';
import QuestionnaireSection from './QuestionnaireSection.vue';
import { PrimaryButton } from '@reviseannuity/randolph-ui';
import { ref } from 'vue';
import StrategyTypeTag from '@/components/StrategyTypeTag.vue';
import BestFitBadge from '@/components/BestFitBadge.vue';
import { CalendarId } from '@/data/calendarIds';
import { ProductType } from '@/types';
import CalendarBookingModal from '../CalendarBookingModal.vue';
import { useQuestionnaireStore } from '@/stores/questionnaire';

const store = useQuestionnaireStore()

const showCalEmbed = ref<boolean>(false)
</script>