import { event } from "vue-gtag"
import { callZapierWebhook, ZapierWebhook } from "@/clients/zapier";
import { isProduction } from "@/utils";

const GOOGLE_ADS_CONVERSION_ID = 'AW-779555572'
const GOOGLE_ANALYTICS_MEASUREMENT_ID = 'G-LB5BT7V27L'

/**
 * 
 * Events fired when the questionnaire is started
 * 
 */
export const questionnaireStartedEvents = () => {
    if (!isProduction) return

    // Google Analytics and Ads
    event('questionnaire_started', {
        send_to: GOOGLE_ANALYTICS_MEASUREMENT_ID
    })

    // Facebook Pixel
    if (window.fbq) {
        window.window.fbq('trackCustom', 'questionnaire_started')
    }

    // TikTok
    if (window.ttq) {
        window.window.ttq.track('QuestionnaireStarted')
    }

    // LinkedIn
    if (window.lintrk) {
        window.lintrk('track', { conversion_id: 17411836 });
    }
}

/**
 * 
 * Events fired when the questionnaire is completed
 * 
 */
export const questionnaireCompletedEvents = (investmentAmount: number) => {
    if (!isProduction) return

    // Google Ads
    event('conversion', {
        send_to: `${GOOGLE_ADS_CONVERSION_ID}/-XZgCM78gOgZEPSl3PMC`,
        value: investmentAmount * 0.085,
        currency: 'USD'
    })
}

/**
 * 
 * Events fired when a user signs up
 * 
 */
export const signUpEvents = (
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    strategyName: string,
    investmentAmount: number,
    birthdate: string,
    age: number
) => {
    if (!isProduction) return

    console.log('is production, proceeding')

    // Google Analytics
    event('sign_up', {
        send_to: GOOGLE_ANALYTICS_MEASUREMENT_ID,
        method: 'email',
        email: email,
    })

    // Google Ads
    event('conversion', {
        send_to: `${GOOGLE_ADS_CONVERSION_ID}/KKDlCODg_ucZEPSl3PMC`,
        value: Number(investmentAmount) * 0.085,
        currency: 'USD'
    })

    // Facebook Pixel
    if (window.fbq) {
        window.fbq('track', 'CompleteRegistration')
    }


    // Zapier
    callZapierWebhook(ZapierWebhook.SIGN_UP, {
        email,
        firstName,
        lastName,
        phone,
        strategyName,
        investmentAmount,
        birthdate,
        age,
        isWojo: window?.reviseTracking?.getFirstTouchAttribution()?.attribution?.utmMedium === 'wojo_ads' || false
    })

    // TikTok
    if (window.ttq) {
        window.ttq.track('CompleteRegistration')
    }

    // LinkedIn
    if (window.lintrk) {
        window.lintrk('track', { conversion_id: 17411828 });
    }
}

/**
 * 
 * Events fired when an applicaiton is started
 * 
 */
export const applicationStartedEvents = (
    investmentAmount: number,
    state: string,
    name: string,
    email: string,
    phone: string,
    birthdate: string
) => {
    if (!isProduction) return

    // Google Analytics and Ads
    event('application_started', {
        send_to: GOOGLE_ANALYTICS_MEASUREMENT_ID
    })

    // Facebook Pixel
    if (window.fbq) {
        window.fbq('trackCustom', 'application_started')
    }

    // Zapier
    callZapierWebhook(ZapierWebhook.APPLICATION_STARTED, {
        investmentAmount,
        state,
        name,
        email,
        phone,
        birthdate
    })

    // TikTok
    if (window.ttq) {
        window.ttq.track('ApplicationStarted')
    }

    // LinkedIn
    if (window.lintrk) {
        window.lintrk('track', { conversion_id: 17411820 });
    }
}

/**
 * 
 * Events fired when an application is submitted
 * 
 * @param {number} investmentAmount
 * @param {string} applicationId
 * @param {object} state
 */
export const applicationSubmittedEvents = (
    investmentAmount: number,
    applicationId: string,
    state: any
) => {
    if (!isProduction) return

    // Google Analytics and Ads
    event('conversion', {
        send_to: [
            GOOGLE_ANALYTICS_MEASUREMENT_ID,
            `${GOOGLE_ADS_CONVERSION_ID}/8NhWCK2cpJIZEPSl3PMC`
        ],
        value: investmentAmount * 0.085,
        currency: 'USD',
        transaction_id: applicationId
    })

    // Facebook Pixel
    if (window.fbq) {
        window.fbq('track', 'Purchase', {
            value: investmentAmount * 0.085,
            currency: 'USD',
        })
    }

    // Zapier
    callZapierWebhook(ZapierWebhook.APPLICATION_SUBMITTED, state)

    // TikTok
    if (window.ttq) {
        window.ttq.track('CompletePayment', {
            value: investmentAmount * 0.085,
            currency: 'USD',
        })
    }

    // LinkedIn
    if (window.lintrk) {
        window.lintrk('track', {
            conversion_id: 17411812,
            value: investmentAmount * 0.085,
        })
    };

    if (window.trackdesk) {
        window.trackdesk("revise", "click")
        window.trackdesk("revise", "conversion", {
            "conversionType": "sale"
        });
    }

    window.VWO = window.VWO || [];
    window.VWO.event = window.VWO.event || function () { window.VWO.push(['event'].concat([].slice.call(arguments))) };
    window.VWO.event('applicationSubmitted', {
        'investmentAmount': investmentAmount
    })
}
/**
 * 
 * Events fired when a user schedules a meeting
 * 
 */
export const meetingBookedEvents = (
    date: string,
    auth0_id: string,
    investmentAmount: number,
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    strategyName: string
) => {
    if (!isProduction) return

    event('meeting_scheduled', {
        date: date
    })

    if (window.fbq) {
        window.fbq('track', 'Purchase', {
            value: investmentAmount * 0.085,
            currency: 'USD',
        })
    }

    callZapierWebhook(ZapierWebhook.ROLLOVER_BOOKED, {
        email: email,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        strategy_name: strategyName,
        funding_amount: investmentAmount,
        rollover_meeting: date
    })
}

export const loadMetaPixel = () => {
    ((f: any, b, e, v, n, t, s) => {
        if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
        n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
    })(window, document, 'script',
        'https://connect.facebook.net/en_US/fbevents.js');
    window.fbq('init', '267489385853803');
    window.fbq('track', 'PageView');
}

export const loadTikTokPixel = () => {
    ((w: any, d, t) => {
        w.TiktokAnalyticsObject = t; var ttq = w[t] = w[t] || []; ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie", "holdConsent", "revokeConsent", "grantConsent"], ttq.setAndDefer = function (t, e) { t[e] = function () { t.push([e].concat(Array.prototype.slice.call(arguments, 0))) } }; for (var i = 0; i < ttq.methods.length; i++)ttq.setAndDefer(ttq, ttq.methods[i]); ttq.instance = function (t) {
            for (
                var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)ttq.setAndDefer(e, ttq.methods[n]); return e
        }, ttq.load = function (e, n) {
            var r = "https://analytics.tiktok.com/i18n/pixel/events.js", o = n && n.partner; ttq._i = ttq._i || {}, ttq._i[e] = [], ttq._i[e]._u = r, ttq._t = ttq._t || {}, ttq._t[e] = +new Date, ttq._o = ttq._o || {}, ttq._o[e] = n || {}; n = document.createElement("script")
                ; n.type = "text/javascript", n.async = !0, n.src = r + "?sdkid=" + e + "&lib=" + t; e = document.getElementsByTagName("script")[0]; e.parentNode.insertBefore(n, e)
        };

        ttq.load('CR8CMKRC77UFBBI22I2G');
        ttq.page();
    })(window, document, 'ttq');
}

export const loadLinkedInPixel = () => {
    const _linkedin_partner_id = "6488476";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);

    (function (l) {
        if (!l) {
            window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
            window.lintrk.q = []
        }
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript"; b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
    })(window.lintrk);
}

export const loadRevisePixel = () => {
    var v = "1726517836"
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript"; b.defer = true;
    b.src = `https://revise-tracking-pixel.s3.amazonaws.com/main-${v}.js`;
    s.parentNode.insertBefore(b, s);
}

export const loadTrackDeskPixel = () => {
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript"; b.async = true;
    b.src = `//cdn.trackdesk.com/tracking.js`;
    s.parentNode.insertBefore(b, s);
    (function (t, d, k) { (t[k] = t[k] || []).push(d); t[d] = t[d] || t[k].f || function () { (t[d].q = t[d].q || []).push(arguments) } })(window, "trackdesk", "TrackdeskObject");
}