<template>
    <div>
        <div class="bg-[#f1f5f9] fixed top-0 left-0 w-full z-50 h-20">
            <div class="max-w-sm mx-auto px-4">
                <div class="pt-4 mb-4 sm:mb-6 flex flex-col items-center gap-4">
                    <div class="flex justify-between w-full items-center">
                        <button @click="router.back()" class=" w-8 h-8 -m-2 rounded-full hover:bg-primary-300 flex items-center
                            justify-center"
                            v-if="Object.keys(store.steps).indexOf(store.activeStep) > 0 && store.steps[store.activeStep]">
                            <ArrowLeftIcon class="w-4 h-4 text-gray-500 text-primary-600" />
                        </button>
                        <img src="../assets/logo.svg" alt="Revise Annuity Group logo" class="mx-auto h-5" />
                    </div>
                    <ProgressBar :progress="store.progress" class="w-full" />
                </div>
            </div>
        </div>
        <div class="pt-20 px-4">
            <RouterView />
        </div>
        <div class="flex justify-center mt-4 text-gray-500 text-xs pb-8">
            &copy; {{ new Date().getFullYear() }} Revise. All rights reserved.&nbsp;|&nbsp;
            <a href="https://getrevise.com/privacy" target="_blank" class=" underline">Privacy
                Policy</a>
        </div>
    </div>
    <ExitPopup v-if="store.enableExitPopup" :open="store.showExitPopup" @close="exitPopupClose" />
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { ProgressBar } from '@reviseannuity/randolph-ui';
import { ArrowLeftIcon } from '@heroicons/vue/20/solid';
import { useQuestionnaireStore } from '@/stores/questionnaire';
import { useAuth0 } from '@auth0/auth0-vue';
import { RouterView, useRouter } from 'vue-router';
import { setBgColor } from '@/utils';
import { getStateFromZipCode } from '@/clients/zipcodestack';
import { questionnaireStartedEvents } from '@/eventTagging';
import ExitPopup from '@/components/ExitPopup.vue';

const store = useQuestionnaireStore();
const router = useRouter()
const { isAuthenticated } = useAuth0()


onMounted(async () => {
    setBgColor('#f1f5f9')
    exitPopupListener()
    questionnaireStartedEvents()

    if (router?.currentRoute?.value?.query?.zip) {
        try {
            let zip = router.currentRoute.value.query.zip
            zip = Array.isArray(zip) ? zip[0] : zip
            store.setField('loading', true)
            const state = await getStateFromZipCode(zip)
            store.setField('state', state)
            store.setField('zipCode', zip)
            store.setField('showBirthdateChecks', true)
            router.push('/get-started/birthdate')
            store.setField('loading', false)
        } catch (error) {
            store.setField('loading', false)
            router.push('/get-started/zip')
        }
    }

    if (store.state === null) {
        router.push('/get-started/zip')
        return store.setField('loading', false)
    }

    if (isAuthenticated.value) {
        router.push('/application')
        return store.setField('loading', false)
    }
})

const exitPopupListener = (): void => {
    document.body.addEventListener('mouseleave', (event) => {
        let mouseY = event.clientY;
        if (mouseY < 0) {
            exitPopupOpen()
        }
    });
}

const exitPopupOpen = (): void => {
    if (store.enableExitPopup && !store.dontShowPopupAgain) {
        store.setField('showExitPopup', true)
    }
}

const exitPopupClose = (): void => {
    store.setField('showExitPopup', false)
    store.setField('dontShowPopupAgain', true)
}
</script>