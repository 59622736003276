<template>
    <div class="bg-white" ref="wrapper">
        <RegistrationForm v-if="!store.isVerificationCodeSent" />
        <VerificationForm v-if="store.isVerificationCodeSent" />
    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import RegistrationForm from '@/components/auth/RegistrationForm.vue';
import VerificationForm from '@/components/auth/VerificationForm.vue';
import { useRegistrationStore } from '@/stores/registration';

const store = useRegistrationStore()
const wrapper = ref(null)
const ro = ref(null)

onMounted(() => {
    document.body.addEventListener('resize', sendHeightToParent)
    ro.value = new ResizeObserver(sendHeightToParent)
    ro.value.observe(wrapper.value)
})

onUnmounted(() => {
    ro.value.unobserve(wrapper.value)
})

function sendHeightToParent() {
    const height = wrapper.value.getBoundingClientRect().height
    parent.postMessage({ type: 'setHeight', height }, '*');
}
</script>