<template>
    <QuestionnaireSection title="What is the current value of your IRA or old 401(k)?" :loading="store.submitting"
        :enableEnterKey="false" class="max-w-lg" continueText="Continue" @click="nextStep"
        :disableNext="store.investmentAmount < 20000">
        <div class="pb-8">
            <MoneyField :value="store.investmentAmount" @input="v => store.setField('investmentAmount', v)" />
            <ErrorMessage v-if="store.investmentAmount && store.investmentAmount < 20000">$20,000 is the minimum amount
                to open an
                annuity contract.</ErrorMessage>
        </div>
    </QuestionnaireSection>


</template>

<script setup lang="ts">
import QuestionnaireSection from '@/components/questionnaireSections/QuestionnaireSection.vue';
import { MoneyField, ErrorMessage } from '@reviseannuity/randolph-ui'
import { onMounted } from 'vue';
import { useRegistrationStore } from '@/stores/registration';
import { RegistrationStep } from '@/stores/registration/types';
import { event } from 'vue-gtag'
import { updateUser } from '@/clients/user';

const store = useRegistrationStore()

onMounted(() => {
    event('page_view', {
        page_title: 'Master App Registration - 401k/IRA Amount',
        page_location: window.location.href,
        page_path: window.location.pathname
    })
})

const nextStep = async () => {
    store.setField('submitting', true)

    await updateUser(store.auth0Id, {
        funding_amount: Number(store.investmentAmount)
    })

    event('investment_amount_added', {
        amount: store.investmentAmount
    })

    store.setField('submitting', false)

    store.setActiveStep(RegistrationStep.ROLLOVER_BOOKING)
}
</script>