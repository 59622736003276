<template>
    <div class="rounded-lg bg-white shadow">
        <div class="border-b border-gray-200 bg-white px-4 py-3 sm:py-5 sm:px-6">
            <h3 class="text-base font-semibold leading-6 text-gray-900">
                <span v-if="step" class="text-gray-500 font-normal mr-2">Step {{ step }}</span>
                {{ title }}
            </h3>
            <HelperText v-if="helperText" class="pt-1">{{ helperText }}
            </HelperText>
            <slot name="helperText"></slot>
        </div>
        <div class="px-4 py-5 sm:p-6" :class="open ? 'block' : 'hidden'">
            <slot name="default"></slot>
        </div>
        <FooterNavbar :buttonDisabled="buttonDisabled" :buttonText="buttonText" @prevStep="handlePrevButtonClick"
            @nextStep="handleNextButtonClick" />
    </div>
</template>

<script setup lang="ts">
import { useApplicationStore } from '@/stores/application';
import FooterNavbar from './FooterNavbar.vue';
import { HelperText } from '@reviseannuity/randolph-ui';

const store = useApplicationStore()

const emit = defineEmits(['nextStep', 'prevStep'])
const {
    title,
    step,
    open = true,
    buttonDisabled = true,
    buttonText = "Next",
    saveProgress = true,
    helperText
} = defineProps<{
    title: string
    step?: string
    open?: boolean
    buttonDisabled?: boolean
    buttonText?: string
    saveProgress?: boolean
    helperText?: string
}>()

const handlePrevButtonClick = (): void => {
    if (saveProgress) store.saveApplicationProgress()
    emit('prevStep')
}

const handleNextButtonClick = (): void => {
    if (saveProgress) store.saveApplicationProgress()
    emit('nextStep')
}
</script>