import { QuestionnaireStep } from "./types";

export default {
    totalSteps(): number {
        return Object.keys(this.steps).length
    },
    progress(): number {
        const stepKeys = Object.keys(this.steps)
        const activeStepIndex = stepKeys.indexOf(this.activeStep)
        return (activeStepIndex + 1) / stepKeys.length * 80;
    },
    isLastStep(): boolean {
        return this.activeStep === QuestionnaireStep.STRATEGY_DETAIL;
    }
}