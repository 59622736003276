import auth0 from 'auth0-js'
import { RegistrationStore, RegistrationStep } from './types';

const state = (): RegistrationStore => ({
    // REGISTRATION FORM
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    consent: false,
    auth0Id: '',
    isRegistrationFormLoading: false,
    registrationFormErrorMessages: '',
    isVerificationCodeSent: false,
    // VERIFY CODE FORM
    verificationCode: '',
    isVerifyLoading: false,
    verificationFormErrorMessages: '',
    isVerificationCodeResending: false,
    isVerificationCodeResent: false,
    //
    strategyName: '',
    investmentAmount: 0,
    state: '',
    birthdate: null,
    age: null,
    zip: '',
    optIn: false,
    submitting: false,
    liquidFunds: 0,
    activeStep: RegistrationStep.FUNDING,
    fundingSource: '',
    focus: null,
    steps: [
        RegistrationStep.FUNDING,
        RegistrationStep.LIQUID_FUNDS,
        RegistrationStep.QUALIFIED_INVESTMENT_AMOUNT,
        RegistrationStep.ROLLOVER_BOOKING,
        RegistrationStep.INITIAL_PREMIUM_AMOUNT,
    ],
    webAuth: new auth0.WebAuth({
        clientID: import.meta.env.VITE_AUTH0_CLIENT_ID,
        domain: import.meta.env.VITE_AUTH0_DOMAIN,
        redirectUri: `${window.location.origin}/auth/callback`,
        responseType: 'token id_token'
    }),
    strategy: null,
})

export default state