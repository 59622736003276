<template>
    <SectionContainer title="Verification" :open="true" @nextStep="() => {
        event('verification_complete')
        nextStep()
    }" @prevStep="prevStep" :buttonDisabled="!store.steps[stepIndex].complete()" helperText="This information is required to apply for an annuity. Your
                information is secure and will only be shared with your selected insurance carrier.">
        <template v-slot:helperText>
            <div v-if="false" class="mt-2 flex">
                <div class="bg-green-600 flex text-white items-center px-1.5 py-1 rounded text-xs font-semibold gap-1">
                    <ShieldCheckIcon class="w-3.5" /> Secure & 256-bit Encypted
                </div>
            </div>
        </template>
        <div class="grid grid-cols-5 gap-4 sm:gap-8">
            <div class="col-span-full">
                <IdentificationInput
                    tooltip="A valid, government-issued form of identification is required by the carrier for verification."
                    name="form-of-identification" :value="store.personalInformation"
                    @update="v => store.setPersonalInformation(v.key, v.value)" />
            </div>
            <div class="col-span-full sm:col-span-2">
                <SSNField required
                    tooltip="The carrier requires a SSN for identity verification and to ensure that funds are distributed to the correct person as listed in the contract."
                    label="Confirm your SSN" name="ssn" @input="v => store.setPersonalInformation('ssn', v)"
                    :value="store.personalInformation.ssn" />
                <a class="text-primary-600 underline cursor-pointer text-xs" @click="infoModalOpen = true">Why do we
                    need this information?</a>
            </div>
            <Modal :open="infoModalOpen" title="Why do we need this information?" button1="Close"
                @click:button1="infoModalOpen = false" @close="infoModalOpen = false">
                <p class="text-gray-700 mb-4">Your Social Security Number (SSN) is required on your application for
                    identity
                    verification and tax
                    reporting purposes. This ensures that the information provided matches official records, helping to
                    prevent fraud and maintain the security of your personal and financial details.</p>
                <p class="text-gray-700 mb-4">Insurance companies
                    use your SSN to report any taxable distributions, annuity payments, or other transactions to the
                    IRS, ensuring compliance with federal tax regulations. Collecting your SSN is mandated by federal
                    and state regulations to maintain accurate records and follow legal requirements.</p>

                <p class="text-gray-700">This form is end-to-end encrypted and secure. Your information is never shared
                    with third parties.</p>
            </Modal>
        </div>
    </SectionContainer>
</template>

<script setup lang="ts">
import SectionContainer from '@/components/SectionContainer.vue';

import { useApplicationStore } from '@/stores/application';
import { useStep } from '@/composables/useStep';
import { IdentificationInput, SSNField } from '@reviseannuity/randolph-ui';
import { onMounted, ref } from 'vue';
import Modal from '@/components/Modal.vue';
import { event } from 'vue-gtag';
import { ShieldCheckIcon } from '@heroicons/vue/16/solid';

const store = useApplicationStore()
const infoModalOpen = ref(false)

const { stepIndex, nextStep, prevStep } = useStep(store)

onMounted(() => {
    if (!store.personalInformation.issuingState) {
        store.setPersonalInformation('issuingState', store.personalInformation.state)
    }
})
</script>