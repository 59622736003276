<template>
    <SDPWrapper extrathin v-if="productGrowth">
        <div class="mb-12">
            <h2 class="text-2xl font-bold font-serif mt-8 mb-2">Your
                <div class="inline-block relative -top-1 w-32">
                    <MoneyField :value="inputValue" @input="v => inputValue = v" @keydown.enter="setStartingValue"
                        @blur="setStartingValue" />
                </div>
                investment could be worth <span class="text-primary-600">{{
                    formatMoney(productGrowth[productGrowth.length -
                        1]) }}</span> in 10 years.
            </h2>
            <SDPDescription>This chart shows the hypothetical return of a
                {{ formatMoney(startingValue) }} investment
                in this annuity strategy compared to a baseline money market rate using historical data over a
                10-year period.</SDPDescription>
            <div class="mb-2 min-h-80">
                <ComparisonChart :productGrowth="productGrowth" :comparisonGrowth="comparisonGrowth"
                    :name="strategy.name" v-if="comparisonGrowth && productGrowth" />
            </div>
            <p class="mb-8 text-[0.7rem] text-gray-600 text-center w-[80%] mx-auto leading-3">The performance data
                featured represents past performance, which is no guarantee of future results. Investment returns
                will fluctuate. Current performance may be higher or lower than the performance data quoted.</p>
        </div>
    </SDPWrapper>
</template>

<script setup lang="ts">
import { defineProps, ref, watch } from 'vue';
import SDPWrapper from './SDPWrapper.vue';
import ComparisonChart from '@/components/ComparisonChart.vue';
import { useQuestionnaireStore } from '@/stores/questionnaire';
import { onMounted } from 'vue';
import { formatMoney, variableRateCompoundInterest } from '@/utils';
import { MoneyField } from '@reviseannuity/randolph-ui';
import SDPDescription from './SDPDescription.vue';
import { Strategy } from '@/types';

const store = useQuestionnaireStore();
const productGrowth = ref<number[]>([])
const comparisonGrowth = ref<number[]>([])
const startingValue = ref(0)
const inputValue = ref(0)

const setStartingValue = () => {
    if (inputValue.value < 20000) {
        inputValue.value = 20000
        startingValue.value = 20000
    } else {
        startingValue.value = inputValue.value
    }
}

const {
    strategy
} = defineProps<{
    strategy: Strategy
}>()

onMounted(() => {
    if (store.investmentAmount) {
        if (store.investmentAmount < 20000) {
            startingValue.value = 20000
        } else {
            startingValue.value = store.investmentAmount
        }
    } else {
        startingValue.value = 100000
    }
    inputValue.value = startingValue.value
})

watch(startingValue, () => {
    productGrowth.value = variableRateCompoundInterest(startingValue.value, JSON.parse(strategy.historicalRates))
    comparisonGrowth.value = variableRateCompoundInterest(startingValue.value, [0.04, 0.03, 0.01, 0.01, 0.02, 0.03, 0.02, 0.02, 0.02, 0.03])
})
</script>