<template>
    <span class="px-1 py-0.5 rounded border  uppercase text-[0.6em] font-sans inline-flex items-center gap-0.5 mb-0"
        :class="[color ? 'border-primary-400 text-primary-500' : 'border-gray-300 text-gray-500']">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="10px" height="10px" fill="currentColor"
            v-if="transformedType === 'income'">
            <path
                d="M41,9H7c-2.21,0-4,1.79-4,4v22c0,2.21,1.79,4,4,4h34c2.21,0,4-1.79,4-4V13C45,10.79,43.21,9,41,9z M6,14.5 C6,13.12,7.12,12,8.5,12H12c0,1.66-0.67,3.16-1.76,4.24C9.16,17.33,7.66,18,6,18V14.5z M8.5,36C7.12,36,6,34.88,6,33.5V30 c1.66,0,3.16,0.67,4.24,1.76C11.33,32.84,12,34.34,12,36H8.5z M12,26c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2 C14,25.1,13.1,26,12,26z M25.5,30.92v0.58c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5v-0.54c-1.7-0.2-2.83-1.27-3.34-2.29 c-0.37-0.74-0.07-1.64,0.67-2.01s1.64-0.07,2.01,0.67c0.06,0.11,0.38,0.67,1.2,0.67h1.71c0.69,0,1.25-0.56,1.25-1.25 s-0.56-1.25-1.25-1.25h-1.23c-2.34,0-4.25-1.91-4.25-4.25c0-1.93,1.4-3.59,3.23-4.08V16.5c0-0.83,0.67-1.5,1.5-1.5 s1.5,0.67,1.5,1.5v0.61c1.26,0.27,2.25,1.03,2.84,2.22c0.37,0.74,0.07,1.64-0.67,2.01s-1.64,0.07-2.01-0.67 c-0.17-0.35-0.44-0.67-1.2-0.67h-0.84c-0.72,0-1.35,0.58-1.35,1.25c0,0.69,0.56,1.25,1.25,1.25h1.23c2.34,0,4.25,1.91,4.25,4.25 C29,28.84,27.49,30.57,25.5,30.92z M34,24c0-1.1,0.9-2,2-2s2,0.9,2,2c0,1.1-0.9,2-2,2S34,25.1,34,24z M42,33.5 c0,1.38-1.12,2.5-2.5,2.5H36c0-1.66,0.67-3.16,1.76-4.24C38.84,30.67,40.34,30,42,30V33.5z M42,18c-1.66,0-3.16-0.67-4.24-1.76 C36.67,15.16,36,13.66,36,12h3.5c1.38,0,2.5,1.12,2.5,2.5V18z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="10px" height="10px" fill="currentColor"
            v-if="['growth', 'fia'].includes(transformedType)">
            <path
                d="M 35 14 A 2.0002 2.0002 0 1 0 35 18 L 39.171875 18 L 29.5 27.671875 L 18.914062 17.085938 A 2.0002 2.0002 0 0 0 16.085938 17.085938 L 2.5859375 30.585938 A 2.0002 2.0002 0 1 0 5.4140625 33.414062 L 17.5 21.328125 L 28.085938 31.914062 A 2.0002 2.0002 0 0 0 30.914062 31.914062 L 42 20.828125 L 42 25 A 2.0002 2.0002 0 1 0 46 25 L 46 16 A 2.0002 2.0002 0 0 0 44 14 L 35 14 z" />
        </svg>
        <CakeIcon v-if="transformedType === 'bonus'" class="h-2" />
        <ArrowDownTrayIcon v-if="transformedType === 'spia'" class="h-2" />
        <ArrowUpRightIcon v-if="transformedType === 'dia'" class="h-2" />
        <span class="leading-3 h-3">
            {{ transformedType }}
        </span>
    </span>
</template>

<script setup lang="ts">
import { FocusOption } from '@/stores/questionnaire/types';
import { ProductType, StrategyType } from '@/types';
import { ArrowDownTrayIcon, ArrowUpRightIcon, CakeIcon } from '@heroicons/vue/16/solid';
import { onMounted, ref } from 'vue';

const {
    type = '',
    color = false
} = defineProps<{
    type: StrategyType | FocusOption | ProductType,
    color?: boolean
}>()

const transformedType = ref<string>('')

onMounted(() => {
    switch (type) {
        case StrategyType.INCOME:
        case StrategyType.HYBRID:
        case FocusOption.INCOME:
            transformedType.value = 'income';
            break;
        case StrategyType.BONUS:
            transformedType.value = 'bonus';
            break;
        case FocusOption.GROWTH:
        case ProductType.MYGA:
            transformedType.value = 'growth';
            break;
        case ProductType.SPIA:
            transformedType.value = 'spia';
            break;
        case ProductType.DIA:
            transformedType.value = 'dia';
            break;
        case ProductType.FIA:
            transformedType.value = 'fia';
            break;
        default:
            transformedType.value = '';
            break;
    }
})
</script>