<template>
    <div class="grid gap-4">
        <div>
            <p class="text-sm text-gray-500 col-span-full">Enter the verification code we sent to
                <strong>{{ formatPhone(store.phone.toString()) }}</strong> below.
            </p>
            <p>
                <button @click="store.setField('isVerificationCodeSent', false)"
                    class="text-sm underline text-primary-600">&lsaquo; Go back</button>
            </p>
        </div>
        <TextField name="verificationCode" label="Verification Code" inputmode="numeric" :value="store.verificationCode"
            @input="v => store.setField('verificationCode', v)" />
        <ErrorMessage v-if="store.verificationFormErrorMessages" class="col-span-full">{{
            store.verificationFormErrorMessages }}
        </ErrorMessage>
        <div class="col-span-full flex justify-center">
            <PrimaryButton @click="store.verifyCode" :loading="store.isVerifyLoading"
                :disabled="!store.isVerificationFormValid">See my strategies</PrimaryButton>
        </div>
        <div class="text-gray-800 text-center  text-sm">Didn't get one?
            <span v-if="store.isVerificationCodeResent" class="font-bold">Code sent!</span>
            <button class="text-primary-600 underline" @click="store.resendCode"
                v-else-if="!store.isVerificationCodeResending">Re-send
                code.</button>
            <span v-else-if="store.isVerificationCodeResending" class="font-bold text-primary-600">
                <SpinningLoader class="h-3 -mt-1.5 inline" small color="#1454f2" />
                Sending...
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ErrorMessage, PrimaryButton, SpinningLoader, TextField } from '@reviseannuity/randolph-ui';
import { useRegistrationStore } from '@/stores/registration';
import { formatPhone } from '@/utils';

const store = useRegistrationStore()
</script>