export const isProduction = import.meta.env.PROD

export const calculateAge = (birthdate: Date): number => {
    const today = new Date();
    const age = today.getFullYear() - birthdate.getFullYear();
    const month = today.getMonth() - birthdate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
        return age - 1;
    }
    return age;
}

export const generateId = (): string => Math.random().toString(36).substr(2, 9)

export const formatMoney = (number: number): string => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
}).format(number)

export const formatCompactNumber = (number: number): string => new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
}).format(number)

export const formatSSN = (ssn: number): string => {
    if (!ssn) return
    let value = ssn.toString().replace(/\D/g, '')
    value = value.replace(/^(\d{3})/, '$1-')
    value = value.replace(/-(\d{2})/, '-$1-')
    value = value.replace(/(\d)-(\d{4}).*/, '$1-$2')
    return value
}

export const formatDateInput = (date: string): string => {
    if (!date) return
    let value = date.replace(/\D/g, '')
    value = value.replace(/^(\d{2})/, '$1/')
    value = value.replace(/\/(\d{2})/, '/$1/')
    value = value.replace(/(\d{4}).*/, '$1')
    return value
}

export const formatDate = (date: Date): string => {
    if (!date) return

    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC'
    })
}

export const formatApplicationDate = (date: Date): string => {
    if (!date) return

    return new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)).toLocaleDateString('UTC', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    })
}

export const formatPhone = (phone: string): string => {
    if (!phone) return
    let value = phone.replace(/\D/g, '')
    value = value.replace(/^(\d{3})/, '($1) ')
    value = value.replace(/\) (\d{3})/, ') $1-')
    value = value.replace(/(\d{4}).*/, '$1')
    return value
}

export const formatPercentage = (decimal: number): string => {
    if (!decimal) return "0"
    return (decimal * 100).toFixed(2).replace(/[.,]00$/, '')
}

export const emailIsValid = (email: string): boolean => email.length && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

export const variableRateCompoundInterest = (principal: number, rates: number[]): number[] => {
    const growth = []
    rates.forEach((rate, i) => {
        if (i === 0) {
            growth.push(principal * (1 + rate))
            return
        }

        growth.push(growth[i - 1] * (1 + rate))
    })
    return growth
}

export const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i >= 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array
}

export const unqualifiedFundLocations = {
    "checking": "Checking",
    "savings": "Savings",
    "brokerage-account": "Taxable Brokerage Account",
    "paper-check": "Paper Check"
}

export const qualifiedFundLocations = {
    "401k": "401(k)",
    "403b": "403(b)",
    "ira": "IRA",
    "roth-ira": "Roth IRA",
    "tsp-tsa": "TSP/TSA"
}

export const ownerTypes = {
    'individual': 'Me',
    'joint': 'Me and Someone Else',
}

export const annuitantTypes = {
    "self": 'Me',
    "spouse-joint": 'My Spouse and I',
    "other": 'Someone else',
}

export const investmentTypes = {
    'stocks-bonds': "Stocks and Bonds",
    'annuities': "Annuities",
    'mutual-funds': "Mutual Funds",
    'cds': "CDs",
    'money-market': "Money Market",
    'checking-savings': "Checking or Savings",
    'pension-401k': "Pension or 401(k)",
    'life-insurance': "Life Insurance",
    'real-estate': "Real Estate",
    'reverse-mortgage': "Reverse Mortgage",
    'limited-partnership': "Limited Partnership",
}

export const formatApplicationText = (data) => {
    let formattedText = "# Application Submission"
    // FUNDING AMOUNT
    formattedText += `
## Funding Amount
Total funding amount: ${formatMoney(data.investmentAmount)}

---
`
    // STRATEGY
    formattedText += `
## Strategy
**Name:** ${data.strategy?.name}
**Product:** ${data.strategy?.underlyingProduct?.insurer?.name} - ${data.strategy?.underlyingProduct?.name}
**Allocation:** ${data.strategy?.indices.map(index => `${index.percentage * 100}% ${index.name}`).join(', ')}

---
`
    // PERSONAL INFORMATION
    formattedText += `
## Personal Information
**Full Name:** ${data.personalInformation.name}
**Email:** ${data.personalInformation.email}
**Phone:** ${data.personalInformation.phone}
**Date of Birth:** ${formatApplicationDate(new Date(data.personalInformation.birthdate))}
**Address:** ${data.personalInformation.address}
${data.personalInformation.address_2}
${data.personalInformation.city}, ${data.personalInformation.state} ${data.personalInformation.zip}

---

### Identification
**Form of ID:** ${data.personalInformation.formOfIdentification}
**ID Number:** ${data.personalInformation.idNumber}
**Expiration Date:** ${formatApplicationDate(new Date(data.personalInformation.expirationDate))}
`
    if (data.personalInformation.formOfIdentification != 'passport') formattedText += `**State of Issue:** ${data.personalInformation.issuingState}`

    // EMPLOYMENT
    formattedText += `
`

    // FUNDING SOURCES
    formattedText += `
---

## Funding Sources
`
    data.fundingSources.forEach((fundingSource, i) => {
        formattedText += `
### Funding Source ${i + 1} of ${data.fundingSources.length}
**Where are these funds current located?** ${fundingSource.fundsLocation}
**Institution:** ${fundingSource.institution}
**Amount:** ${formatMoney(fundingSource.amount)}
**Routing Number:** ${fundingSource.routingNumber}
**Account Number:** ${fundingSource.accountNumber}
`
    })

    // OWNERSHIP
    formattedText += `
---

## Ownership
**Ownership type:** ${data.ownerType}
`

    if (["joint", "individual"].includes(data.ownerType)) {
        formattedText += `
### Owner
**Gender:** ${data.owner.gender}
**Full Name:** ${data.owner.firstName} ${data.owner.middleName || ""} ${data.owner.lastName}
**Date of Birth:** ${formatApplicationDate(new Date(data.owner.birthdate))}
**SSN:** ${formatSSN(data.owner.ssn)}
**Address:** ${data.owner.address}
${data.owner.address_2}
${data.owner.city}, ${data.owner.state} ${data.owner.zip}
`
    }

    if (data.ownerType === "joint") {
        formattedText += `
### Joint Owner
**Gender:** ${data.jointOwner.gender}
**Full Name:** ${data.jointOwner.firstName} ${data.jointOwner.middleName || ""} ${data.jointOwner.lastName}
**Date of Birth:** ${formatApplicationDate(new Date(data.jointOwner.dob))}
**SSN:** ${formatSSN(data.jointOwner.ssn)}`
        if (!data.jointOwner?.sameAddress) {
            formattedText += `**Address:** ${data.jointOwner.address}
${data.jointOwner.city}, ${data.jointOwner.state} ${data.jointOwner.zip}`
        }
    }

    // ANNUITANT
    const annuitantTypes = { self: "Myself", "spouse-joint": "My Spouse and I", other: "Someone else" }
    formattedText += `

---

## Annuitant
**Who is the annuitant?** ${annuitantTypes[data.annuitantType]}
`

    if (data.annuitantType === "other") {
        formattedText += `
### Other Annuitant
**Gender:** ${data.otherAnnuitant.gender}
**Full Name:** ${data.otherAnnuitant.firstName} ${data.otherAnnuitant.middleName} ${data.otherAnnuitant.lastName}
**SSN:** ${data.otherAnnuitant.ssn ? formatSSN(data.otherAnnuitant.ssn) : 'N/A'}
**Address:** ${data.otherAnnuitant.address}, ${data.otherAnnuitant.city}, ${data.otherAnnuitant.state} ${data.otherAnnuitant.zip}
`
    }

    // BENEFICIARIES
    formattedText += `
---

## Beneficiaries
`
    data.beneficiaries.forEach((beneficiary, i) => {
        formattedText += `
### Beneficiary ${i + 1} of ${data.beneficiaries.length}
**Type:** ${beneficiary.type} 
**Status:** ${beneficiary.status} 
**Contingency** ${beneficiary.contingency} 
**Gender:** ${beneficiary.gender} 
**Full Name:** ${beneficiary.firstName} ${beneficiary.middleName} ${beneficiary.lastName} 
**SSN:** ${beneficiary.ssn ? formatSSN(beneficiary.ssn) : 'N/A'} 
**Date of Birth:** ${formatApplicationDate(new Date(beneficiary.dob))}
**Address:** ${beneficiary.address}
${beneficiary.address_2}
${beneficiary.city}, ${beneficiary.state} ${beneficiary.zip} 
**Relationship:** ${beneficiary.relationship}
**Percentage** ${beneficiary.percentage}%
`
    })

    // EXISTING POLICIES
    formattedText += `
---

## Existing Policies
**Do you have any existing policies?** ${data.existingPolicy ? 'Yes' : 'No'}
**Is this policy replacing an existing policy?** ${data.replacingPolicy ? 'Yes' : 'No'} 
`
    if (data.existingPolicy) {
        formattedText += `
#### Existing Policy
**Company Name:** ${data.replacingPolicyData.company}
**Policy Number:** ${data.replacingPolicyData.contractNumber}
`
    }

    // HOUSEHOLD INFO
    formattedText += `
---

## Household Information
**Monthly Household Income:** ${formatMoney(data.householdIncome)}
**Monthly Household Expenses:** ${formatMoney(data.householdExpenses)}
**Household Liabilities:** ${formatMoney(data.householdLiabilities)}
**Tax Bracket:** ${calculateTaxBracket(data.householdIncome * 12)}
`
    if (data.householdInvestments.length > 0) {
        formattedText += `
### Current Investments`
    }

    data.householdInvestments.forEach((investment, i) => {
        const investmentDetails = data.householdInvestmentDetails[investment]
        formattedText += `
**Type:** ${investment}
**Account Value:** ${formatMoney(investmentDetails.value)}
**Are you partially or wholly using this account to fund your annuity?** ${investmentDetails.fundAnnuity ? 'Yes' : 'No'}

---
`
    })

    if (data.dependents) {
        formattedText += `
### Dependents`
        if (data.dependent1Age) formattedText += `\n**Dependent 1 Age:** ${data.dependent1Age}`
        if (data.dependent2Age) formattedText += `\n**Dependent 2 Age:** ${data.dependent2Age}`
        if (data.dependent3Age) formattedText += `\n**Dependent 3 Age:** ${data.dependent3Age}`
    }

    formattedText += `
**Tax Filing Status:** ${data.taxFilingStatus}`

    return formattedText
}

const calculateTaxBracket = (income: number): string => {
    if (income <= 11000) return "10%"
    if (income <= 44725) return "12%"
    if (income <= 95375) return "22%"
    if (income <= 182100) return "24%"
    if (income <= 231250) return "32%"
    if (income <= 578125) return "35%"
    return "37%"
}

export const debounce = (func: () => any, delay: number): () => any => {
    let timeout
    return function () {
        const context = this
        const args = arguments
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            func.apply(context, args)
        }, delay)
    }
}

export const setBgColor = (color: string): void => {
    document.body.style.backgroundColor = color
}

export const renderPercentage = (percentage: number): string => {
    return `${formatPercentage(percentage)}<sup class="-mr-0">%</sup>`
}

export const renderPercentageRange = (low: number, high?: number): string => {
    if (!high) {
        [low, high] = calculatePercentageRange(low)
    }
    return `<span>
    ${formatPercentage(low)} - ${renderPercentage(high)}
</span>`
}

export const calculatePercentageRange = (percentage: number): [low: number, high: number] => {
    const distance = percentage * 0.1
    const low = percentage - distance
    const high = percentage + distance
    return [low, high]
}

export const renderYears = (numberOfYears: number): string => {
    return `${numberOfYears}<small>yrs</small>`
}