<template>
    <div v-if="store.submittingApplication"
        class="fixed top-0 left-0 right-0 bottom-0 bg-primary-600 z-[999] flex items-center justify-center text-white bg-opacity-90">
        <div class="flex justify-center items-center flex-col">
            <div class="w-32 mb-8">
                <SpinningLoader />
            </div>
            <p class="font-bold font-serif text-3xl text-center">Submitting your <br />application.</p>
        </div>
    </div>
    <div v-if="store.loading && !store.errorMessage && !store.applicationExpired"
        class="text-lg fixed left-0 top-0 w-full h-full flex justify-center items-center">
        <div class="w-12">
            <SpinningLoader color="#1454f2" />
        </div>
    </div>
    <div v-else-if="store.errorMessage"
        class="text-center text-lg text-red-600 fixed left-0 top-0 w-full h-full flex flex-col justify-center items-center">
        <p>
            {{ store.errorMessage }}
        </p>
        <p class="text-sm">If you think this is a mistake, please email <a href="mailto:help@getrevise.com"
                class="underline">help@getrevise.com</a></p>
    </div>
    <div v-else-if="store.applicationExpired" class="w-screen h-screen flex justify-center items-center">
        <div class="bg-white p-8 rounded-lg shadow-lg max-w-sm">
            <h2 class="text-2xl font-semibold mb-4 text-gray-900">This application has expired.</h2>
            <p class="text-gray-800">Revise annuity applications have a life span of two weeks. Please start a new
                application.</p>
            <PrimaryButton @click="router.push('/get-started')" class="w-full mt-4">Start a new application
            </PrimaryButton>
        </div>
    </div>
    <div v-else>
        <div class="fixed lg:inset-y-0 z-50 flex w-full lg:w-72 flex-col">
            <ApplicationSidebar />
        </div>
        <div class=" pt-28 pl-0 lg:pt-0 lg:pl-72 relative">
            <div class="px-6 mx-auto max-w-2xl lg:px-6 lg:px-8">
                <div class="space-y-6 lg:space-y-8 pt-4 pb-24 lg:pb-28 lg:py-16 lg:min-h-screen ">
                    <RouterView />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';
import { useApplicationStore } from '@/stores/application'
import ApplicationSidebar from '@/components/ApplicationSidebar.vue';
import { RouterView, useRouter } from 'vue-router';
import { createApplicationForUser, getApplicationForUser } from '@/clients/user';
import { getStrategy } from '@/clients/strategies';
import { SpinningLoader, PrimaryButton } from '@reviseannuity/randolph-ui';
import { useAuth0 } from '@auth0/auth0-vue';
import { applicationStartedEvents } from '@/eventTagging';
import { useRegistrationStore } from '@/stores/registration';
import { setBgColor } from '@/utils';

const store = useApplicationStore()
const registrationStore = useRegistrationStore()

const router = useRouter()
const { logout, user } = useAuth0()

let autoSaveInterval

onMounted(async () => {
    if (!store.user.auth0_id) {
        store.setField('user', {
            auth0_id: registrationStore.auth0Id
        })
    }

    setBgColor('#f1f5f9')

    autoSaveInterval = setInterval(() => {
        store.saveApplicationProgress()
    }, 1000 * 60)

    window.addEventListener("beforeunload", function (event) {
        store.saveApplicationProgress()
    });

    try {
        let application

        if (store.user.application) {
            application = await getApplicationForUser(store.user.auth0_id)

            if (!application) {
                throw new Error('No application found')
            }

            if (application.json) {
                const TWO_WEEKS = 1000 * 60 * 60 * 24 * 7 * 2

                const applicationIsExpired = Date.now() - new Date(application.created_at).getTime() > TWO_WEEKS
                if (applicationIsExpired) {
                    store.setField('applicationExpired', true)
                    store.setField('loading', false)
                    return
                }
                await store.$patch(JSON.parse(application.json))
                store.setField('loading', false)
                return
            }
        } else {
            application = await createApplicationForUser(store.user.auth0_id)
            store.setField('applicationId', application.fields.id)
        }

        if (!store.strategy) {
            if (registrationStore.strategy) {
                if (!registrationStore.strategy) throw new Error('No strategy specified')

                const strategyDetails = await getStrategy(registrationStore.strategy.id)
                if (!strategyDetails) throw new Error('No strategy found')

                const indexValues = [
                    {
                        percentage: strategyDetails.index1Percentage,
                        parHigh: strategyDetails.par1High,
                        parLow: strategyDetails.par1Low
                    },
                    {
                        percentage: strategyDetails.index2Percentage,
                        parHigh: strategyDetails.par2High,
                        parLow: strategyDetails.par2Low
                    }
                ]

                store.setField('strategy', {
                    ...strategyDetails,
                    indices: strategyDetails.indices.map((index, i) => {
                        return {
                            ...index,
                            ...indexValues[i]
                        }
                    })
                })

                let phone = user.value.name
                if (phone && phone.startsWith('+1')) {
                    phone = phone.slice(2)
                }

                let name = registrationStore.firstName
                if (registrationStore.lastName) name += ` ${registrationStore.lastName}`

                if (registrationStore.investmentAmount) store.setField('investmentAmount', registrationStore.investmentAmount)
                if (registrationStore.birthdate) store.setPersonalInformation('birthdate', new Date(registrationStore.birthdate).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                }))
                if (registrationStore.state) store.setPersonalInformation('state', registrationStore.state)
                if (registrationStore.zip) store.setPersonalInformation('zip', registrationStore.zip)
                if (name) store.setPersonalInformation('name', name)
                if (registrationStore.email) store.setPersonalInformation('email', registrationStore.email)
                if (phone) store.setPersonalInformation('phone', phone)
                if (registrationStore.fundingSource) store.setField('fundingSourceType', registrationStore.fundingSource === 'after-tax' ? 'unqualified' : 'qualified')

                applicationStartedEvents(
                    registrationStore.investmentAmount,
                    registrationStore.state,
                    name,
                    registrationStore.email,
                    phone,
                    registrationStore.birthdate.toDateString()
                )

                store.setField('loading', false)



            } else {
                await logout({
                    openUrl: () => {
                        router.push('/get-started')
                    }
                })
            }
        }

    } catch (error) {
        store.errorMessage = error.message
    }
})

onUnmounted(() => {
    clearInterval(autoSaveInterval)
})
</script>