<template>
    <div class="mx-auto" :class="{ 'max-w-sm': !('class' in $attrs) }" v-bind="$attrs">
        <div class="bg-white shadow-lg rounded-lg p-6 sm:p-10">
            <h2 class="font-serif font-bold text-2xl sm:text-3xl mb-2" :class="[helperText ? 'mb-2' : 'mb-6']"
                v-if="title?.length">{{ title
                }}
            </h2>
            <HelperText class="mb-6" v-if="helperText">{{ helperText }}</HelperText>
            <slot></slot>
            <PrimaryButton :loading="loading" v-if="!noButton" :disabled="disableNext" class="w-full"
                @click="$emit('click')" :id="buttonId">
                {{ continueText }}</PrimaryButton>
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'
import { PrimaryButton, HelperText } from '@reviseannuity/randolph-ui';

const emit = defineEmits(['click'])
const {
    title,
    helperText,
    disableNext = false,
    enableEnterKey = true,
    noButton = false,
    continueText = 'Continue',
    loading = false,
    buttonId
} = defineProps<{
    title?: string
    helperText?: string
    disableNext?: boolean
    enableEnterKey?: boolean
    noButton?: boolean
    continueText?: string
    loading?: boolean
    buttonId?: string
}>()

const handleKeyDown = event => {
    if (!enableEnterKey) return
    if (event.key === 'Enter') {
        if (disableNext) return
        emit('click')
    }
};

onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
});

onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
});

</script>