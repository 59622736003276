<template>
    <SDPWrapper>
        <h2 class="text-xl sm:text-2xl font-bold font-serif mb-4 text-center">Other popular strategies</h2>
        <div class="relative">
            <div v-if="recommendedScrolledPastStart">
                <div
                    class="pointer-events-none absolute left-0 top-0 bottom-0 w-32 bg-gradient-to-r from-white via-white via-10% to-transparent z-2">
                </div>
            </div>
            <div v-if="!recommendedScrolledToEnd">
                <div
                    class="pointer-events-none absolute right-0 top-0 bottom-0 w-32 bg-gradient-to-l from-white via-white via-10% to-transparent z-2">
                </div>
            </div>
            <div class="overflow-scroll no-scrollbar" ref="recommended" @scroll="scroll" v-dragscroll>
                <div class="flex flex-nowrap gap-4">
                    <div class="w-80 grow-0 shrink-0 mb-8 select-none" v-for="strategy, i in recommendedStrategies">
                        <StrategyCard class="cursor-pointer" :strategy="strategy" :key="strategy.id"
                            @click="$emit('selectStrategy', strategy.id)" small />
                    </div>
                </div>
            </div>
        </div>
    </SDPWrapper>
</template>

<script setup lang="ts">
import SDPWrapper from './SDPWrapper.vue';
import StrategyCard from '@/components/StrategyCard.vue';
import { ref } from 'vue';
import { Strategy } from '@/types';

defineEmits(['selectStrategy'])

const { recommendedStrategies } = defineProps<{
    recommendedStrategies: Strategy[]
}>()

const recommended = ref(null)
const recommendedScrolledPastStart = ref(false)
const recommendedScrolledToEnd = ref(false)

const scroll = () => {
    if (!recommended.value) return
    const el = recommended.value
    recommendedScrolledPastStart.value = el.scrollLeft > 0
    recommendedScrolledToEnd.value = el.scrollLeft + el.clientWidth == el.scrollWidth
}
</script>