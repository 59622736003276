import { getAllStrategies } from "../../clients/strategies";
import { productTypeOptions, returnsOptions } from "../../data/strategyList";
import { questionnaireCompletedEvents } from "../../eventTagging";
import { GHLCalendarData, ProductTypeOption, QuestionnaireStep, QuestionnaireStore, ReturnsOption, SortOption, UseCaseOption } from "./types";
import { Strategy, StrategyType } from "../../types";

export default {
    setField<K extends keyof QuestionnaireStore>(field: K, value: QuestionnaireStore[K]): void {
        this[field] = value;
    },
    nextStep(): QuestionnaireStep {
        const state = this as QuestionnaireStore

        const nextStep = state.steps[state.activeStep].next(state)
        if (!nextStep) throw Error('Step not found: ' + nextStep)
        this.setStep(nextStep)
        return state.activeStep
    },
    setStep(step: QuestionnaireStep): void {
        const state = this as QuestionnaireStore

        state.activeStep = step
    },
    validateStep(step: QuestionnaireStep): boolean {
        const state = this as QuestionnaireStore

        if (!state.steps[step]) throw new Error(`No validation for step ${step} found!`)
        return state.steps[step].validate(state)
    },
    async fetchStrategies(): Promise<void> {
        const state = this as QuestionnaireStore

        const startTime = Date.now()
        state.strategyLoadingError = null
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100vh';
        state.loadingStrategies = true

        try {
            const res = await getAllStrategies({
                state: state.state,
                age: state.age,
                riskTolerance: state.riskTolerance,
                investmentAmount: state.investmentAmount,
                focus: state.focus,
                retirementAge: state.retirementAge
            })

            state.minVolatility = Math.min(...res.map(s => Number(s.volatility)))
            state.maxVolatility = Math.max(...res.map(s => Number(s.volatility)))
            const endTime = Date.now()
            const duration = endTime - startTime
            state.strategies = res
            this.sortAndFilter()
            await new Promise(resolve => setTimeout(resolve, 4500 - duration))
            questionnaireCompletedEvents(state.investmentAmount)
            state.showPaywall = true
            state.loadingStrategies = false
            document.body.style.overflow = 'auto';
            document.body.style.height = 'auto';
        } catch (error) {
            state.strategyLoadingError = error
            document.body.style.overflow = 'auto';
            document.body.style.height = 'auto';
            state.loadingStrategies = false
        }
    },
    setProductType(type: ProductTypeOption, value: boolean): void {
        const state = this as QuestionnaireStore

        if (value) {
            state.productType = [...state.productType, type]
        } else {
            state.productType = state.productType.filter(t => t !== type)
        }
    },
    setReturns(type: ReturnsOption, value: boolean): void {
        const state = this as QuestionnaireStore

        if (value) {
            state.returns = [...state.returns, type]
        } else {
            state.returns = state.returns.filter(t => t !== type)
        }
    },
    resetFilters(): void {
        const state = this as QuestionnaireStore

        state.productType = productTypeOptions.map(option => option.value)
        state.returns = returnsOptions.map(option => option.value)
        state.minimumRating = 0
        state.useCase = null
    },
    sortAndFilter(): void {
        const state = this as QuestionnaireStore

        const strategies = state.strategies.slice(1)
            .filter(strategy => {
                return this.hasMinimumRating(strategy, state.minimumRating) && this.hasProductType(strategy, state.productType) && this.hasUseCase(strategy, state.useCase) && this.hasReturnType(strategy, state.returns)
            })
            .sort((a, b) => {
                switch (state.sortBy) {
                    case SortOption.BEST_MATCH:
                        return b.matchScore - a.matchScore
                    case SortOption.HIGHEST_RATE:
                        return b.historicalRate - a.historicalRate
                    case SortOption.LOWEST_VOLATILITY:
                        return a.volatility - b.volatility
                    case SortOption.HIGHEST_RATING:
                        return b.underlyingProduct.insurer.rating - a.underlyingProduct.insurer.rating
                    case SortOption.MOST_REVIEWS:
                        return b.reviews.length - a.reviews.length
                    default:
                        return 0
                }
            })
        state.sortedResults = strategies
    },
    hasMinimumRating(strategy: Strategy, minimumRating: number): boolean {
        if (!minimumRating) return true

        const strategyRating = strategy.reviews.reduce((acc, review) => acc + review.rating, 0) / strategy.reviews.length
        return strategyRating >= minimumRating
    },
    hasProductType(strategy: Strategy, productType: StrategyType[]): boolean {
        let strategyType: StrategyType = strategy.type
        if (['income', 'hybrid'].includes(strategy.type)) strategyType = StrategyType.INCOME
        if (['growth', 'myga'].includes(strategy.type)) strategyType = StrategyType.GROWTH
        return productType.includes(strategyType)
    },
    hasUseCase(strategy: Strategy, useCase: UseCaseOption): boolean {
        if (!useCase) return true

        switch (useCase) {
            case UseCaseOption["401K"]:
                return strategy.rolloverApproved
            default:
                return strategy.tags.map(tag => tag.name.toLowerCase()).includes(UseCaseOption[useCase].toLowerCase())
        }
    },
    hasReturnType(strategy: Strategy, returns: ReturnsOption[]): boolean {
        if (!returns.length) return false

        return strategy?.returnType?.some(type => {
            return returns.includes(type)
        })
    },
    async handleBookingComplete(data: GHLCalendarData): Promise<void> {
        console.log('booking complete! ', data)
        window.location.href = `https://getrevise.com/thank-you?data=${btoa(JSON.stringify(data))}`
    }
}