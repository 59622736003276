<template>
    <div class="border rounded">
        <div class="border-b border-gray-200 bg-white px-4 py-3 sm:px-6 flex justify-between items-center">
            <h3 class="text-sm font-semibold leading-6 text-gray-900">
                Funding Source
            </h3>
            <button @click="store.removeFundingSource(id)">
                <XMarkIcon class="h-5 w-5 text-gray-400" />
            </button>
        </div>
        <div class="px-4 py-3 space-y-4">
            <SelectField required label="Account type"
                tooltip="What kind of account is this? Choose brokerage account for any kind of investment account that doesn't match the other options."
                placeholder="Select an option" name="fundsLocation" :options="fundLocationsList"
                @input="e => store.updateFundingSource(id, 'fundsLocation', e)"
                :value="store.getFundingSource(id).fundsLocation" />
            <div class="grid grid-cols-5 gap-4" v-if="store.getFundingSource(id)?.fundsLocation?.length">
                <TextField required
                    tooltip="This is the insitution that currently holds the account you're transferring from - either your bank, retirement account servicer, or brokerage."
                    label="Institution" name="institution" type="text" :value="store.getFundingSource(id).institution"
                    @input="e => store.updateFundingSource(id, 'institution', e)" class="col-span-full sm:col-span-3" />
                <MoneyField required
                    tooltip="This is the amount you're funding from this account. If you're funding the full amount from a single account, please enter your full annuity premium amount here."
                    label="Amount" name="amount" type="number" :value="store.getFundingSource(id).amount"
                    @input="e => store.updateFundingSource(id, 'amount', e)" class="col-span-full sm:col-span-2" />
            </div>
            <div class="grid grid-cols-2 gap-4" v-if="store.getFundingSource(id)?.fundsLocation?.length">
                <TextField required :maxlength="9" label="Routing Number" name="routingNumber" type="text"
                    v-if="sourceType == 'unqualified'" :value="store.getFundingSource(id).routingNumber"
                    @input="e => store.updateFundingSource(id, 'routingNumber', e)"
                    class="col-span-full sm:col-span-1" />
                <TextField :required="sourceType == 'unqualified'" :maxlength="17" label="Account Number"
                    name="accountNumber" type="text" :value="store.getFundingSource(id).accountNumber"
                    @input="e => store.updateFundingSource(id, 'accountNumber', e)"
                    class="col-span-full sm:col-span-1" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { TextField, MoneyField, SelectField } from '@reviseannuity/randolph-ui';
import { defineProps, defineEmits } from 'vue';
import { useApplicationStore } from '@/stores/application';
import { XMarkIcon } from '@heroicons/vue/20/solid';
import { qualifiedFundLocations, unqualifiedFundLocations } from '@/utils';
import { FundingSourceType } from '@/stores/application/types';

const props = defineProps<{
    id: string
    sourceType: FundingSourceType
}>();
defineEmits(['remove']);

const store = useApplicationStore();

const fundLocations = props.sourceType === 'qualified' ? Object.entries(qualifiedFundLocations) : Object.entries(unqualifiedFundLocations);
const fundLocationsList = fundLocations.map(([value, label]) => ({ value, label }));
</script>