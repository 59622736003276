<template>
    <QuestionnaireSection title="At what age do you want to start taking income?"
        helperText="This can be an estimate but should be greater than your current age." @click="nextStep"
        :disableNext="!store.validateStep(QuestionnaireStep.RETIREMENT_AGE)" buttonId="retirementAgeContinue">
        <div class="mb-8">
            <TextField inputmode="numeric" :value="store.retirementAge" :placeholder="store.age + 15"
                @input="onInput" />
            <ErrorMessage v-if="store.retirementAge && store.retirementAge <= store.age">Please enter a number greater
                than your current age.</ErrorMessage>
        </div>
    </QuestionnaireSection>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import QuestionnaireSection from './QuestionnaireSection.vue';
import { useQuestionnaireStore } from '@/stores/questionnaire';
import { event } from 'vue-gtag';
import { useRouter } from 'vue-router';
import { ErrorMessage, TextField } from '@reviseannuity/randolph-ui'
import { QuestionnaireStep } from '@/stores/questionnaire/types';

const router = useRouter()
const store = useQuestionnaireStore();

const onInput = (value) => {
    store.setField('retirementAge', value.replace(/\D/g, ''))
};

const nextStep = () => {
    router.push(store.nextStep())
}

onMounted(() => {
    store.setStep(QuestionnaireStep.RETIREMENT_AGE)
    event('page_view', {
        page_title: 'Master App Questionnaire - Retirement Age',
        page_location: window.location.href,
        page_path: window.location.pathname
    })
})
</script>