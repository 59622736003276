<template>
    <div class="min-h-screen w-screen flex justify-center sm:items-center p-8">
        <div class="bg-white py-12 px-8 rounded-lg shadow-lg w-full max-w-md text-center" v-if="steps.length">
            <img src="../assets/logo.svg" alt="Revise Annuity" class="w-24 mx-auto mb-4" />
            <h1 class="text-3xl font-bold font-serif mb-4">Thank you!</h1>
            <p class="text-gray-800 mb-8">
                Your application has been received. Return to this page to track your application status.</p>
            <ol role="list" class="mb-8 text-left">
                <li v-for="(step, stepIdx) in steps" :key="step.title"
                    :class="[stepIdx !== steps.length - 1 ? 'pb-6' : '', 'relative']">
                    <template v-if="step.status === 'complete'">
                        <div v-if="stepIdx !== steps.length - 1"
                            class="absolute left-4 top-8 -ml-px mt-0.5 h-full w-0.5 bg-primary-600"
                            aria-hidden="true" />
                        <div class="group relative flex items-center">
                            <span class="flex h-9 items-center">
                                <span
                                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary-600 ">
                                    <CheckIcon class="h-5 w-5 text-white" aria-hidden="true" />
                                </span>
                            </span>
                            <span class="ml-4 flex min-w-0 flex-col">
                                <span class="text-sm font-semibold">{{ step.title }}</span>
                            </span>
                        </div>
                    </template>
                    <template v-else-if="step.status === 'current'">
                        <div v-if="stepIdx !== steps.length - 1"
                            class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                        <div class="group relative flex items-center" aria-current="step">
                            <div class="grid grid-cols-1 grid-rows-1">
                                <span
                                    class="flex h-9 w-9 items-center block col-start-1 col-end-1 row-start-1 row-end-1 animate-ping"
                                    aria-hidden="true">
                                    <span
                                        class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-600 bg-white">
                                    </span>
                                </span>
                                <span
                                    class="flex h-9 w-9 items-center block col-start-1 col-end-1 row-start-1 row-end-1"
                                    aria-hidden="true">
                                    <span
                                        class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-600 bg-white">
                                        <span class="h-2.5 w-2.5 rounded-full bg-primary-600" />
                                    </span>
                                </span>
                            </div>
                            <span class="ml-4 flex min-w-0 flex-col w-full">
                                <span class="text-sm font-semibold text-primary-600">{{ step.title }}</span>
                                <span class="text-sm text-gray-500">{{ step.description }}</span>
                            </span>
                        </div>
                    </template>
                    <template v-else>
                        <div v-if="stepIdx !== steps.length - 1"
                            class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                        <div class="group relative flex items-center">
                            <span class="flex h-9 items-center" aria-hidden="true">
                                <span
                                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white ">
                                    <span class="h-2.5 w-2.5 rounded-full bg-transparent " />
                                </span>
                            </span>
                            <span class="ml-4 flex min-w-0 flex-col">
                                <span class="text-sm font-semibold text-gray-500">{{ step.title }}</span>
                            </span>
                        </div>
                    </template>
                </li>
            </ol>
            <p class="text-sm text-gray-600">
                If you have any questions, please email us at
                <a href="mailto:support@getrevise.com" class=" text-primary-600 underline">support@getrevise.com</a>
            </p>
        </div>
        <div v-else class="text-gray-600">Loading...</div>
    </div>
</template>

<script setup lang="ts">
import { CheckIcon } from '@heroicons/vue/24/outline';
import confetti from 'canvas-confetti';
import { ref } from 'vue';
import { onMounted } from 'vue';
import { getApplicationForUser } from '@/clients/user';
import { useAuth0 } from '@auth0/auth0-vue';
import { setBgColor } from '@/utils';

const { user } = useAuth0()
const steps = ref([])
onMounted(async () => {
    setBgColor('#f1f5f9')
    clearInterval(window.autoSaveInterval)
    const application = await getApplicationForUser(user.value.sub) || 'submitted'
    const currentStatus = application.status

    if (!localStorage.getItem('reviseDashboardNoConfetti')) {
        var count = 200;
        var defaults = {
            origin: { y: 0.7 }
        };

        function fire(particleRatio, opts) {
            confetti({
                ...defaults,
                ...opts,
                particleCount: Math.floor(count * particleRatio)
            });
        }

        fire(0.25, {
            spread: 26,
            startVelocity: 55,
        });
        fire(0.2, {
            spread: 60,
        });
        fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 45,
        });

        localStorage.setItem('reviseDashboardNoConfetti', 'true')
    }

    const allSteps = [
        {
            name: 'questionnaire',
            title: "Strategy selection",
            status: ''
        },
        {
            name: 'application',
            title: "Annuity application",
            status: ''
        },
        {
            name: 'submitted',
            title: "Internal review",
            status: '',
            description: "A licensed agent is looking over your application to ensure it passes suitability standards."
        },
        {
            name: 'signatures',
            title: "Signature collection",
            status: '',
            description: "We're awaiting your signatures on the annuity application. Check your email for a link to the carrier signature portal. Please complete with a desktop or laptop computer."
        },
        {
            name: 'carrier',
            title: "Carrier review",
            status: '',
            description: "A review of your application is being performed by the insurance carrier who will issue your policy. We will be in touch if we have any additional questions."
        },
        {
            name: 'pending_premium',
            title: "Pending premium",
            status: '',
            description: "We are waiting to receive your first premium payment via ACH or rollover from your surrendering financial institution."
        },
        {
            name: 'delivery',
            title: "Delivery receipt",
            status: '',
            description: "Your annuity policy documents has been delivered via email, and your signature is legally required to confirm receipt."
        },
        {
            name: 'in_force',
            title: "Policy in force",
            status: '',
            description: "Your policy is in force and can be viewed within the carrier portal."
        },
    ]

    steps.value = allSteps.map((step, idx) => {
        if (idx === allSteps.findIndex(s => s.name === currentStatus)) {
            if (idx === allSteps.length - 1) {
                return { ...step, status: 'complete' }
            }

            return { ...step, status: 'current' }
        } else if (idx < allSteps.findIndex(s => s.name === currentStatus)) {
            return { ...step, status: 'complete' }
        } else {
            return { ...step, status: '' }
        }
    })
})
</script>