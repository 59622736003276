<template>
    <SDPWrapper>
        <div class="grid grid-cols-2 gap-4 lg:gap-x-12 lg:gap-y-8">
            <SDPTitle class="text-center col-span-full">Why choose Revise?</SDPTitle>
            <div v-for="bullet in bullets" class="text-center">
                <component :is="bullet.icon" class="h-10 text-primary-600 inline-block mb-4" />
                <h3 class="text font-serif font-bold">{{ bullet.title }}</h3>
                <p class="text-gray-600 text-sm">{{ bullet.body }}</p>
            </div>
        </div>
    </SDPWrapper>
</template>

<script setup lang="ts">
import { PercentBadgeIcon, ShieldCheckIcon, StarIcon, TrophyIcon } from '@heroicons/vue/24/outline';
import SDPWrapper from './SDPWrapper.vue';
import SDPTitle from './SDPTitle.vue';

const bullets = [
    {
        icon: ShieldCheckIcon,
        title: '100% Market Protection',
        body: 'Our annuity strategies grow with the market but are totally loss protected.'
    },
    {
        icon: PercentBadgeIcon,
        title: 'Tax-Deferred Growth',
        body: 'Growth in all of our annuities are tax-deferred whether you fund with pre- or post- tax dollars.'
    },
    {
        icon: StarIcon,
        title: 'Top-rated Carriers',
        body: 'We only partner with highly-rated carriers so you can rest easy knowing your funds are safe.'
    },
    {
        icon: TrophyIcon,
        title: 'Best-in-Class Experience',
        body: 'Revise is a highly-rated insurance brokerage founded on the values of transparency, support, and simplicity.'
    }
]
</script>