<template>
    <SDPWrapper thin
        class="lg:sticky top-20 bg-white z-50 flex flex-col gap-4 sm:gap-0 sm:flex-row justify-between items-top text-center sm:text-left">
        <div>
            <h2 class="text-2xl sm:text-3xl font-bold font-serif mb-2">
                {{ strategy.name }}
            </h2>
            <p class="text-gray-500 text-sm mb-4">
                {{ strategy.tagline }}
            </p>

            <div class=" flex gap-2 items-center justify-center sm:justify-start mt-4">
                <StrategyTypeTag :type="strategy.type" />
                <div class="flex gap-2 items-center justify-center sm:justify-start" v-if="totalReviews">
                    <StarRating :rating="rating" />
                    <RouterLink :to="{ hash: '#reviews', params: { offset: 200 } }"
                        class="text-gray-400 text-xs underline">
                        ({{ totalReviews }} {{ totalReviews > 1 ? 'reviews' : 'review' }})</RouterLink>
                </div>
            </div>
            <div
                class="mt-8 fixed bottom-0 left-0 z-[5] right-0 bg-white px-8 pb-8 pt-4 lg:p-0 lg:static flex flex-col sm:block border-t border-gray-200 sm:border-0">
                <PrimaryButton class="mb-2" @click="continueWithStrategy" id="continueButton">Discuss this strategy with
                    an expert
                </PrimaryButton>
            </div>
        </div>
        <div class="flex flex-col">
            <div class="text-center sm:text-right">
                <div class="font-semibold text-gray-700">
                    {{ formatMoney(strategy.minimumInvestment) }} - ${{ formatCompactNumber(strategy.maximumInvestment)
                    }}
                </div>
            </div>

        </div>
    </SDPWrapper>
    <CalendarBookingModal :open="showCal" @close="showCal = false" :calendarId="CalendarId.Intro"
        @bookingComplete="store.handleBookingComplete" />
</template>

<script setup lang="ts">
import SDPWrapper from './SDPWrapper.vue';
import { StarRating, PrimaryButton } from '@reviseannuity/randolph-ui';
import { ref } from 'vue';
import { RouterLink } from 'vue-router';
import { useRegistrationStore } from '@/stores/registration';
import CalendarBookingModal from '@/components/CalendarBookingModal.vue';
import { formatMoney, formatCompactNumber } from '@/utils';
import StrategyTypeTag from '@/components/StrategyTypeTag.vue';
import { updateUser } from '@/clients/user';
import { CalendarId } from '@/data/calendarIds';
import { Strategy } from '@/types';
import { useQuestionnaireStore } from '@/stores/questionnaire';

const { strategy } = defineProps<{
    strategy: Strategy
    totalReviews: number
    rating: number
}>()

const registrationStore = useRegistrationStore();
const store = useQuestionnaireStore()

const showCal = ref(false)

const continueWithStrategy = () => {
    registrationStore.setField('strategy', strategy)

    updateUser(registrationStore.auth0Id, {
        strategy_name: strategy.name
    })

    showCal.value = true
}
</script>