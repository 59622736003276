import { createApp, markRaw } from 'vue'
import '@/index.css'
import App from '@/App.vue'
import { router } from '@/router'
import { createPinia } from 'pinia'
import { createAuth0 } from '@auth0/auth0-vue'
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css';
import VueGtag from 'vue-gtag'
import Hotjar from 'vue-hotjar'
import * as Sentry from "@sentry/vue";
import VueDragscroll from "vue-dragscroll";
import { loadMetaPixel, loadLinkedInPixel, loadTikTokPixel, loadRevisePixel, loadTrackDeskPixel } from '@/eventTagging'

const app = createApp(App)
const pinia = createPinia()
pinia.use(({ store }) => { store.router = markRaw(router) })
const auth0 = createAuth0({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    authorizationParams: {
        redirect_uri: window.location.origin
    }
})

app.use(pinia)
app.use(auth0)
app.use(router)
app.use(ToastPlugin)
app.use(VueDragscroll)

app.config.globalProperties.window = window

console.log(`%cRevise Master Application`, 'color: #1454f2;font-size:2em;')
console.log(`%cEnvironment: ${import.meta.env.MODE}
Production: ${import.meta.env.PROD ? 'True' : 'False'}
Version: ${__APP_VERSION__}`, 'font-family:monospace;')

if (import.meta.env.PROD) {
    app.use(VueGtag, {
        config: { id: "G-LB5BT7V27L" }
    })

    loadMetaPixel()
    loadTikTokPixel()
    loadLinkedInPixel()
    loadRevisePixel()
    loadTrackDeskPixel()

    Sentry.init({
        app,
        dsn: "https://50d5484cf330d17684796b3d41aaf15b@o4506139684503552.ingest.us.sentry.io/4507662469038080",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    app.use(Hotjar, {
        id: '5074504',
    })
}

app.mount('#app')
