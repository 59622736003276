export const call = async <T>(method, path, body?, userOptions?): Promise<T> => {
    const options: RequestInit = {
        method,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': import.meta.env.VITE_API_KEY
        }
    }

    if (body) {
        options.body = JSON.stringify(body)
    }

    if (options) {
        Object.assign(options, userOptions)
    }

    const res = await fetch(`${import.meta.env.VITE_API_URL}${path}`, options)
    const json = await res.json()

    if (!res.ok) {
        throw new Error(json.error)
    }

    return json
}