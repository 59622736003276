import { Strategy } from "../../types"

export interface Person {
    gender: string
    firstName: string
    middleName: string
    lastName: string
    ssn: number
    dob: string
    email: string
    phone: string
    address: string
    address_2: string
    city: string
    state: string
    zip: string
    birthdate: Date
}

export interface Beneficiary extends Person {
    id: string
    type: BeneficaryType
    status: BeneficiaryStatus
    contingency: BeneficiaryContingency
    relationship: BeneficiaryRelationship
    percentage: number
}

export enum BeneficaryType {
    INDIVIDUAL = "INDIVIDUAL",
    TRUST = "TRUST",
    CORPORATION = "CORPORATION"
}

export enum BeneficiaryStatus {
    PRIMARY = "PRIMARY",
    CONTINGENT = "CONTINGENT"
}

export enum BeneficiaryContingency {
    PER_STIRPES = "PER_STIRPES",
    PER_CAPITA = "PER_CAPITA",
}

export enum BeneficiaryRelationship {
    AUNT = "AUNT",
    BROTHER_IN_LAW = "BROTHER_IN_LAW",
    CHILD = "CHILD",
    COUSIN = "COUSIN",
    DAUGHTER_IN_LAW = "DAUGHTER_IN_LAW",
    DOMESTIC_PARTNER = "DOMESTIC_PARTNER",
    EX_SPOUSE = "EX_SPOUSE",
    FATHER_IN_LAW = "FATHER_IN_LAW",
    FIANCE = "FIANCE",
    FRIEND = "FRIEND",
    GODCHILD = "GODCHILD",
    GRANDCHILD = "GRANDCHILD",
    GRANDPARENT = "GRANDPARENT",
    MOTHER_IN_LAW = "MOTHER_IN_LAW",
    NEPHEW = "NEPHEW",
    NIECE = "NIECE",
    PARENT = "PARENT",
    SIBLING = "SIBLING",
    SISTER_IN_LAW = "SISTER_IN_LAW",
    SON_IN_LAW = "SON_IN_LAW",
    SPOUSE = "SPOUSE",
    STEP_PARENT = "STEP_PARENT",
    STEP_SIBLING = "STEP_SIBLING",
    STEP_CHILD = "STEP_CHILD",
    SURVIVING_OWNER = "SURVIVING_OWNER",
    SURVIVING_SPOUSE = "SURVIVING_SPOUSE",
    UNCLE = "UNCLE",
}

export interface Identification {
    formOfIdentification: string
    idNumber: string
    issuingState: string
    expirationDate: string
}

export type AnnuitantType = 'self' | 'spouse-joint' | 'other'

export type OwnerType = 'individual' | 'joint'

export type FundingSourceType = 'qualified' | 'unqualified'

export type TaxFilingStatus = 'single' | 'married-joint' | 'married-separate' | 'head-household' | 'widow'

export type FundsLocation = "checking" | "savings" | "brokerage-account" | "paper-check" | "401k" | "403b" | "ira" | "roth-ira" | "tsp" | "tsa"

export interface FundingSource {
    id: string
    fundsLocation: FundsLocation
    institution: string
    amount: number
    routingNumber?: string
    accountNumber?: string
}


export interface HouseholdInvestmentDetail {
    value: number
    liquidating: boolean
}

export interface User {
    email: string
    id: string
    auth0_id: string
    first_name: string
    last_name: string
    phone: string
    application: string
    state: string
    zip: string
    birthdate: string
    age: number
    strategy_name: string
    funding_type: 'employer' | 'ira' | 'after-tax'
    funding_amount: number
    rollover_call: string
    status: UserStatus
    created_at: string
    updated_at: string
    first_touch_attribution: string
    last_touch_attribution: string
    referring_partner: string
    self_attribution: string
}

export type UserStatus = 'signed_up_paywall' | 'signed_up' | 'called' | 'meeting_booked' | 'application_started' | 'application_submitted' | 'delivery_confirmed' | 'dead'

export interface ApplicationStore {
    loading: boolean
    user: User
    applicationId: string
    errorMessage: string
    submittingApplication: boolean
    applicationSubmitted: boolean
    submissionErrors: string[]
    investmentAmount: number
    monthlyContributions: number
    personalInformation: Person & Identification
    fundingSourceType: FundingSourceType
    fundingSources: FundingSource[]
    ownerType: OwnerType
    owner: Person
    jointOwner: Person & Identification & {
        sameAddress: boolean
    },
    annuitantType: AnnuitantType
    otherAnnuitant: Person
    beneficiaries: Beneficiary[]
    existingPolicy: boolean
    replacingPolicy: boolean
    replacingPolicyData: {
        company: string,
        contractNumber: string
    },
    householdIncome: number
    householdExpenses: number
    householdLiabilities: number
    householdInvestments: string[]
    taxFilingStatus: TaxFilingStatus
    householdInvestmentDetails: {
        'stocks-bonds': HouseholdInvestmentDetail
        'annuities': HouseholdInvestmentDetail
        'mutual-funds': HouseholdInvestmentDetail
        'cds': HouseholdInvestmentDetail
        'money-market': HouseholdInvestmentDetail
        'checking-savings': HouseholdInvestmentDetail
        'pension-401k': HouseholdInvestmentDetail
        'life-insurance': HouseholdInvestmentDetail
        'real-estate': HouseholdInvestmentDetail
        'reverse-mortgage': HouseholdInvestmentDetail
        'limited-partnership': HouseholdInvestmentDetail
    },
    engagementLetterAgreed: boolean
    savingApplication: boolean
    attestations: {
        financialGoals: boolean
        previousContractsWithProducer: boolean
        governmentBenefits: boolean
        reverseMortgage: boolean
        sufficientFunds: boolean
        surrenderCharges: boolean
        futureIncomeChange: boolean
        exchanges: boolean
    },
    strategy: Strategy
    wereYouReferred: boolean
    referrer: string
    referringPartner: {
        value: string
    }
    applicationExpired: boolean
    dependents: boolean
    dependent1Age: number
    dependent2Age: number
    dependent3Age: number
}

export interface Step {
    name: string
    url: string
    complete: () => boolean
}