<template>
    <QuestionnaireSection title="Let's complete your rollover." :loading="store.submitting" :enableEnterKey="false"
        helperText="Here's what to expect." class="max-w-lg" continueText="Choose a meeting time" @click="step = 2"
        v-if="step == 1">
        <div class="mt-4 mb-8">
            <div class="grid gap-6 mb-8">
                <div v-for="step, i in steps" class="flex items-start gap-4">
                    <div
                        class="font-semibold text-primary-600 border border-primary-200 rounded-full w-10 h-10 flex justify-center items-center shrink-0">
                        {{ i + 1
                        }}</div>
                    <p>
                        <strong>{{ step.title }}</strong> {{ step.description }}
                    </p>
                </div>
            </div>
        </div>
    </QuestionnaireSection>
    <div v-if="step == 2" class="max-w-4xl mx-auto pb-8 text-center">
        <div class="mb-8">
            <div v-if="!rolloverBooked">
                <h1 class="text-3xl font-serif font-bold mb-2">Book a rollover meeting</h1>
                <p class="text-gray-600 mb-4 max-w-xl mx-auto">Choose
                    a time to
                    meet with your rollover specialist to complete the rollover. This ensures every rollover is
                    completed accurately. We do not accept self-directed rollovers.</p>
            </div>
            <div v-else>
                <h1 class="mt-4 text-3xl font-serif font-bold mb-2">Booking confirmed!</h1>
                <p class="text-gray-600 mb-4">Now that we have a meeting booked, it's time to fill out your application.
                    You'll need to have this application complete before your meeting on {{ meetingDate }}.</p>
                <PrimaryButton size="lg" @click="router.push('/application')">Start your application now
                </PrimaryButton>
            </div>
        </div>
        <GHLCalendar :calendarId="CalendarId.RolloverCall" @bookingComplete="handleBookingSuccessful" />
    </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, defineProps } from 'vue';
import QuestionnaireSection from '@/components/questionnaireSections/QuestionnaireSection.vue'
import { updateUser } from '@/clients/user';
import { meetingBookedEvents } from '@/eventTagging';
import { useApplicationStore } from '@/stores/application';
import { PrimaryButton } from '@reviseannuity/randolph-ui';
import { useRouter } from 'vue-router';
import { useRegistrationStore } from '@/stores/registration';
import GHLCalendar from '@/components/GHLCalendar.vue';
import { CalendarId } from '@/data/calendarIds';
import { useAuth0 } from '@auth0/auth0-vue';

const router = useRouter()
const applicationStore = useApplicationStore()
const store = useRegistrationStore()
const meetingDate = ref()
const { user } = useAuth0()

defineEmits(['continue'])
const step = ref(applicationStore.user.rollover_call ? 2 : 1)
const rolloverBooked = ref(false)

const steps = [
    { title: "Choose a meeting time.", description: "On the next screen, you'll set a meeting with your rollover specialist. Together, you'll call your plan servicer and start the rollover process." },
    { title: "Complete your online application.", description: "After you book your call, you'll be directed to our online application. This must be completed before your rollover meeting." },
    { title: "Review and sign.", description: "A licensed representive will review your application and share any required signature links." },
]

const handleBookingSuccessful = async (data) => {
    meetingDate.value = new Date(data.appointment.only_start_date).toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })

    localStorage.setItem('registrationComplete', 'true');
    rolloverBooked.value = true

    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })

    await updateUser(applicationStore.user.auth0_id, {
        rollover_meeting: meetingDate.value,
        status: 'meeting_booked'
    })

    meetingBookedEvents(
        meetingDate.value,
        user.value.sub,
        store?.investmentAmount,
        store.email,
        store.firstName,
        store.lastName,
        store.phone,
        store.strategyName,
    )
}
</script>