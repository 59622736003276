import { generateId } from "../../utils";
import { Person, Beneficiary, Identification, BeneficaryType, BeneficiaryStatus, BeneficiaryContingency, BeneficiaryRelationship, FundingSource } from "./types";

export const defaultPerson: Person = {
    gender: 'male',
    firstName: '',
    middleName: '',
    lastName: '',
    ssn: null,
    dob: '',
    email: '',
    phone: '',
    address: '',
    address_2: '',
    city: '',
    state: 'Alabama',
    zip: '',
    birthdate: null,
}

export const defaultIdentification: Identification = {
    formOfIdentification: 'drivers-license',
    idNumber: '',
    issuingState: 'Alabama',
    expirationDate: '',
}

export const defaultBeneficiary: Beneficiary = {
    id: generateId(),
    type: BeneficaryType.INDIVIDUAL,
    status: BeneficiaryStatus.PRIMARY,
    contingency: BeneficiaryContingency.PER_CAPITA,
    ...defaultPerson,
    relationship: BeneficiaryRelationship.CHILD,
    percentage: 100
}

export const defaultFundingSource: FundingSource = {
    id: generateId(),
    fundsLocation: null,
    institution: '',
    amount: 0,
}