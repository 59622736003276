<template>
    <QuestionnaireSection title="How much are you looking to invest?"
        helperText="This will help us estimate your growth or future income. You can set an exact amount later."
        @click="nextStep" :disableNext="!store.validateStep(QuestionnaireStep.INVESTMENT_AMOUNT)"
        buttonId="investmentAmountContinue">
        <div class="mb-8">
            <MoneyField name="investmentAmount" :value="store.investmentAmount"
                @input="v => store.setField('investmentAmount', v)" />
        </div>
        <div class="bg-primary-100 text-primary-950 text-sm px-4 py-5 rounded-lg mb-8" v-if="showReserveInfo">
            <img :src="logo" class="h-4 mb-3" />
            You are eligible for Revise Reserve Services, a bespoke suite of offerings meticulously designed to
            complement and enhance the financial journey of our most distinguished clients.
        </div>
        <template v-slot:footer>
            <div class="mt-2">
                <SecondaryButton block @click="skip">I'm not sure
                </SecondaryButton>
            </div>
        </template>
    </QuestionnaireSection>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import QuestionnaireSection from './QuestionnaireSection.vue';
import { useQuestionnaireStore } from '@/stores/questionnaire';
import { event } from 'vue-gtag';
import { useRouter } from 'vue-router';
import { MoneyField, SecondaryButton } from '@reviseannuity/randolph-ui';
import logo from '@/assets/revise_reserve_logo_blue.svg';
import { QuestionnaireStep } from '@/stores/questionnaire/types';

const router = useRouter()
const store = useQuestionnaireStore();

const showReserveInfo = computed(() => store.investmentAmount >= 100000)

const nextStep = (): void => {
    event('investment_amount_selected', {
        investment_amount: store.investmentAmount
    })

    router.push(store.nextStep())
}

const skip = (): void => {
    store.setField('investmentAmount', 0)
    router.push(store.nextStep())
}

onMounted(() => {
    store.setStep(QuestionnaireStep.INVESTMENT_AMOUNT)
    event('page_view', {
        page_title: 'Master App Questionnaire - Investment Amount',
        page_location: window.location.href,
        page_path: window.location.pathname
    })
})
</script>