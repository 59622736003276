import { formatMoney, unqualifiedFundLocations } from "../../utils"
import { HouseholdInvestmentDetail, Step } from "./types"

export default {
    steps(): Step[] {
        return [
            {
                name: 'Welcome',
                url: 'welcome',
                complete: () => true
            },

            {
                name: 'Personal Information',
                url: 'personal-information',
                complete: () => (
                    this.personalInformation.gender &&
                    this.personalInformation.firstName &&
                    this.personalInformation.lastName &&
                    this.personalInformation.email &&
                    this.personalInformation.phone &&
                    this.personalInformation.address &&
                    this.personalInformation.city &&
                    this.personalInformation.state &&
                    this.personalInformation.zip &&
                    this.personalInformation.birthdate
                )
            },
            {
                name: 'Verification',
                url: 'verification',
                complete: () => (this.personalInformation.ssn &&
                    this.personalInformation.formOfIdentification &&
                    this.personalInformation.idNumber &&
                    this.personalInformation.issuingState &&
                    this.personalInformation.expirationDate)
            },
            {
                name: 'Funding Sources',
                url: 'funding-sources',
                complete: () => {
                    return this.fundingSources.length &&
                        this.fundingSources.every(source => source.institution && source.amount && (Object.keys(unqualifiedFundLocations).includes(source.fundsLocation) ? source.accountNumber && source.routingNumber : true)) &&
                        this.totalFunded == this.investmentAmount
                }
            },
            {
                name: 'Ownership',
                url: 'ownership',
                complete: () => {
                    if (this.ownerType == 'individual') {
                        return this.owner.gender && this.owner.firstName && this.owner.lastName && this.owner.ssn && this.owner.address && this.owner.city && this.owner.state && this.owner.zip
                    }

                    if (this.ownerType == 'joint') {
                        return this.owner.gender && this.owner.firstName && this.owner.lastName && this.owner.ssn && this.owner.address && this.owner.city && this.owner.state && this.owner.zip &&
                            this.jointOwner.gender && this.jointOwner.firstName && this.jointOwner.lastName && this.jointOwner.ssn && this.jointOwner.dob && this.jointOwner.idNumber && this.jointOwner.expirationDate && (this.jointOwner.sameAddress ? true : this.jointOwner.address && this.jointOwner.city && this.jointOwner.state && this.jointOwner.zip)
                    }
                }
            },
            {
                name: 'Annuitant',
                url: 'annuitant',
                complete: () => {
                    if (this.annuitantType == 'self') {
                        return this.owner.gender && this.owner.firstName && this.owner.lastName && this.owner.ssn && this.owner.address && this.owner.city && this.owner.state && this.owner.zip
                    }

                    if (this.annuitantType == 'spouse-joint') {
                        return this.owner.gender && this.owner.firstName && this.owner.lastName && this.owner.ssn && this.owner.address && this.owner.city && this.owner.state && this.owner.zip &&
                            this.jointOwner.gender && this.jointOwner.firstName && this.jointOwner.lastName && this.jointOwner.ssn && this.jointOwner.dob && this.jointOwner.idNumber && this.jointOwner.expirationDate && (this.jointOwner.sameAddress ? true : this.jointOwner.address && this.jointOwner.city && this.jointOwner.state && this.jointOwner.zip)
                    }

                    if (this.annuitantType == 'other') {
                        return this.otherAnnuitant.firstName && this.otherAnnuitant.lastName && this.otherAnnuitant.ssn && this.otherAnnuitant.address && this.otherAnnuitant.city && this.otherAnnuitant.state && this.otherAnnuitant.zip
                    }
                }
            },
            {
                name: 'Beneficiaries',
                url: 'beneficiaries',
                complete: () => {
                    return this.beneficiaries.length &&
                        this.beneficiaries.every(beneficiary => (
                            beneficiary.firstName &&
                            beneficiary.lastName &&
                            beneficiary.address &&
                            beneficiary.city &&
                            beneficiary.state &&
                            beneficiary.zip &&
                            beneficiary.dob &&
                            beneficiary.relationship
                        ))
                }
            },
            {
                name: 'Existing Policies',
                url: 'existing-policies',
                complete: () => {
                    if (this.existingPolicy === null) return false

                    if (this.existingPolicy && this.replacingPolicy) {
                        return this.replacingPolicyData.company && this.replacingPolicyData.contractNumber
                    } else {
                        return true
                    }
                }
            },
            {
                name: 'Household Information',
                url: 'household-information',
                complete: () => {
                    return (
                        this.householdIncome > 0 &&
                        this.householdExpenses > 0 &&
                        this.householdInvestments.length > 0 &&
                        this.householdInvestments.every(investment => this.householdInvestmentDetails[investment]?.value) &&
                        Object.values(this.householdInvestmentDetails).some((detail: HouseholdInvestmentDetail) => detail.liquidating) &&
                        (this.dependents ? (this.dependent1Age || this.dependent2Age || this.dependent3Age) : true)
                    )
                }
            },
            {
                name: 'Suitability Attestation',
                url: 'suitability-attestation',
                complete: () => (
                    this.attestations.financialGoals !== null &&
                    this.attestations.previousContractsWithProducer !== null &&
                    this.attestations.governmentBenefits !== null &&
                    this.attestations.reverseMortgage !== null &&
                    this.attestations.sufficientFunds !== null &&
                    this.attestations.surrenderCharges !== null &&
                    this.attestations.futureIncomeChange !== null &&
                    this.attestations.exchanges !== null
                )
            }
        ]
    },
    formattedInvestmentAmount(): string {
        return formatMoney(this.investmentAmount)
    },
    progress(): number {
        return this.steps.filter(step => step.complete()).length / this.steps.length * 100
    },
    totalFunded(): number {
        return this.fundingSources.reduce((a, b) => a + Number(b.amount), 0)
    },
    beneficiariesPercentage(): number {
        return this.beneficiaries.reduce((a, b) => a + Number(b.percentage), 0)
    },
    fundingIsQualified(): boolean {
        return this.fundingSources.some(source => [].includes(source.fundsLocation))
    },
    readyForSubmission(): boolean {
        return this.steps.every(step => step.complete())
    }
}