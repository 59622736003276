<template>
    <div class="border border-gray-300 px-4 py-4 sm:py-6 rounded-lg w-full sm:max-w-[250px]">
        <h3 class="text-primary-800 text-sm uppercase font-semibold text-center mb-4 sm:mb-6">What to expect</h3>
        <ul class="text-sm text-primary-950">
            <li class="relative pl-6 mb-2 sm:mb-6 last:mb-0" v-for="check in checks">
                <CheckBadgeIcon class="text-primary-600 w-4 h-4 inline-block absolute left-0 top-1" />{{ check }}
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { CheckBadgeIcon } from '@heroicons/vue/16/solid';

const checks = [
    "Get instant annuity matches you can discuss with our experts",
    "Browse & learn 100% obligation free",
    "Backed by top-rated insurance carriers"
]
</script>