import { Strategy } from "../../types"

export enum QuestionnaireStep {
    ZIP = 'zip',
    BIRTHDATE = 'birthdate',
    RISK_TOLERANCE = 'risk-tolerance',
    STRATEGY_LIST = 'strategy',
    STRATEGY_DETAIL = 'strategy-details',
    INVESTMENT_AMOUNT = 'investment-amount',
    FOCUS = 'focus',
    TIMELINE = 'timeline',
    IMMEDIATE_INCOME = 'immediate-income',
    RETIREMENT_AGE = 'retirement-age',
}

export enum FocusOption {
    GROWTH = 'growth',
    INCOME = 'income'
}

export enum SortOption {
    BEST_MATCH = 'bestMatch',
    HIGHEST_RATE = 'highestRate',
    LOWEST_VOLATILITY = 'lowestVolatility',
    MOST_REVIEWS = 'mostReviews',
    HIGHEST_RATING = 'highestRating'
}

export enum ProductTypeOption {
    GROWTH = 'growth',
    INCOME = 'income',
    BONUS = 'bonus'
}

export enum UseCaseOption {
    '401K' = '401k',
    ANNUITY_REPLACEMENT = 'annuity replacement',
    GUARANTEED_INCOME = 'guaranteed income',
    YIELD_ENHANCEMENT = 'yield enhancement',
    PORTFOLIO_HEDGE = 'portfolio hedge'
}

export enum ReturnsOption {
    FIXED_INDEXED = "Fixed Indexed",
    FIXED = "Fixed",
    MYGA = "MYGA"
}

export enum TimelineOption {
    IMMEDIATELY = 'immediately',
    FUTURE = 'future'
}

export interface QuestionnaireStore {
    steps: {
        [key in QuestionnaireStep]: {
            validate: (state?: QuestionnaireStore) => boolean
            next: (state?: QuestionnaireStore) => QuestionnaireStep | null
        }
    }
    activeStep: QuestionnaireStep
    previousStep: QuestionnaireStep | null
    state: string
    zipCode: string
    birthdate: Date
    investmentAmount: number
    retirementAge: number
    riskTolerance: number
    fundingSource: string
    strategies: Strategy[]
    loadingStrategies: boolean
    minVolatility: number
    maxVolatility: number
    loading: boolean
    strategyLoadingError: string
    age: number
    focus: FocusOption
    sortedResults: Strategy[]
    sortBy: SortOption
    minimumRating: number
    productType: ProductTypeOption[]
    useCase: UseCaseOption
    returns: ReturnsOption[]
    showBirthdateChecks: boolean
    timeline: TimelineOption
    enableExitPopup: boolean
    dontShowPopupAgain: boolean
    showExitPopup: boolean
    showPaywall: boolean
    strategy?: Strategy
}

export interface GHLCalendarData {
    firstName: string
    lastName: string
    phone: string
    email: string
    birthdate: Date
    age: string
    state: string
    zip: string
    investmentAmount: string
    fundingSource: string
    meetingDate: Date
}