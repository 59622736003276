<template>
    <QuestionnaireSection title="When do you need to start taking income after your initial investment?"
        class="max-w-lg" @click="handleContinue" :disableNext="!store.validateStep(QuestionnaireStep.TIMELINE)">
        <div class="grid gap-4 mb-6">
            <button v-for="option of options" @click="selectOption(option.value)"
                class="text-left rounded-lg border-2 p-6" :class="[store.timeline == option.value ? 'border-primary-600 bg-primary-50 focus:outline-none' :
                    'border-gray-300 bg-white']">
                <div class="-mt-2 mb-1 flex gap-1">
                    <StrategyTypeTag v-for="pill of option.pills" :type="pill" />
                </div>
                <h3 class="text-xl sm:text-2xl font-bold font-serif tracking-tight mb-2"
                    :class="store.timeline == option.value ? 'text-primary-600' : 'text-gray-800'">{{ option.heading }}
                </h3>
                <p class="text-normal leading-tight"
                    :class="store.timeline == option.value ? 'text-primary-900' : 'text-gray-800'">{{
                        option.body
                    }}</p>
            </button>
        </div>
    </QuestionnaireSection>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import QuestionnaireSection from './QuestionnaireSection.vue';
import { useQuestionnaireStore } from '@/stores/questionnaire';
import { useRouter } from 'vue-router';
import StrategyTypeTag from '@/components/StrategyTypeTag.vue';
import { QuestionnaireStep, TimelineOption } from '@/stores/questionnaire/types';
import { ProductType } from '@/types';

const router = useRouter()
const store = useQuestionnaireStore();

const options = ref([
    {
        pills: [ProductType.SPIA],
        value: TimelineOption.IMMEDIATELY,
        heading: 'Immediately or within 1 year',
        body: "Convert your existing nest egg into a guaranteed income stream for life, starting as soon as a month from now."
        // body: "A Single Premium Immediate Annuity (SPIA) is opened with a one-time lump sum payment in exchange for a guaranteed, immediate or near-term income stream.",
    },
    {
        pills: [ProductType.DIA, ProductType.FIA],
        value: TimelineOption.FUTURE,
        heading: 'After 1 year or more',
        body: "By investing now and waiting to take income, your money grows first, allowing you to receive a potentially larger lifetime income at a future date.",
    },
])

onMounted(() => {
    store.setStep(QuestionnaireStep.TIMELINE)
})

const selectOption = (value) => {
    store.setField('timeline', value)
}

const handleContinue = () => {
    router.push(store.nextStep())
}
</script>