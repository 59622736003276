<template>
    <QuestionnaireSection title="How much would you like to invest?"
        helperText="This is known as your first premium payment and it opens your annuity contract."
        :loading="store.submitting" :enableEnterKey="false" class="max-w-lg" continueText="Continue"
        @click="() => showConfirmationModal = true"
        :disableNext="store.investmentAmount < 20000 || store.investmentAmount > 2000000">
        <div class="pb-8">
            <MoneyField :value="store.investmentAmount" @input="v => store.setField('investmentAmount', v)" />
            <ErrorMessage v-if="store.investmentAmount && store.investmentAmount < 20000">$20,000 is the minimum amount
                to open an
                annuity contract.</ErrorMessage>
            <ErrorMessage v-if="store.investmentAmount && store.investmentAmount > 2000000">$2,000,000 is the maximum
                amount
                to open an
                annuity contract.</ErrorMessage>
        </div>
    </QuestionnaireSection>
    <Modal :open="showConfirmationModal"
        :title="`You are about to apply for an annuity in the amount of ${formatMoney(store.investmentAmount)}.`"
        body="This is also known as the first premium payment. If your policy permits additional contributions, you can make them through your online dashboard after issuance of the policy."
        @close="() => showConfirmationModal = false" button1="Cancel" button2="Continue"
        @click:button1="() => showConfirmationModal = false" @click:button2="nextStep"
        :button2Loading="store.submitting">
    </Modal>
    <div class="w-full text-center">
        <button class="px-4 py-2 text-sm underline" @click="helpModalOpen = true">I need some help</button>
    </div>
    <CalendarBookingModal :open="helpModalOpen" @close="helpModalOpen = false" :calendarId="CalendarId.Intro" />
</template>

<script setup lang="ts">
import QuestionnaireSection from '@/components/questionnaireSections/QuestionnaireSection.vue';
import { MoneyField, ErrorMessage } from '@reviseannuity/randolph-ui'
import { ref, onMounted } from 'vue';
import { useRegistrationStore } from '@/stores/registration';
import { event } from 'vue-gtag'
import { updateUser } from '@/clients/user';
import { formatMoney } from '@/utils';
import Modal from '@/components/Modal.vue';
import { CalendarId } from '@/data/calendarIds';
import { useRouter } from 'vue-router';

const store = useRegistrationStore()
const showConfirmationModal = ref(false)
const helpModalOpen = ref(false)
const router = useRouter()

onMounted(() => {
    event('page_view', {
        page_title: 'Master App Registration - 401k/IRA Amount',
        page_location: window.location.href,
        page_path: window.location.pathname
    })
})

const nextStep = async () => {
    await updateUser(store.auth0Id, {
        funding_amount: Number(store.investmentAmount)
    })

    router.push({ name: 'application' })
}
</script>