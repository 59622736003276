import { User } from '@/stores/application/types'
import { call } from './client'


interface ApplicationInput { }

interface ApplicationResponse {
    status: string
}

export const createUser = async (userInput: Partial<User>): Promise<User> => {
    return await call('POST', '/user', userInput)
}

export const updateUser = async (auth0Id: string, userInput: Partial<User>): Promise<User> => {
    if (!auth0Id) {
        throw new Error('auth0Id is required')
    }
    return await call('PUT', `/user/${encodeURI(auth0Id)}`, userInput)
}

export const getUserByAuth0Id = async (auth0Id: string): Promise<User> => {
    if (!auth0Id) {
        throw new Error('auth0Id is required')
    }
    return await call('GET', `/user/${encodeURI(auth0Id)}`)
}

export const getApplicationForUser = async (auth0Id: string): Promise<ApplicationResponse> => {
    if (!auth0Id) {
        throw new Error('auth0Id is required')
    }
    return await call('GET', `/user/${encodeURI(auth0Id)}/application`)
}

export const createApplicationForUser = async (auth0Id: string): Promise<ApplicationResponse> => {
    if (!auth0Id) {
        throw new Error('auth0Id is required')
    }
    return await call('POST', `/user/${encodeURI(auth0Id)}/application`)
}

export const updateApplicationForUser = async (auth0Id: string, applicationInput: ApplicationInput): Promise<ApplicationResponse> => {
    if (!auth0Id) {
        throw new Error('auth0Id is required')
    }
    return await call('PUT', `/user/${encodeURI(auth0Id)}/application`, applicationInput, {
        keepalive: true
    })
}