<template>
    <SectionContainer title="Beneficiaries" :button-disabled="!store.steps[stepIndex].complete()" @nextStep="() => {
        event('beneficiaries_complete')
        nextStep()
    }" @prevStep="prevStep" helperText="The beneficiaries are the people who will receive the annuity payments if the annuitant passes
                away.">
        <div class="space-y-4">
            <BeneficiaryInput v-for="beneficiary, i in store.beneficiaries" :id="beneficiary.id" :key="beneficiary.id"
                @remove="store.removeBeneficiary(beneficiary.id)" :value="beneficiary" :number="i + 1"
                @update="({ key, value }) => store.updateBeneficiary(beneficiary.id, key, value)" />
            <div class="flex flex-col sm:flex-row gap-4 justify-end items-start">
                <SecondaryButton @click="store.addBeneficiary">
                    <div class="flex items-center">
                        <PlusIcon class="h-4 w-4 inline-block mr-1" />
                        Add Beneficiary
                    </div>
                </SecondaryButton>
            </div>
        </div>
    </SectionContainer>
</template>

<script setup lang="ts">
import BeneficiaryInput from '@/components/BeneficiaryInput.vue'
import SectionContainer from '@/components/SectionContainer.vue';
import { SecondaryButton } from '@reviseannuity/randolph-ui';
import { PlusIcon } from '@heroicons/vue/24/outline';
import { useApplicationStore } from '@/stores/application';
import { useStep } from '@/composables/useStep';
import { event } from 'vue-gtag';

const store = useApplicationStore();
const { nextStep, prevStep, stepIndex } = useStep(store)
</script>