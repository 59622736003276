<template>
    <SDPWrapper extrathin>
        <div class="grid grid-cols-2 gap-4 sm:gap-0 sm:grid-cols-4 sm:-ml-8 sm:-mr-8 ">
            <div class="text-center flex flex-col justify-center items-stretch sm:border-r" v-for="block of dataBlocks">
                <strong class="block text-[0.65em] uppercase text-gray-500 mb-2">{{ block.title }}
                    <Tooltip :text="block.tooltip" v-if="block.tooltip" />
                </strong>
                <span class="text-2xl font-serif font-bold" v-html="block.value" />
            </div>
        </div>
    </SDPWrapper>
</template>

<script setup lang="ts">
import { formatMoney, renderPercentage, renderPercentageRange } from '@/utils';
import { Tooltip } from '@reviseannuity/randolph-ui';
import SDPWrapper from './SDPWrapper.vue';
import { useQuestionnaireStore } from '@/stores/questionnaire';
import { Strategy } from '@/types';

const props = defineProps<{
    strategy: Strategy
}>()

let estimatedIncome
if (['income', 'hybrid'].includes(props.strategy.type)) {
    const store = useQuestionnaireStore()
    estimatedIncome = store.strategies.find(s => s.id === props.strategy.id)?.estimatedIncome
}

let dataBlocks

const tooltips = {
    incomeGrowth: `This is the rate of return associated with the capital pool or “benefit base” you draw from if you elect to take income from your annuity. It is often a multiple of the rate of return associated with the market-linked return of your annuity, which provides a larger base of capital from which to draw retirement income. With better market performance, you can expect more income upon income election.`,
    productFees: `These are the fees assessed by the insurance carrier for providing capital protection and guaranteed retirement income for various income-based annuity products. They are assessed by the carrier and are not paid to Revise.`,
    surrenderPeriodIncome: `The surrender period does not apply to electing a guaranteed income benefit from your annuity, but rather refers to discretionary cash withdrawals. The surrender period is the length of time you are required to hold your annuity before you can withdraw funds from the accumulation value in excess of the penalty-free withdrawal amount (typically 5-10%) without incurring surrender charges. The surrender period gives the insurer time to invest your money and provides an opportunity for a competitive return and capital protection.`,
    surrenderPeriodGrowth: `The surrender period is the length of time you are required to hold your annuity before you can withdraw funds without incurring surrender charges. During this period, if you withdraw more than the penalty-free amount (usually up to 10% of the accumulation value annually), you may be subject to surrender charges, which are fees assessed on the amount withdrawn. These charges typically decrease over time and reach 0% after a certain number of years.`,
    estIncome: `This is an estimate the annual income payment you would receive for life from the annuity when/if you elect income ("guaranteed withdrawal benefit" or "guaranteed lifetime income") considering your age, payout age, and investment amount using recent historical data for indexed products. It is for educational purposes only, may vary with market conditions, issuing carrier, state. This does not serve as a guarantee or official carrier illustration until speaking with one of our licensed annuity agents who will share the most up-to-date information with you. You may elect monthly payment periodicity from your policy, annual is only displayed for simplicity.`,
    histRate: `Estimated historical annual effective rate net of fees credited from index or blended strategy. The results obtained from back-testing. As future market returns may vary, this rate should not be considered indicative of the actual results that might be obtained from an investment in the index or strategy. The actual performance of the Index may vary significantly from the results obtained from back-testing.`,
    freeWithdrawal: `Once per year, you may take a penalty-free withdrawal (also known as a penalty-free partial surrender), without surrender charges, of up to 10% of your accumulation value. Always consult with your tax advisor first before making early withdrawals.`,
    strategyFee: `A strategy fee is an annual percentage fee deducted from your accumulation value in exchange for higher participation in the underlying index.`,
}

if (props.strategy.type === 'growth') {
    dataBlocks = [
        {
            title: "Hist. Rate",
            tooltip: tooltips.histRate,
            value: `<span class="text-sm">${renderPercentageRange(props.strategy.historicalRate)}</span>`
        },
        {
            title: "Free Withdrawal",
            tooltip: tooltips.freeWithdrawal,
            value: renderPercentage(props.strategy.underlyingProduct.liquidityProvision)
        },
        {
            title: "Surrender Period",
            tooltip: tooltips.surrenderPeriodGrowth,
            value: `${props.strategy.surrenderPeriod} <small>yrs</small>`
        },
        {
            title: "Strategy Fee",
            tooltip: tooltips.strategyFee,
            value: renderPercentage(props.strategy.strategyFee)
        },
    ]
}

if (props.strategy.type === 'hybrid') {
    dataBlocks = [
        {
            tooltip: tooltips.estIncome,
            title: "Your Est. Income",
            value: estimatedIncome ? `${formatMoney(estimatedIncome)}` : '-'
        },
        {
            tooltip: tooltips.incomeGrowth,
            title: "Income Growth",
            value: `<span class="text-sm">${renderPercentageRange(props.strategy.incomeGrowthRate)}</span>`
        },
        {
            tooltip: tooltips.surrenderPeriodIncome,
            title: "Surrender Period",
            value: `${props.strategy.surrenderPeriod} <small>yrs</small>`
        },
        {
            tooltip: tooltips.productFees,
            title: "Product Fees",
            value: renderPercentage(props.strategy.productFee)
        }
    ]
}

if (props.strategy.type === 'income') {
    dataBlocks = [
        {
            tooltip: tooltips.estIncome,
            title: "Est. Income",
            value: estimatedIncome ? `${formatMoney(estimatedIncome)}` : '-'
        },
        {
            tooltip: tooltips.incomeGrowth,
            title: "Income Growth",
            value: renderPercentageRange(props.strategy.assetGrowthRate)
        },
        {
            tooltip: tooltips.surrenderPeriodIncome,
            title: "Surrender Period",
            value: `${props.strategy.surrenderPeriod} <small>yrs</small>`
        },
        {
            tooltip: tooltips.productFees,
            title: "Product Fees",
            value: renderPercentage(props.strategy.productFee)
        }
    ]
}

if (props.strategy.type === 'myga') {
    dataBlocks = [
        { title: "Guaranteed Rate", value: `<span class="text-sm">${renderPercentageRange(props.strategy.historicalRate)}</span>` },
        {
            title: "Free Withdrawal", value: renderPercentage(props.strategy.underlyingProduct.liquidityProvision),
            tooltip: tooltips.freeWithdrawal
        },
        { title: "Guarantee Period", value: `${props.strategy.surrenderPeriod} <small>yrs</small>` },
        {
            title: "Strategy Fee",
            value: renderPercentage(props.strategy.strategyFee)
        },
    ]
}

if (props.strategy.type === 'bonus') {
    dataBlocks = [
        { title: "Historical rate", value: renderPercentageRange(props.strategy.historicalRate) },
        { title: "Bonus", value: renderPercentage(props.strategy.bonus) },
        { title: "Surrender Period", value: `${props.strategy.surrenderPeriod} <small>yrs</small>` },
        {
            title: "Product Fee",
            value: renderPercentage(props.strategy.productFee)
        }
    ]
}
</script>