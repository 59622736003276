<template>
    <SDPWrapper>
        <SDPTitle>Hypothetical annuity illustration.</SDPTitle>
        <SDPDescription>Here's a convenient way to estimate your annuity value and future income payments at various
            ages.</SDPDescription>
        <div v-if="selectedRow" class="text-xl font-serif font-bold mb-8 text-center">Based on historical data, if you
            elect income at <span class="text-primary-600">{{
                rows[selectedRow]?.age }}</span>, you could
            receive <span class="text-primary-600">{{
                formatMoney(rows[selectedRow]?.lifetimePayment) }}</span> annually for life. Your beneficiaries would
            receive
            five
            annual payments of <span class="text-primary-600">{{ formatMoney(rows[selectedRow]?.beneficiaryPayout)
                }}</span> upon your death.</div>
        <div class="grid grid-cols-3 lg:grid-cols-6 gap-4 mb-4">
            <TextField label="Current Age" type="number" :min="40" :max="79" :value="currentAge" @input="v => {
                if (parseInt(v) < 40) return currentAge = 40
                if (parseInt(v) > 79) return currentAge = 79
                return currentAge = parseInt(v)
            }" />
            <div class="col-span-2 lg:col-span-3">
                <MoneyField label="Investment Amount" :value="store.investmentAmount"
                    @input="v => store.setField('investmentAmount', parseInt(v))" />
            </div>
            <div class="col-span-2">
                <RadioField label="Annuitant Type" :options="annuitantTypeOptions" :value="annuitantType"
                    @input="v => annuitantType = v" :inline="true" />
            </div>
        </div>
        <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                    <tr>
                        <th class=" px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Year</th>
                        <th class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Age</th>
                        <th class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Accumulation value</th>
                        <th class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Est. annual income*
                        </th>
                    </tr>
                </thead>
                <tbody class="divide-y">
                    <tr v-for="row, i of rows" @click="selectedRow = i" class="cursor-pointer "
                        :class="[selectedRow == i ? 'bg-primary-600 hover:bg-primary-700' : 'hover:bg-primary-50']">
                        <td :class="[selectedRow == i && 'text-white']"
                            class="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500 text-center">{{
                                row.year }}</td>
                        <td :class="[selectedRow == i && 'text-white']"
                            class="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500 text-center">{{
                                row.age }}</td>
                        <td :class="[selectedRow == i && 'text-white']"
                            class="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500 text-center font-semibold">{{
                                formatMoney(row.accumulationValue) }}</td>
                        <td :class="[selectedRow == i && 'text-white']"
                            class="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500 text-center font-semibold">{{
                                row.lifetimePayment ? formatMoney(row.lifetimePayment) : '-'
                            }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="disclaimer text-center lg:mt-4">
            <p>* The displayed returns are calculated using historical data and are shown for illustrative purposes
                only, not as a guarantee or prediction of future rates.</p>
        </div>
    </SDPWrapper>
</template>

<script setup lang="ts">
import { onBeforeMount, onMounted, ref, watch } from 'vue'
import { formatMoney } from '@/utils';
import { MoneyField, TextField, RadioField } from '@reviseannuity/randolph-ui';
import SDPWrapper from './SDPWrapper.vue';
import SDPTitle from './SDPTitle.vue';
import SDPDescription from './SDPDescription.vue';
import { useQuestionnaireStore } from '@/stores/questionnaire';

const annuitantTypeOptions = [
    { value: 'single', label: 'Single' },
    { value: 'joint', label: 'Joint' }
]

const store = useQuestionnaireStore()
const creditedInterestRates = [0.0908, 0, 0.0605, 0.15, 0, 0.1918, 0.116, 0.0039, 0, 0.0485]
const lifetimePaymentPercentages = {
    single: {
        50: 0.0495,
        51: 0.0495,
        52: 0.0495,
        53: 0.0495,
        54: 0.0495,
        55: 0.0495,
        56: 0.0495,
        57: 0.0495,
        58: 0.0495,
        59: 0.0495,
        60: 0.0545,
        61: 0.0555,
        62: 0.0565,
        63: 0.0575,
        64: 0.0585,
        65: 0.0595,
        66: 0.0605,
        67: 0.0615,
        68: 0.0625,
        69: 0.0635,
        70: 0.0645,
        71: 0.0655,
        72: 0.0665,
        73: 0.0675,
        74: 0.0685,
        75: 0.0695,
        76: 0.0705,
        77: 0.0715,
        78: 0.0725,
        79: 0.0735,
        80: 0.0745
    },
    joint: {
        50: 0.0445,
        51: 0.0445,
        52: 0.0445,
        53: 0.0445,
        54: 0.0445,
        55: 0.0445,
        56: 0.0445,
        57: 0.0445,
        58: 0.0445,
        59: 0.0445,
        60: 0.0495,
        61: 0.0505,
        62: 0.0515,
        63: 0.0525,
        64: 0.0535,
        65: 0.0545,
        66: 0.0555,
        67: 0.0565,
        68: 0.0575,
        69: 0.0585,
        70: 0.0595,
        71: 0.0605,
        72: 0.0615,
        73: 0.0625,
        74: 0.0635,
        75: 0.0645,
        76: 0.0655,
        77: 0.0665,
        78: 0.0675,
        79: 0.0685,
        80: 0.0695,
    }
}

const rows = ref(null)
const currentAge = ref(store.age || 40)
const investmentAmount = ref(100000)
const annuitantType = ref<'single' | 'joint'>('single')
const selectedRow = ref(null)

const illustrate = (startingAge, startingValue, years, annuitantType) => {
    let rows = [{
        year: 'At Issue',
        age: startingAge,
        accumulationValue: parseInt(startingValue),
        benefitBase: parseInt(startingValue),
        lifetimePayment: 0,
        beneficiaryPayout: 0
    }]

    for (let i = 0; i < years; i++) {
        const year = i + 1
        const age = startingAge + year

        const previousAccumulationValue = rows[i].accumulationValue
        const accumulationValue = calculateGrowth(previousAccumulationValue, creditedInterestRates[i])

        const previousBenefitBase = rows[i].benefitBase
        const benefitBase = calculateBenefitBase(previousBenefitBase, creditedInterestRates[i])
        const lifetimePaymentPercentage = lookupLifetimePaymentPercentage(annuitantType, age)
        const lifetimePayment = benefitBase * lifetimePaymentPercentage

        const beneficiaryPayoutCeiling = Math.max(accumulationValue * 1.25, 2.5 * investmentAmount.value)
        const beneficiaryPayout = (benefitBase < beneficiaryPayoutCeiling ? benefitBase : beneficiaryPayoutCeiling) / 5

        rows.push({
            year: year.toString(),
            age,
            accumulationValue,
            benefitBase,
            lifetimePayment,
            beneficiaryPayout
        })
    }

    return rows
}

const calculateGrowth = (previousValue, rate) => {
    return previousValue + (previousValue * rate)
}

const calculateBenefitBase = (previousValue, rate) => {
    const growthRate = (rate * 1.5) + 0.02
    return calculateGrowth(previousValue, growthRate)
}

const lookupLifetimePaymentPercentage = (annuitantType, age) => {
    const percentages = lifetimePaymentPercentages[annuitantType]
    const validAges = Object.keys(percentages)

    if (age < validAges[0]) {
        return 0
    }

    if (age > validAges[validAges.length - 1]) {
        return percentages[validAges[validAges.length - 1]]
    }

    return percentages[age]
}

onBeforeMount(() => { if (!store.investmentAmount) store.setField('investmentAmount', 100000) })

onMounted(() => {
    rows.value = illustrate(currentAge.value, store.investmentAmount, creditedInterestRates.length, annuitantType.value)
    if (rows.value.length > 5) selectedRow.value = 5
})

watch(() => [store.investmentAmount, currentAge.value, annuitantType.value], () => {
    rows.value = illustrate(currentAge.value, store.investmentAmount, creditedInterestRates.length, annuitantType.value)
})
</script>