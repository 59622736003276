<template>
    <MarketplaceSignupPaywall />
    <div class="max-w-7xl mx-auto pb-8 lg:px-8">
        <div v-if="store.strategyLoadingError !== null" class="text-center py-12 max-w-md mx-auto">
            <h2 class="font-serif font-bold text-2xl sm:text-3xl mb-2">Oops! Something went wrong.</h2>
            <p class="text-lg text-gray-600 mb-2">We're sorry, something went wrong and we were unable to load
                strategies.
            </p>
            <ErrorMessage>
                {{ store.strategyLoadingError }}
            </ErrorMessage>
            <PrimaryButton @click="store.fetchStrategies" class="mt-8">Try again?</PrimaryButton>
        </div>
        <SLPLoader v-if="store.loadingStrategies" />
        <div v-else-if="store.strategies.length">
            <MobileFilterDrawer :sidebarOpen="sidebarOpen" @toggle="() => sidebarOpen = !sidebarOpen">
                <FilterList />
                <SidebarTips />
            </MobileFilterDrawer>
            <div class="grid lg:grid-cols-8 lg:gap-12">
                <div class="hidden lg:block lg:col-span-2">
                    <div class="lg:sticky top-20">
                        <div class="px-8 py-6 bg-white rounded-lg shadow mb-8 relative">
                            <FilterList />
                        </div>
                    </div>
                </div>
                <div class="lg:col-span-4">
                    <div
                        class="text-xs flex justify-between items-center gap-4 mb-4  bg-gray-100 z-20 sticky top-20 lg:top-20 pb-2">
                        <div class="flex items-center gap-2">
                            <SecondaryButton class="!px-2 !py-1.5 lg:hidden" @click="sidebarOpen = true">
                                <AdjustmentsHorizontalIcon class="text-gray-700 w-4 h-4" />
                            </SecondaryButton>
                            <span class="text-gray-600">
                                <strong>{{ store.sortedResults.length + 1 }}</strong> of 8,104
                                strategies
                            </span>
                        </div>
                        <SelectField :options="sortByOptions" :value="store.sortBy"
                            @input="v => store.setField('sortBy', v)" />
                    </div>
                    <button @click="showStrategyDetails(store.strategies[0])" class="w-full">
                        <StrategyCard :strategy="store.strategies[0]" :bestFit="true" />
                    </button>
                    <div v-if="store.sortedResults" class=" space-y-6 mt-6 sm:mt-12">
                        <div class="sm:hidden">
                            <SidebarHelp @openContactModal="() => modalIsOpen = true" />
                        </div>
                        <div v-for="strategy in store.sortedResults" :key="strategy.id">
                            <button @click="showStrategyDetails(strategy)" class="w-full">
                                <StrategyCard :strategy="strategy" :strategyType="strategy.type" />
                            </button>
                        </div>
                    </div>
                    <p class="text-xs text-gray-600 mt-8">Our annuity "best fit" or "best match" is provided by our
                        matching engine for educational and
                        transparency purposes only. You must consult with a licensed Revise expert before receiving
                        an annuity recommendation.</p>
                    <div class="text-center py-16 text-gray-800">
                        <p class="text-xl font-semibold">
                            Don't see what you're looking for?
                        </p>
                        <p class="mb-8">We have access to all products across the entire insurance market.</p>
                        <p class="mb-2">
                            <button @click="modalIsOpen = true" class="underline text-primary-600">Book
                                a
                                meeting
                                with our
                                experts</button>
                        </p>
                        <p class="mb-2">
                            or
                        </p>
                        <p>
                            <a href="tel:8552564477" class="underline text-primary-600">(855)
                                256-4477</a>
                        </p>
                    </div>
                </div>
                <div class="hidden lg:col-span-2 lg:block">
                    <div class="lg:sticky top-20 space-y-8">
                        <SidebarHelp @openContactModal="modalIsOpen = true" />
                        <SidebarTips />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <CalendarBookingModal :open="modalIsOpen" @close="modalIsOpen = false" :calendarId="CalendarId.Intro"
        @bookingComplete="store.handleBookingComplete" />
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useQuestionnaireStore } from '@/stores/questionnaire';
import { event } from 'vue-gtag'
import { useRouter } from 'vue-router';
import StrategyCard from '@/components/StrategyCard.vue';
import { PrimaryButton, ErrorMessage, SelectField, SecondaryButton } from '@reviseannuity/randolph-ui';
import { AdjustmentsHorizontalIcon } from '@heroicons/vue/24/outline';
import SLPLoader from '@/components/SLPLoader.vue';
import { sortByOptions, } from '@/data/strategyList';
import { MobileFilterDrawer, FilterList, SidebarTips, SidebarHelp } from '@/components/strategyListSections';
import MarketplaceSignupPaywall from './MarketplaceSignupPaywall.vue';
import { QuestionnaireStep } from '@/stores/questionnaire/types';
import { CalendarId } from '@/data/calendarIds';
import CalendarBookingModal from '@/components/CalendarBookingModal.vue';

const store = useQuestionnaireStore();
const router = useRouter()
const sidebarOpen = ref(false)
const modalIsOpen = ref(false)

let exitPopupTimeout

onMounted(async () => {
    store.setStep(QuestionnaireStep.STRATEGY_LIST)
    event('page_view', {
        page_title: 'Master App Questionnaire - Marketplace - Recommendations',
        page_location: window.location.href,
        page_path: window.location.pathname
    })

    if (!store.strategies.length) {
        if (!store.state || !store.age) {
            router.push({ name: 'questionnaire' })
        } else {
            await store.fetchStrategies()
            store.setField('showPaywall', true)

            event('slp_loaded', {
                best_fit: store.strategies[0]?.name
            })
        }
    }
})

watch(() => [store.sortBy, store.minimumRating, store.productType, store.useCase, store.returns], () => {
    store.sortAndFilter()
})

onUnmounted(() => {
    clearTimeout(exitPopupTimeout)
})

const showStrategyDetails = (strategy) => {
    router.push(`strategy/${strategy.id}?ref=slp`)
    store.nextStep()
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}
</script>