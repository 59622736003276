import { formatApplicationText, generateId } from "../../utils";
import { updateApplicationForUser, updateUser } from "../../clients/user";
import { applicationSubmittedEvents } from "../../eventTagging";
import { Person, Beneficiary, FundingSource, ApplicationStore, HouseholdInvestmentDetail, BeneficiaryStatus } from "./types";
import { defaultFundingSource, defaultBeneficiary } from "./defaults";

export default {
    getFundingSource(id: string): FundingSource {
        return this.fundingSources.find(source => source.id === id)
    },
    setField(key: keyof ApplicationStore, value: any): void {
        this[key] = value
    },
    setPersonalInformation(key: keyof typeof this.personalInformation, value: any): void {
        this.personalInformation[key] = value
    },
    setAttestation(key: string, value: boolean): void {
        this.attestations[key] = value
    },
    addFundingSource(): void {
        this.fundingSources.push({
            ...defaultFundingSource,
            id: generateId()
        })
    },
    removeFundingSource(id: string): void {
        this.fundingSources = this.fundingSources.filter(source => source.id !== id)
    },
    updateFundingSource(id: string, key: keyof FundingSource, value: any): void {
        const source = this.fundingSources.find(source => source.id === id)
        source[key] = value
    },
    updateOwner(key: keyof typeof this.owner, value: any) {
        this.owner[key] = value
    },
    updateJointOwner(key: keyof typeof this.jointOwner, value: any) {
        this.jointOwner[key] = value
    },
    updateOtherAnnuitant(key: keyof typeof this.otherAnnuitant, value: any) {
        this.otherAnnuitant[key] = value
    },
    addBeneficiary(): void {
        this.beneficiaries.push({
            ...defaultBeneficiary,
            id: generateId(),
            status: this.beneficiaries.length === 0 ? BeneficiaryStatus.PRIMARY : BeneficiaryStatus.CONTINGENT,
        })
    },
    updateBeneficiary(id: string, key: keyof Beneficiary, value: any): void {
        this.beneficiaries = this.beneficiaries.map(beneficiary => {
            if (beneficiary.id === id) {
                return { ...beneficiary, [key]: value }
            } else {
                return beneficiary
            }
        })
    },
    removeBeneficiary(index: number | string): void {
        if (typeof index === 'number') {
            this.beneficiaries.splice(index, 1)
        } else {
            this.beneficiaries = this.beneficiaries.filter(beneficiary => beneficiary.id !== index)
        }
    },
    setReplacingPolicyData(key: keyof typeof this.replacingPolicy, value: any) {
        this.replacingPolicyData[key] = value
    },
    setHouseholdInvestmentDetails(
        type: keyof typeof this.householdInvestmentDetails,
        key: keyof HouseholdInvestmentDetail,
        value: any
    ): void {
        this.householdInvestmentDetails[type][key] = value
    },
    validatePerson(person: Person, title: string): string[] {
        let errors = []
        if (!person.gender) errors.push(`Must specify a gender for ${title}`)
        if (!person.firstName) errors.push(`Must specify first name for ${title}`)
        if (!person.lastName) errors.push(`Must specify last name for ${title}`)
        if (!person.ssn) errors.push(`Must specify SSN for ${title}`)
        if (!person.address) errors.push(`Must specify address for ${title}`)
        if (!person.city) errors.push(`Must specify city for ${title}`)
        if (!person.state) errors.push(`Must specify state for ${title}`)
        if (!person.zip) errors.push(`Must specify zip for ${title}`)
        return errors
    },
    validateForm(): { errors: string[] } {
        const errors = []


        if (!this.fundingSources.length) errors.push('You must provide at least one funding source.')

        this.fundingSources.forEach((source, i) => {
            if (!source.institution) errors.push(`You must provide the institution for funding source ${i + 1}`)
            if (!source.amount) errors.push(`You must provide an amount for funding source ${i + 1}`)
        })

        let ownerErrors = []
        if (["individual", "joint"].includes(this.ownerType)) {
            ownerErrors = this.validatePerson(this.owner, "owner")
        }
        if (this.ownerType == "joint") {
            ownerErrors = [...ownerErrors, ...this.validatePerson(this.jointOwner, "joint owner")]
        }

        // TODO: Currently not even tracking trust/corp data
        // if (this.ownerType == "trust-corp") {
        //     ownerErrors = this.validateCorporation(this.owner, "owner")
        // }
        if (ownerErrors.length) errors.push(...ownerErrors)

        if (!this.beneficiaries.length) errors.push('You must provide at least one beneficiary.')
        if (this.beneficiaries.length) {
            this.beneficiaries.forEach(beneficiary => {
                this.errors.push(...this.validatePerson(beneficiary))
            })
        }

        if (this.beneficiaries.reduce((a, b) => a + b.percentage, 0) !== 100) {
            errors.push('Total percentages of all beneficiaries must equal 100%.')
        }

        if (this.annuitantType == "joint" && this.ownerType !== "joint") errors.push("Please ensure both the owner and annuitant are set to joint.")
        if (this.annuitantType == "other") {
            const annuitantErrors = this.validatePerson(this.otherAnnuitant, "annuitant")
        }

        if (this.replacingPolicy) {
            if (!this.replacingPolicyData.company) errors.push("You must provide the company name of the policy you're replacing.")
            if (!this.replacingPolicyData.contractNumber) errors.push("You must provide the policy number name of the policy you're replacing.")
        }

        if (!this.householdIncome) errors.push("You must provide your household income.")
        if (!this.householdExpenses) errors.push("You must provide your monthly household expenses.")
        if (this.householdInvestments) {
            this.householdInvestments.forEach(investment => {
                if (this.householdInvestmentDetails[investment].value) errors.push(`You must provide the value of your ${investment}`)
            })
        }

        return { errors }
    },
    async submitApplication(): Promise<void> {
        this.submittingApplication = true

        try {

            await updateApplicationForUser(this.user.auth0_id, {
                json: this.$state,
                response: formatApplicationText(this.$state),
                status: 'submitted'
            })
            await updateUser(this.user.auth0_id, {
                status: 'application_submitted',
                first_touch_attribution: window.reviseTracking?.getFirstTouchAttribution() || '',
                last_touch_attribution: window.reviseTracking?.getLastTouchAttribution() || '',
            })

            applicationSubmittedEvents(this.investmentAmount, this.applicationId, this.$state)

            this.applicationSubmitted = true
        } catch (error) {
            alert('Failed to submit application. ' + error.message)
        } finally {
            this.submittingApplication = false
        }
    },
    async saveApplicationProgress(): Promise<void> {
        this.savingApplication = true
        await updateApplicationForUser(this.user.auth0_id, { json: this.$state })
        this.savingApplication = false
    }
}