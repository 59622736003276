<template>
    <div>
        <div class="shadow-none border border-primary-200 bg-primary-50 px-4 pl-4 py-4 sm:pb-4 w-full rounded-lg">
            <div class="grid grid-cols-4 gap-4">
                <div class="">
                    <img src="../../assets/headshot4.jpg" class="overflow-hidden rounded-full" />
                </div>
                <div class="col-span-3">
                    <h3 class="font-bold mb-1 text-primary-900">Talk to an expert now</h3>
                    <p class="text-primary-800 text-sm leading-4 mb-4">Our expertly trained strategists are ready to
                        help
                        you.
                    </p>
                    <div class="grid grid-cols-1 gap-1">
                        <PrimaryButton size="sm" block class="text-nowrap" @click="handlePhoneButtonClick">
                            <PhoneIcon class="w-3 -mt-1 inline-block" /> (855) 256-4477
                        </PrimaryButton>
                        <SecondaryButton size="sm" block @click="$emit('openContactModal')">Book a Meeting
                        </SecondaryButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { PhoneIcon } from '@heroicons/vue/16/solid';
import { SecondaryButton, PrimaryButton } from '@reviseannuity/randolph-ui';

defineEmits(['openContactModal'])

const handlePhoneButtonClick = () => {
    window.open('tel:8552564477', '_self');
}
</script>