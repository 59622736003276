<template>
    <div class="min-h-12">
        <SpinningLoader class="w-12 -ml-6 absolute left-1/2" color="#1454f2" />
        <iframe ref="iframe" :src="iframeSource" style="width: 100%;border:none;overflow: hidden;" scrolling="no"
            :id="`${calendarId}_1734966967215`" class="relative z-10"></iframe><br>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { SpinningLoader } from '@reviseannuity/randolph-ui'
import { GHLCalendarData } from '@/stores/questionnaire/types';
const { calendarId } = defineProps<{
    calendarId: String
}>()
const emit = defineEmits<{
    (e: 'bookingComplete', data: GHLCalendarData): void
}>()

const iframe = ref<HTMLIFrameElement>()
const iframeSource = ref(`https://api.leadconnectorhq.com/widget/booking/${calendarId}`)
const formFields = ref({})

const eventHandler = (event: MessageEvent) => {
    let message, data
    if (event.data) {
        message = event.data[0]
        data = event.data
    }

    if (message === 'set-sticky-contacts' && data && data.length) {
        formFields.value = JSON.parse(data[2])
    }

    if (message === 'msgsndr-booking-complete') {
        emit('bookingComplete', {
            firstName: formFields.value.first_name,
            lastName: formFields.value.last_name,
            phone: formFields.value.phone,
            email: formFields.value.email,
            birthdate: new Date(formFields.value.date_of_birth._seconds),
            age: formFields.value.age,
            state: formFields.value.state,
            zip: formFields.value.postal_code,
            investmentAmount: formFields.value.investment_amount,
            fundingSource: formFields.value.funding_source,
            meetingDate: new Date(formFields.value.appointment.start_time)
        })
    }
}

onMounted(() => {
    if (typeof window !== 'undefined') {
        const scripts = document.getElementsByTagName('script')
        for (let i = 0; i < scripts.length; i++) {
            if (scripts[i].src === 'https://link.msgsndr.com/js/form_embed.js') {
                return
            }
        }

        const script = document.createElement('script')
        script.id = 'ghl-form-embed'
        script.src = 'https://link.msgsndr.com/js/form_embed.js'
        document.body.appendChild(script)
        window.addEventListener('message', eventHandler)
    }
})

onUnmounted(() => {
    document.getElementById('ghl-form-embed')?.remove()
    window.removeEventListener('message', eventHandler)
})
</script>