<template>
    <div class="sm:flex justify-center sm:gap-6 items-start mx-auto w-auto">
        <QuestionnaireSection class="max-w-sm !mx-0 sm:mb-0 mb-4" :title="title" :helperText="helperText"
            @click="handleContinue" :disableNext="!store.validateStep(QuestionnaireStep.BIRTHDATE)">
            <div class="mb-8">
                <DateField @input="e => store.setField('birthdate', e)" :value="store.birthdate" />
                <ErrorMessage v-if="errorMessage">{{ errorMessage }}</ErrorMessage>
            </div>
        </QuestionnaireSection>
        <WhatToExpect v-if="store.showBirthdateChecks" />
    </div>
</template>

<script setup lang="ts">
import QuestionnaireSection from './QuestionnaireSection.vue';
import { useQuestionnaireStore } from '@/stores/questionnaire';
import { onMounted, ref } from 'vue';
import { DateField, ErrorMessage, TextField } from '@reviseannuity/randolph-ui';
import { event } from 'vue-gtag'
import { useRouter } from 'vue-router';
import WhatToExpect from './WhatToExpect.vue';
import { QuestionnaireStep } from '@/stores/questionnaire/types';

const router = useRouter()
const store = useQuestionnaireStore()
const errorMessage = ref(null)

const title = 'What is your date of birth?'
const helperText = "Your age helps us to match you with the right annuities."

onMounted(() => {
    store.setStep(QuestionnaireStep.BIRTHDATE)
    event('page_view', {
        page_title: 'Master App Questionnaire - Age Selection',
        page_location: window.location.href,
        page_path: window.location.pathname
    })
})

const handleContinue = () => {
    errorMessage.value = null
    let age

    if (store.birthdate) {
        const birthdate = new Date(store.birthdate)
        age = new Date().getFullYear() - birthdate.getFullYear()
    } else {
        age = store.age
    }

    if (age < 18) {
        errorMessage.value = 'You must be at least 18 years old to purchase an annuity.'
        return
    }

    if (age > 93) {
        errorMessage.value = 'The maximum age to purchase an annuity is 93.'
        return
    }

    store.setField('age', age)

    event('age_set', {
        age
    })

    router.push(store.nextStep())
}
</script>