import { ProductTypeOption, ReturnsOption, SortOption, UseCaseOption } from "../stores/questionnaire/types"

export const sortByOptions: { label: string, value: SortOption }[] = [
    { label: 'Best Match', value: SortOption.BEST_MATCH },
    { label: 'Highest Carrier Rating', value: SortOption.HIGHEST_RATING },
    { label: 'Highest Rate', value: SortOption.HIGHEST_RATE },
    { label: 'Lowest Volatility', value: SortOption.LOWEST_VOLATILITY },
    { label: 'Most Reviews', value: SortOption.MOST_REVIEWS },
]

export const productTypeOptions: { label: string, value: ProductTypeOption }[] = [
    { label: 'Growth', value: ProductTypeOption.GROWTH },
    { label: 'Lifetime Income', value: ProductTypeOption.INCOME },
    { label: 'Bonus', value: ProductTypeOption.BONUS },
]

export const returnsOptions: { label: string, value: ReturnsOption, tooltip?: string }[] = [
    {
        label: 'Fixed indexed',
        value: ReturnsOption.FIXED_INDEXED,
        tooltip: 'Fixed indexed annuity returns are based upon the annual or biannual return of the index listed in the strategy detail page. These products are entirely protected from market loss.'
    },
    {
        label: 'Fixed',
        value: ReturnsOption.FIXED,
        tooltip: 'Fixed annuity returns are guaranteed and and credited on an annual basis by the issuing insurance company for a specific duration of time, usually 3 to 10 years.'
    },
    {
        label: 'MYGA',
        value: ReturnsOption.MYGA
    }
]

export const useCaseOptions: { label: string, value: UseCaseOption }[] = [
    { label: '401(k) Rollover', value: UseCaseOption['401K'] },
    { label: '1035 & Annuity Replacement', value: UseCaseOption.ANNUITY_REPLACEMENT },
    { label: 'Guaranteed Income', value: UseCaseOption.GUARANTEED_INCOME },
    { label: 'Yield Enhancement & Higher Returns', value: UseCaseOption.YIELD_ENHANCEMENT },
    { label: 'Portfolio Hedge', value: UseCaseOption.PORTFOLIO_HEDGE },
]

export const loadingPhrases = [
    "Analyzing your responses...",
    "Searching top annuity strategies...",
    "Building your personalized experience...",
]

export const bulletPoints = [
    '100% market protected',
    'Tax-deferred growth',
    'Top-rated carriers',
    'Expert support'
]
