<template>
    <SectionContainer title="Annuitant" :button-disabled="!store.steps[stepIndex].complete()" @nextStep="() => {
        event('annuitant_complete')
        nextStep()
    }" @prevStep="prevStep" helperText="The annuitant is the person whose age and life expectancy is going to be used to calculate the
                benefits of the annuity and who will receive any annuity payments during annuitization (the optional
                event when an annuity is converted from an accumulation vehicle to a lifetime income stream). Usually,
                the annuitant and the owner are the same person, but it is not required.">
        <div class="space-y-4 ">
            <RadioField :inline="true" label="Who is the annuitant?" name="annuitantType" :options="annuitantTypes"
                @input="e => store.setField('annuitantType', e)" :value="store.annuitantType" required />
            <PersonInput name="otherAnnuitant" label="Other Annuitant" v-if="['other'].includes(store.annuitantType)"
                @update="({ key, value }) => store.updateOtherAnnuitant(key, value)" />
        </div>
    </SectionContainer>
</template>

<script setup lang="ts">
import { RadioField, PersonInput } from '@reviseannuity/randolph-ui';
import SectionContainer from '@/components/SectionContainer.vue';
import { useApplicationStore } from '@/stores/application';
import { useStep } from '@/composables/useStep';
import { event } from 'vue-gtag';

const store = useApplicationStore();
const { nextStep, prevStep, stepIndex } = useStep(store)

const annuitantTypes = [
    { label: 'Myself', value: 'self' },
    { label: 'My Spouse and I', value: 'spouse-joint', disabled: store.fundingIsQualified },
    { label: 'Someone Else', value: 'other', disabled: store.fundingIsQualified },
]
</script>