import { isBurnerEmail } from '../../burnerEmails';
import { RegistrationStep } from './types';

export default {
    percentage: (state) => {
        switch (state.activeStep) {
            case RegistrationStep.FUNDING:
                return 80;
            case RegistrationStep.LIQUID_FUNDS:
                return 90;
            case RegistrationStep.INITIAL_PREMIUM_AMOUNT:
                return 100;
            case RegistrationStep.INVESTMENT_AMOUNT:
                return 100;
            case RegistrationStep.QUALIFIED_INVESTMENT_AMOUNT:
                return 90;
            case RegistrationStep.ROLLOVER_BOOKING:
                return 100;
            default:
                return 70;
        }
    },
    previousStep: (state) => {
        switch (state.activeStep) {
            case RegistrationStep.LIQUID_FUNDS:
                return RegistrationStep.FUNDING;

            case RegistrationStep.INVESTMENT_AMOUNT:
                return RegistrationStep.LIQUID_FUNDS

            case RegistrationStep.QUALIFIED_INVESTMENT_AMOUNT:
                return RegistrationStep.FUNDING;

            case RegistrationStep.ROLLOVER_BOOKING:
                return RegistrationStep.QUALIFIED_INVESTMENT_AMOUNT

            case RegistrationStep.INITIAL_PREMIUM_AMOUNT:
                return RegistrationStep.FUNDING

            default:
                return false
        }
    },
    emailValidationError: (state) => {
        if (state.email.length > 0 && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email)) {
            return 'Please enter a valid email address.'
        }

        if (isBurnerEmail(state.email)) {
            return `Email with domain ${state.email.split('@')[1]} is not valid.`
        }
    },
    phoneValidationError: (state) => {
        if (state.phone.length > 0 && !/^(?!555)(?!(\d)\1{9})\d{10}$/.test(state.phone)) {
            return 'Please enter a valid phone number.'
        }
    },
    isRegistrationFormValid: (state) => {
        if (!state.firstName) return false
        if (!state.lastName) return false
        if (!state.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email)) return false
        if (!state.phone || !/\d{10}$/.test(state.phone.toString())) return false
        if (!state.consent) return false
        return true
    },
    isVerificationFormValid: (state) => {
        if (!state.verificationCode || !/\d{6}/g.test(state.verificationCode.trim())) return false
        return true
    }
}