<template>
    <div class="sm:flex justify-center sm:gap-6 items-start mx-auto w-auto">
        <QuestionnaireSection class="max-w-md !mx-0 sm:mb-0 mb-4"
            title="Let's get started! First, what is your ZIP code?"
            helperText="As product offerings vary state-to-state, we'll need to know where you call home."
            @click="nextStep" :disableNext="!store.validateStep(store.activeStep)" :loading="loading">
            <div class="mb-8">
                <TextField @input="onInput" :value="store.zipCode" placeholder="Enter ZIP code" id="zipInput"
                    autocomplete='postal-code' inputmode="numeric" key="zipInput" maxlength="5">
                    <template v-slot:leftIcon>
                        <MapPinIcon class="w-4 text-gray-500 sm:text-sm" />
                    </template>
                </TextField>
            </div>
            <ErrorMessage v-if="errorMessage">{{ errorMessage }}</ErrorMessage>
        </QuestionnaireSection>
        <WhatToExpect />
    </div>
</template>

<script setup lang="ts">
import QuestionnaireSection from './QuestionnaireSection.vue';
import { useQuestionnaireStore } from '@/stores/questionnaire';
import { event } from 'vue-gtag'
import { onMounted, ref } from 'vue';
import { MapPinIcon } from '@heroicons/vue/16/solid';
import { useRouter } from 'vue-router';
import { getStateFromZipCode } from '@/clients/zipcodestack';
import { TextField, ErrorMessage } from '@reviseannuity/randolph-ui'
import WhatToExpect from './WhatToExpect.vue';
import { QuestionnaireStep } from '@/stores/questionnaire/types';

const router = useRouter()
const store = useQuestionnaireStore();
const errorMessage = ref<string>()
const loading = ref<boolean>(false)

const onInput = (value) => {
    store.setField('zipCode', value.replace(/\D/g, '').slice(0, 5))
};

const nextStep = async () => {
    loading.value = true
    errorMessage.value = null
    try {
        const state = await getStateFromZipCode(store.zipCode)
        store.setField('state', state)
        event('state_selected', {
            state: state,
            zipCode: store.zipCode
        })
        router.push(store.nextStep())
    } catch (error) {
        errorMessage.value = error.message
    }
    loading.value = false
}

onMounted(() => {
    store.setStep(QuestionnaireStep.ZIP)
    event('page_view', {
        page_title: 'Master App Questionnaire - State Selection',
        page_location: window.location.href,
        page_path: window.location.pathname
    })
})
</script>