import { defaultBeneficiary, defaultIdentification, defaultPerson } from "./defaults"
import { ApplicationStore } from "./types"

const state = (): ApplicationStore => ({
    loading: true,
    user: null,
    applicationId: null,
    errorMessage: null,
    submittingApplication: false,
    applicationSubmitted: false,
    submissionErrors: [],
    investmentAmount: 0,
    monthlyContributions: 0,
    personalInformation: {
        ...defaultPerson,
        ...defaultIdentification
    },
    fundingSourceType: 'qualified',
    fundingSources: [],
    ownerType: 'individual',
    owner: null,
    jointOwner: {
        sameAddress: true,
        ...defaultPerson,
        ...defaultIdentification
    },
    annuitantType: null,
    otherAnnuitant: null,
    beneficiaries: [{ ...defaultBeneficiary }],
    existingPolicy: null,
    replacingPolicy: null,
    replacingPolicyData: { company: null, contractNumber: null },
    householdIncome: 0,
    householdExpenses: 0,
    householdLiabilities: 0,
    householdInvestments: [],
    taxFilingStatus: 'single',
    householdInvestmentDetails: {
        'stocks-bonds': { value: 0, liquidating: false },
        'annuities': { value: 0, liquidating: false },
        'mutual-funds': { value: 0, liquidating: false },
        'cds': { value: 0, liquidating: false },
        'money-market': { value: 0, liquidating: false },
        'checking-savings': { value: 0, liquidating: false },
        'pension-401k': { value: 0, liquidating: false },
        'life-insurance': { value: 0, liquidating: false },
        'real-estate': { value: 0, liquidating: false },
        'reverse-mortgage': { value: 0, liquidating: false },
        'limited-partnership': { value: 0, liquidating: false },
    },
    engagementLetterAgreed: false,
    savingApplication: false,
    attestations: {
        financialGoals: null,
        previousContractsWithProducer: null,
        governmentBenefits: null,
        reverseMortgage: null,
        sufficientFunds: null,
        surrenderCharges: null,
        futureIncomeChange: null,
        exchanges: null,
    },
    strategy: null,
    wereYouReferred: null,
    referrer: null,
    referringPartner: null,
    applicationExpired: false,
    dependents: false,
    dependent1Age: null,
    dependent2Age: null,
    dependent3Age: null,
})

export default state